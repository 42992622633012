import { trendArrowImages } from '../../../utils/dashboardRenderer';
import { format } from 'date-fns';
const moment = require('moment');

const _ = require('lodash');
const startOfWeek = require('date-fns/start_of_week');
const startOfMonth = require('date-fns/start_of_month');
const startOfQuarter = require('date-fns/start_of_quarter');
const endOfWeek = require('date-fns/end_of_week');
const endOfMonth = require('date-fns/end_of_month');
const endOfQuarter = require('date-fns/end_of_quarter');
const getMonth = require('date-fns/get_month');

const lesserThanOne = "<1";
const typeNumber = "number";

const clientFunctions = {
    avgProductCount: {
        transformResponse: ({ _clientResponse, _widgetScope }) => {
            const current = _clientResponse?.data?.data?.current ? _clientResponse.data.data.current : 0;
            const previous = _clientResponse?.data?.data?.previous ? _clientResponse.data.data.previous : 0;
            const days = _widgetScope.dateFilterDays

            return {
                current: days > 0 ? Number((current/days).toFixed(2)) : 0,
                previous: days > 0 ? Number((previous/days).toFixed(2)) : 0
            }
        }
    },
    peakHour: {
        transformResponse: ({ _clientResponse, _widgetScope }) => {
            let productData = _clientResponse?.data?.data?.productData || [];

            if (productData.length === 0) {
                return { chartData: { data: [], subTitleMessage: "0", setMaxPrecision: false } };
            }

            let totalCooks = 0, average = 0;
            const is24Hours = localStorage.getItem("timeFormat") === "24";
            const days = moment().daysInMonth();
            const daysInMonth =  Array.from({ length: days }, (_, i) => { return i + 1});
            const hours24 =  Array.from({ length: 24 }, (_, i) => { return `${i}-${i+1}`});
            const hours12 =  Array.from({ length: 24 }, (_, i) => { return  (i > 12)? `${i-12}-${i-11} pm` : (i === 12)? `12-1 pm` : `${i}-${i+1} am`});
            const months =  Array.from({ length: 12 }, (_, i) => { return moment().month(i).format('MMMM')});

            const xAxis = {
                hourly24: hours24,
                hourly12: hours12,
                Year: months,
                Quarter: months,
                Month: daysInMonth,
                Week: daysInMonth
            }

            totalCooks = _.sumBy(productData, 'cooks');

            //Get average cooks per day
            if (typeof (totalCooks) === typeNumber) {
                average = totalCooks / _widgetScope.dateFilterDays;
                average = average > 0 && average < 1 ? lesserThanOne : average.toFixed(0, 2);
            }

            if (_widgetScope.dateFilterValue.value !== "Day") {
                let dateFormat = (_widgetScope.dateFilterValue.value === "Week" || _widgetScope.dateFilterValue.value === "Month" || _widgetScope.dateFilterValue.value === "Custom") ? "D" : (_widgetScope.dateFilterValue.value === "Year" || _widgetScope.dateFilterValue.value === "Quarter") ? "MMMM" : "";
                productData = productData.map(item => {
                    let day = item.key.split('-')[2] || 1; //Setting default date to 1 for quarter and year type filter
                    let month = item.key.split('-')[1];
                    day = day && day.length === 1 ? '0' + day : day;
                    month = month && month.length === 1 ? '0' + month : month;
                    let date = item.key.split('-')[0] + '-' + month + '-' + day;
                    item.key = (moment(date).format(dateFormat));
                    return {
                        "x": item.key,
                        "y": item.cooks,
                        "alpha": 1
                    };
                });

            } else {
                if (is24Hours) {
                    productData = productData.map(item => {
                        item.key = item.key +1;
                        item.key = (item.key === 24 ? "23-24" : `${item.key - 1}-${item.key}`);
                        return {
                            "x": item.key,
                            "y": item.cooks,
                            "alpha": 1
                        };
                    });
                } else {
                    productData = productData.map(item => {
                        item.key = item.key +1;
                        item.key = (item.key === 12 ? "11-12 am" : item.key === 24 ? "11-12 pm" : item.key > 12 ? item.key === 13 ? "12-1 pm" : `${item.key - 13}-${item.key - 12} pm` : `${item.key - 1}-${item.key} am`);
                        item.alpha = 1;
                        return {
                            "x": item.key,
                            "y": item.cooks,
                            "alpha": 1
                        };
                    });
                }
            }

            let dataSet = [];
            if (_widgetScope.dateFilterValue.value === "Day") {
                let baseX = is24Hours ? xAxis.hourly24 : xAxis.hourly12;
                dataSet = baseX.map(item => {
                    return {
                        x: item,
                        y: productData.find(res => res.x == item)?.y || 0,
                        alpha: 1
                    }
                })
            } else {
                dataSet = xAxis[_widgetScope.dateFilterValue.value].map(item => {
                    return {
                        x: item,
                        y: productData.find(res => res.x == item)?.y || 0,
                        alpha: 1
                    }
                });
            }


            const todaysDate = new Date();
            let categoryStarts = null;
            let categoryEnds = null;
            if (_widgetScope.dateFilterValue.value === "Week") {
                let startDateMonth = getMonth(startOfWeek(todaysDate, { weekStartsOn: 1 }));
                let endDateMonth = getMonth(endOfWeek(todaysDate, { weekStartsOn: 1 }));
                let todayMonth = getMonth(todaysDate);

                if (startDateMonth < todayMonth && endDateMonth === todayMonth) {
                    categoryStarts = format(startOfMonth(todaysDate), 'D');
                    categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
                }
                else if (startDateMonth === todayMonth && endDateMonth > todayMonth) {
                    categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
                    categoryEnds = format(endOfMonth(todaysDate), 'D');
                }
                else {
                    categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
                    categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
                }
            }
            else if (_widgetScope.dateFilterValue.value === "Quarter") {
                categoryStarts = format(startOfQuarter(todaysDate), 'MMMM');
                categoryEnds = format(endOfQuarter(todaysDate), 'MMMM');
            }
            return {
                chartData: {
                    data: dataSet,
                    setMaxPrecision: true,
                    categoryStarts: categoryStarts,
                    categoryEnds: categoryEnds
                }
            };

        }
    },
    topTenSoldProducts: {
        transformResponse: ({ _clientResponse }) => {
            // Define product colors (replace with your actual colors)
            const productColors = ["#ff97cd", "#0083b3", "#7192f1", "#c10b6d", "#414548", "#eacd61", "#59a9ed", "#00eabf", "#ff3b84", "#e08e53"];

            const current = _clientResponse?.data?.data?.current?.length ? _clientResponse.data.data.current : [];
            // Transform the data
            const transformedData = current.map((currentItem, indx) => {
                // Get color based on product
                const color = productColors[indx]; // Default to black if color not found

                // Find corresponding item in previous array (if available)
                const previousItem = _clientResponse?.data?.data?.previous?.find((prevItem) => prevItem.product === currentItem.product) || 0;

                // Calculate trend
                const trend = previousItem ? (currentItem.count === previousItem.count ? 0 : currentItem.count > previousItem.count ? 1 : -1) : 0;

                // Create the new object
                return {
                    productID: currentItem.product,
                    counts: currentItem.count,
                    trend: trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""],
                    color,
                    order: currentItem.count
                };
            });
            const sortedTransformData = transformedData.sort((a, b) => a.counts - b);
            return {
                data: {
                    value: sortedTransformData
                }
            }
        }
    },

}

export default clientFunctions;