import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserMgmtAddUser, UserManagement, UserProfile } from '../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../service/httpService';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { brandLocations, unitModels } from '../../../utils/imgContants';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { BRAND_ID } from '../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { injectIntl,FormattedMessage } from 'react-intl';
import { searchVisible, searchText, clearSearchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj} from '../../globalSearchBar/selectOption';
import { AutoSizer, List } from 'react-virtualized';
import {readArchivesFromCompressedResponse} from '../../../utils/helpers'

var bg = require('../../../images/recipecreation/photoPlaceholder.png');
const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;
const brandLocationsMap = {};
brandsLocationLogo.forEach(item => (brandLocationsMap[item.name] = item));
const brandsMap = {};
brandsLogo.forEach(item => (brandsMap[item.name] = item));

class AssignUserToTopology extends Component {


    onSuccessCloseModal = () => {
        this.props.history.push(`/user/list`);
    }
    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/user/list`);
    }
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            roleId: 0,
            userGroup: [],
            userPreference: [],
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            linkListValue: {},
            findTreeLevel: "",
            selectValues:{},
            pageLoad: true,
            selectedList:[],
            selectedDynasty: [],
            selectedGroupName: [],
            searchData: null,
            units: [],
            flattenTreeData: [], /* Contains flatten hierarchy data structure in Array<Array> */
        };
        this.treeData = {
            virtualScrollBufferRowCount: 10,
            rowBaseHeight: 80,
            virtualScrollRef: React.createRef(),
            collapsedNodes: {},
            activeNode: null,
            expand: {}
        };
        // this.showLinks = this.showLinks.bind(this);
        this.getData = this.getData.bind(this);
        this.submitAddUser = this.submitAddUser.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.setUserToState = this.setUserToState.bind(this);
        this.treeViewRowRenderer = this.treeViewRowRenderer.bind(this);
        this.getTreeViewRowHeight = this.getTreeViewRowHeight.bind(this);
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            let { KC_ERR_400, KC_ERR_500, body } = data;
            if (response && response.status === 400) {
                let showErrMsg = "KC0944"
                if (data && data.body && data.body.message && data.body.message === 'Email id is not valid'){
                    showErrMsg = "KC0018";
                }
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : showErrMsg
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }
    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        this.getCustGroupID();
        this.props.searchVisible(true);
        window.addEventListener('resize', this.updateDimensions());
    }

    // componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

    updateDimensions() {
        const windowHeight = $(window).height();
        const $treeViewBody = $('.assignOrgTreeWrapper .treeViewBody');
        $treeViewBody.css('height', windowHeight - $treeViewBody.offset().top);
    }

    componentDidUpdate = (prevState) => { 
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if( curSearchData && (isEqualObj( searchData, curSearchData )  === false) ) {
			this.getCustGroupID();
		}
        this.updateDimensions();
	}
	static getDerivedStateFromProps = (props) => ({...props});

    getData = (url, Cgids) => {
        let responseResult = {
            data: []
        }
        this.setState({
            loaderIconVisible: true
        });
        return new Promise((resolve, reject) => {
            let headers = { 'Content-Type': 'application/json', 'brandids': BRAND_ID };
            if (Cgids) headers.Cgids = Cgids;
            http.get(url, {
                headers,
                data: {}
            }
            ).then(response => {
                resolve(response);

            }).catch(err => {
                this.showError(err);
            })
        });
    }

    prepareDataObj = async (orgValue) => {
        let selectedList = this.state.selectedList;
        let selectedDynasty = this.state.selectedDynasty;
        let selectedGroupName = this.state.selectedGroupName;

        for (let i = 0; i < orgValue.length; i++) {
            const { dynasty, cust_gp_id,nextLevelAccess } = orgValue[i];
            if (selectedList.includes(cust_gp_id)) {
                selectedDynasty.push(dynasty);
                selectedGroupName.push(orgValue[i]);
            }
            if (nextLevelAccess && orgValue[i]['level2'] && orgValue[i]['level2'].length) {
                for (let j = 0; j < orgValue[i]['level2'].length; j++) {
                    const { dynasty, cust_gp_id,nextLevelAccess } = orgValue[i]['level2'][j];
                    if (selectedList.includes(cust_gp_id)) {
                        selectedDynasty.push(dynasty);
                        selectedGroupName.push(orgValue[i]['level2'][j]);
                    }
                    if (nextLevelAccess && orgValue[i]['level2'][j]['level3'] && orgValue[i]['level2'][j]['level3'].length) {
                        for (let k = 0; k < orgValue[i]['level2'][j]['level3'].length; k++) {
                            const { dynasty, cust_gp_id,nextLevelAccess } = orgValue[i]['level2'][j]['level3'][k];
                            if (selectedList.includes(cust_gp_id)) {
                                selectedDynasty.push(dynasty);
                                selectedGroupName.push(orgValue[i]['level2'][j]['level3'][k]);
                            }
                            if (nextLevelAccess && orgValue[i]['level2'][j]['level3'][k]['level4'] && orgValue[i]['level2'][j]['level3'][k]['level4'].length) {
                                for (let l = 0; l < orgValue[i]['level2'][j]['level3'][k]['level4'].length; l++) {
                                    const { dynasty, cust_gp_id,nextLevelAccess } = orgValue[i]['level2'][j]['level3'][k]['level4'][l];
                                    if (selectedList.includes(cust_gp_id)) {
                                        selectedDynasty.push(dynasty);
                                        selectedGroupName.push(orgValue[i]['level2'][j]['level3'][k]['level4'][l]);
                                    }
                                    if (nextLevelAccess &&  orgValue[i]['level2'][j]['level3'][k]['level4'][l]['level5'] &&  orgValue[i]['level2'][j]['level3'][k]['level4'][l]['level5'].length) {
                                        for (let h = 0; h < orgValue[i]['level2'][j]['level3'][k]['level4'][l]['level5'].length; h++) {
                                            const { dynasty, cust_gp_id } = orgValue[i]['level2'][j]['level3'][k]['level4'][l]['level5'][h];
                                            if (selectedList.includes(cust_gp_id)) {
                                                selectedDynasty.push(dynasty);
                                                selectedGroupName.push(orgValue[i]['level2'][j]['level3'][k]['level4'][l]['level5'][h]);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        this.setState({selectedDynasty, selectedGroupName})
        return;
    }

    getCustGroupID = async() => {
        let { userModel } = this.props;
        let custGroupIDList = "";
        let loggedinUser;
        var setcustGroupIDList = "";
        let custGroupID = localStorage.getItem("custGroupID");
        let loginUser =   JSON.parse(localStorage.getItem("loginInfo"));
        if (this.props.userModel.previousPage === "/user/create") {
            loggedinUser = loginUser.username;
        }
        else {
            loggedinUser = userModel.user.email;
        }

        this.setState({ loaderIconVisible: true, })
        await http({
            method: "GET",
            url: `${UserProfile.get_Cust_GroupID}`,
            headers: {
                'customer_id': localStorage.getItem("customerId"),
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'base_country': localStorage.getItem('basecountry'),
                'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
            }, data: {},
        }).then(async response => {

            if (this.props.userModel.previousPage === "/user/create") {
                localStorage.setItem("selectedGroupID", 0);
            }
            else {
                custGroupIDList = response.data.data;
                let selectedList = [];
                custGroupIDList.forEach(p => {
                    setcustGroupIDList ? setcustGroupIDList = setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList = p.customer_group_id
                    selectedList.push(parseInt(p.customer_group_id));
                });
                this.setState({selectedList})
                localStorage.setItem("selectedGroupID", setcustGroupIDList);
            }
            
            await this.getOrgnanizationView(custGroupID);
        }).catch(err => {
            console.log('catch exception occured');
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
    }

     /**
     * Get Unit Smart Group names for display at Unit Node
     * @param {*} unitNode Unit Node object
     * @returns Array<String> Smart Group names
     */
getUnitSmartGroupsName(unitNode) {
    let smartGroups = unitNode.smartGroup || [];
    let smartGroupsName = [];
    if (smartGroups.length > 3) {
        smartGroupsName.push({
            sgName: '',
            sgFirstName: smartGroups.length,
        });
    } else if (smartGroups.length > 0) {
        smartGroups.forEach(item => {
            smartGroupsName.push({
                sgName: item.name,
                sgFirstName: item.smartGroupKeyName && item.smartGroupKeyName !== 'null'
                    ? item.smartGroupKeyName
                    : item.name.charAt(0)
            });
        });
    }
    return smartGroupsName;
}

/**
* Convert hierarchy data structure into flatten array
* @param {*} nodes Array<HierarchyNode> Array of hierarchy node object
* @param {*} nodeLevel Node level No
* @param {*} currentRowIndex Current row index for Tree Data
* @param {*} flattenTreeData Array<Array> Accumulated flatten tree data
* @param {*} parentRowIndex Parent Node row index
* @param {*} parentColIndex Parent Node column index
* @returns Array<Array>
*/
convertToFlattenTreeData(nodes, nodeLevel = 1, currentRowIndex = 0, flattenTreeData = [], parentRowIndex = null, parentColIndex = null) {
    let prevRowIndex = 0;
    const nodeIndexes = [];
    const nodesLength = nodes.length;
    for (let i = 0; i < nodesLength; i++) {
        const node = nodes[i];

        // Set row and column index
        i > 0 && currentRowIndex++;
        const rowIndex = currentRowIndex;
        const level = node.level_id || nodeLevel; // If level_id not available in API response, read from method param. Fix for DEV env
        const colIndex = level - 1;

        // Set connectors in midway indexes, but not for level 1 and 6
        if ((level > 1 && level < 6) && i > 0) {
            const endRowIndex = rowIndex - 1; // Exclude current row
            let midwayRowsCount = endRowIndex - prevRowIndex;
            if (midwayRowsCount > 0) {
                const startRowIndex = prevRowIndex + 1; // Exclude start index
                for (let j = startRowIndex; j <= endRowIndex; j++) {
                    const rowData = flattenTreeData[j] || [];
                    rowData[colIndex] = {
                        __NODE_DATA__: {
                            isConnector: true,
                            rowIndex: j,
                            colIndex,
                            parentRowIndex,
                            parentColIndex
                        }
                    };
                    flattenTreeData[j] = rowData;
                }
            }
        }

        // Create node object
        const childNodesProp = level < 5 ? `level${level + 1}` : 'units';
        const { [childNodesProp]: childNodes, ...nodeClone } = node;
        const nodeData = {
            isNode: true,
            rowIndex,
            colIndex,
            parentRowIndex,
            parentColIndex,
            hasChildren: !!node.child_count,
            isFirstChild: nodesLength > 1 && i === 0,
            isMidwayChild: i > 0 && i < (nodesLength - 1),
            isLastChild: nodesLength > 1 && i === (nodesLength - 1),
            isOnlyChild: nodesLength === 1
        };
        if (level === 6) {
            const smartGroupsName = this.getUnitSmartGroupsName(nodeClone);
            nodeData.smartGroupsName = smartGroupsName;
        }
        nodeClone.__NODE_DATA__ = nodeData;

        // Set node object in respective index
        const rowData = flattenTreeData[rowIndex] || [];
        rowData[colIndex] = nodeClone;
        flattenTreeData[rowIndex] = rowData;
        nodeIndexes.push([rowIndex, colIndex]);

        // Process its children
        if (nodeData.hasChildren) {
            const result = this.convertToFlattenTreeData(
                childNodes,
                nodeLevel + 1,
                currentRowIndex,
                flattenTreeData,
                rowIndex,
                colIndex
            );
            flattenTreeData = result.flattenTreeData;
            flattenTreeData[rowIndex][colIndex].__NODE_DATA__.childNodeIndexes = result.nodeIndexes;
            currentRowIndex = result.currentRowIndex;
        }
        prevRowIndex = rowIndex;
    }
    return { flattenTreeData, nodeIndexes, currentRowIndex };
}

formUnits = (flattenTreeData) => {
    let unit = [];
    for (let index = 0; index < flattenTreeData.length; index++) {
        for (let indexJ = 0; indexJ < flattenTreeData[index].length; indexJ++) {
            const nodeEle = flattenTreeData[index][indexJ];
            if (nodeEle && nodeEle.UNITID) {
                unit.push(nodeEle);
            }
        }
    }
    return unit;
}

async seperateUnits(orgJSON) {
    const { flattenTreeData } = this.convertToFlattenTreeData(orgJSON.level1);
    const units = this.formUnits(flattenTreeData);
    this.setState({ units, flattenTreeData })
}

    async processOrgnanizationJSON(orgJSON) {
		// const length = orgJSON.start_level_id;
        const length = 1;
        let findLeveltoShow = orgJSON.level1[0];
        if (length > 1) {
            for (let i = 2; i <= length; i++) {
                findLeveltoShow = findLeveltoShow[`level${i}`][0];
            }
        }
        if(orgJSON && orgJSON.level1){
            await this.prepareDataObj(orgJSON.level1);
        }
        await this.seperateUnits(orgJSON);
        this.setState({
            linkList: orgJSON,
            link: orgJSON,
            findTreeLevel: findLeveltoShow,
            loaderIconVisible: false,
            isUnitItemClicked: true
        },
        () => {
            this.treeData.virtualScrollRef.current.recomputeRowHeights();
        });
    }

    /**
     * Convert Encoding UTF8 to binary
     */
    convertToBinary = (string) => {
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
            codeUnits[i] = string.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
    }
    
    getOrgnanizationView = async(values) => {
        this.setState({ loaderIconVisible: true });
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
        let url = `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`;
        let { searchData } = this.state;
        if (searchData && searchData.searchtext) {
            url = `${url}?searchText=${searchData.searchtext.toString()}`;
        }
        if (searchData) {
            let searchParam = {};
            Object.keys(searchData).map((item) => {
                if (item !== "searchtext") {
                    searchParam[item] = searchData[item];
                }
            })
            if (searchParam && Object.keys(searchParam).length) {
                url = `${url}?searchParams=${btoa(this.convertToBinary(JSON.stringify(searchParam)))}`;
            }
        }
        await http({
            method: "GET",
            url: url,
            headers: {
                "Cgids": values,
                'Content-Type': 'application/json',
                'brandids': BRAND_ID,
				'useremail': loggedinUser	
            }, data: {},
        }).then(async response => {
            // if (response.data.fetchFromS3) {
			// 	await $.getJSON(response.data.presignedS3URL, (data) => {
			// 		 this.processOrgnanizationJSON(data);
			// 	});
			// }
			// else {
			// 	this.processOrgnanizationJSON(response.data);
            // }
            let orgJSON = await readArchivesFromCompressedResponse(response.data);
            this.processOrgnanizationJSON(orgJSON);
        }).catch(err => {
            console.log("Org Compressed Data Error", err);
            this.setState({
                linkListValue: {},
                loaderIconVisible: false,
            })
        });
    }
    
    setUserToState = (e) => {
		let { userModel } = this.props;
        userModel.roleId = this.props.userModel.roleId;
        if (this.props.userModel.previousPage === "/user/list") {
            this.props.history.push({pathname: '/user/list',})
        } else {
            userModel.assignUserBackPage = "/user/select-role";   
        }
		this.props.setUserDataModel(userModel);
	}

    assignUnitReg = async (rootCgid) =>{
        await http({
            method: "POST",
            url: `${UserManagement.allocateUnitReg}`,
            data: {
                "subscriptionType": "EAAS",
                rootCgid
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            return response;
        }).catch(err => {
            return err;
        }); 
    }

    updateEaasCustomer = async (email, rootCgid) =>{
        await http({
            method: "PUT",
            url: `${UserManagement.updateEaasCustomer}`,
            data: {
                "username": email,
                rootCgid
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            return response;
        }).catch(err => {
            return err;
        });
    }

    submitAddUser = (e) => {

        e.preventDefault();

        const { formatMessage } = this.props.intl;
        let { userModel } = this.props;
        this.setState({
            loaderIconVisible: true
        });

        let userDetails = localStorage.getItem("loginInfo");
        let loggedInUser = JSON.parse(userDetails);

        if (this.props.userModel.previousPage === "/user/create") {
            let postData = {
                user: {
                    name: userModel.user.firstName.trim() + ' ' + userModel.user.lastName.trim(),
                    first_name: userModel.user.firstName.trim(),
                    last_name: userModel.user.lastName.trim(),
                    email: userModel.user.email.trim(),
                    retype_email: userModel.user.retypeEmail.trim(),
                    country_code: userModel.user.country_code,
                    phone_number: userModel.user.phoneNumber,
                    customer_id: localStorage.getItem("customerId"),
                    base_country: localStorage.getItem("basecountry"),
                    access_countries: JSON.stringify([localStorage.getItem("accesscountry")]),
                    language_id: userModel.user.languageList,
                },
                role_id: userModel.roleId,                
                user_group: this.state.selectedList.map(String),              
                user_name: loggedInUser.username,
                name:localStorage.getItem("userName"),
                customerName: localStorage.getItem("customerName")
            };
            http({
                method: "POST",
                url: UserMgmtAddUser.addUser,
                data: postData,
                config: { headers: { 'Content-Type': 'application/json' } }
            }).then(response => {
                this.setState({
                    userData: response.data,
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: formatMessage({ id: 'KC1084' }, { name: this.props.userModel.user.firstName + ' ' + this.props.userModel.user.lastName })
                    }
                })
                WoopraEvents(`${Constants.USER_CREATED}`);
            }).catch(err => {
                this.showError(err);
            });
        }
        else {
            let postData = {
                user: {
                    role_id: userModel.roleId,
                    customer_id: localStorage.getItem("customerId"),
                    email: userModel.user.email,
                    user_group: this.state.selectedList.map(String),
                }
            };
            http({
                method: "PUT",
                url: UserMgmtAddUser.editUser,
                data: postData,
                config: { headers: { 'Content-Type': 'application/json' } }
            }).then(async response => {
                await this.updateEaasCustomer(userModel.user.email, this.state.selectedList.join(','));
                await this.assignUnitReg(this.state.selectedList.join(','));
                this.setState({
                    userData: response.data,
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: formatMessage({ id: 'KC1085' }, { name: this.props.userModel.user.firstName + ' ' + this.props.userModel.user.lastName })
                    }
                })
                WoopraEvents(`${Constants.USER_NODE_ASSIGNED}`);
            }).catch(err => {
                this.showError(err);
            });
        }
    }
    onItemClick = async (e, link) => {
        if(!link.sameLevelAccess){
            if(!link.nextLevelAccess){
                return;
            }
        }
        this.setState({
            veryFirstTime: false,
        })
        const copydynasty = [...this.state.selectedDynasty];
        let dynasty = [];
        let checkselectedcopy = true;
        let levelofcountry = 2;
        const previousselected = [...this.state.selectedGroupName];
        let Currentselected = [];
        let matchedornot = false;
        if (previousselected !== undefined && previousselected.length > 0) {
            previousselected.forEach(function (item, index) {
                if ((item.category_id === link.category_id) && (item.level_id === levelofcountry && link.level_id === levelofcountry) && (item.cust_gp_id !== link.cust_gp_id) && (item.dynasty !== link.dynasty) && (item.group_name !== link.group_name)) {
                    matchedornot = true;
                }
            })
        }

        if (copydynasty.length) {
            copydynasty.forEach(el => {
                if (el === link.dynasty) {
                    dynasty = copydynasty.filter(el => el !== link.dynasty);
                    Currentselected = previousselected.filter(s => s.dynasty !== link.dynasty);
                    checkselectedcopy = false;
                }
                else if (el && el.includes(link.dynasty)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];
                }
                else if ((el && link.dynasty.includes(el)) && (!matchedornot)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];

                    checkselectedcopy = false;
                }
                else if (checkselectedcopy) {
                    dynasty = [...copydynasty, link.dynasty];
                    Currentselected = [...previousselected, link]
                }
            })
        }
        else {
            dynasty = [...copydynasty, link.dynasty];
            Currentselected = [...previousselected, link]
        }

        let cgid =[];
        for(let i=0; i<Currentselected.length; i++){
            cgid.push(Currentselected[i].cust_gp_id)
        };
        this.setState({
            selectedDynasty: dynasty,
            selectedGroupName: Currentselected,
            selectedList:cgid,
            link: link
        })  
        this.treeData.virtualScrollRef.current.recomputeRowHeights();        
    }

    onUnitItemClick(e, unit) {
        const { UNITID, BRAND_NAME } = unit;
        this.setState({
            isUnitItemClicked: false, link: {}, isAddressAvailable: false, unit: unit,
            isLoactionTypeAvailable: false, level: 6
        })
        this.treeData.activeNode = unit;
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
        //this.props.getOrganizationUnitsDetails(UNITID,BRAND_NAME);
    }

    // showUnits(link) {
    //     const showCollpaeCount = this.showCollpaseItemCount(link, true);
    //     let selectedGroupID = localStorage.getItem("selectedGroupID");
    //     let pickUnit = "";
    //     if (link.level_id === 5 && link.units && link.units.length > 0) {
    //         return link.units.map((unit, index) => {
    //             let treeActive = unit.UNITID === this.state.unit.UNITID && this.state.isUnitItemClicked ? 'treeActive' : '';
    //             // treeActive = (parseInt(selectedGroupID) === link.cust_gp_id) ? 'treeActive' : '';
    //             treeActive = (this.state.selectedList.includes(link.cust_gp_id)) ? 'treeActive' : '';
    //             // pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
    //             let pickUnit = {};
    //             const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
    //             const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unit.BRAND_NAME && i.MODEL_NAME === unit.MODEL_NAME) : [];
    //             if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
    //                 pickUnit['img'] = filteredBrandImage[0].LOGO_URL;
    //                 pickUnit['alt'] = filteredBrandImage[0].MODEL_NAME;
    //                 pickUnit['title'] = filteredBrandImage[0].MODEL_NAME;
    //             }else{
    //                 pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
    //             }
    //             const unitInActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "unitDeactive" : ""

    //             let smartGroupDet = unit.smartGroup ? unit.smartGroup : [];
    //             let smartGroupName=[];
    //             if (smartGroupDet.length > 3) {
    //                 smartGroupName.push({
    //                     "sgName":"",
    //                     "sgFirstName": smartGroupDet.length,
    //                 })
    //             } else if (smartGroupDet.length > 0) {
    //                 smartGroupDet.map((item, i) => {
    //                     smartGroupName.push({
    //                         "sgName":item.name,
    //                         "sgFirstName":item.smartGroupKeyName && item.smartGroupKeyName !=="null" ? item.smartGroupKeyName : item.name.charAt(0),
    //                     })
    //                 })
    //             }
    //             return <li>
    //                 <div className='orgznSiteTreeLiDiv'>
    //                     <Link to="#" className={treeActive}
    //                         onClick={(e) => { this.onUnitItemClick(e, unit) }}>
    //                         {showCollpaeCount && <div><span className="treeImg">
    //                             <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
    //                         </span>
    //                             <span className="treeText deviceModel"
    //                             ><b>{unit.UNITID}</b></span>
    //                             <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
    //                         {!showCollpaeCount && <div><span className="treeImg">
    //                             <img src={pickUnit ? pickUnit.img : bg} alt="" />
    //                         </span>
    //                             <span className="treeText deviceModel">{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span> 
    //                             <span className={unitInActive}></span>
    //                             {/* {smartGroupName.map((item, i) => {
    //                                 return <span className = "sgFL">{item.sgFirstName}&nbsp;</span>
    //                             })} */}
    //                             </div>}
    //                     </Link>
    //                 </div>
    //             </li>
    //         });
    //     }
    // }

    // checkSamelevel(pDynasty, cDynasty) {
    //     let pDyn = [];
    //     let cDyn = [];
    //     let sameLevel = true;
    //     if (pDynasty && pDynasty.length > 0) {
    //         pDyn = pDynasty.split(',')
    //     } else {
    //         sameLevel = false;
    //     }
    //     if (cDynasty && cDynasty.length > 0) {
    //         cDyn = cDynasty.split(',')
    //     } else {
    //         sameLevel = false;
    //     }
    //     pDyn.map((i, index) => {
    //         if (pDyn[index] !== cDyn[index]) return sameLevel = false;
    //     })
    //     return sameLevel;

    // }
    // onCollapseButtonClick(e, link) {        
    //     let showLink = this.state.showLink;
    //     let collpaseObj = { dynasty: '', collapsed: false, count: 0 }
    //     const findIndex = showLink.findIndex(item => item.dynasty === link.dynasty);
    //     if (e.target['className'].includes('collapsed')) {
    //         collpaseObj = { dynasty: link.dynasty, collapsed: false, count: link.child_count }
    //     } else {
    //         collpaseObj = { dynasty: link.dynasty, collapsed: true, count: link.child_count }
    //     }
    //     if (findIndex > -1) {
    //         showLink[findIndex] = collpaseObj;
    //     } else {
    //         showLink.push(collpaseObj)
    //     }

    //     this.setState({ showLink: showLink })
    // }
    // showCollpaseItemCount(link, showUnits = false) {
    //     const { showLink } = this.state;
    //     let collapseItem;
    //     if (showUnits) {
    //         collapseItem = showLink.find(item => {
    //             if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
    //                 item.dynasty.split(',').length === link.dynasty.split(',').length)
    //                 return item;
    //         });
    //     } else {
    //         collapseItem = showLink.find(item => {
    //             if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
    //                 item.dynasty.split(',').length + 1 === link.dynasty.split(',').length)
    //                 return item;
    //         });
    //     }
    //     if (collapseItem) {
    //         if (link.dynasty && collapseItem) {
    //             return collapseItem.count;
    //         }
    //         return null;
    //     } else {
    //         return null;
    //     }
    // }
    // showLinks(linkList) {
    //     let level;
    //     let groupName = '';
    //     let selectedGroupID = localStorage.getItem("selectedGroupID");
    //     if (!linkList) return null;
    //     if (linkList && linkList.length > 0) {
    //         return linkList.map((link, index) => {
    //             level = link.level_id;
    //             groupName = link.group_name;
    //             const nextLevel = level + 1;
    //             const id = link.cust_gp_id + link.parent_group_id + link.category_id + link.level_id;
    //             const leveClassName = 'level' + nextLevel + 'Expand0' + id;
    //             let isParentDivActive = link.dynasty && 
    //             // (this.checkSamelevel(this.state.link.dynasty, link.dynasty) || ((parseInt(selectedGroupID) === link.cust_gp_id) && this.state.pageLoad)) ? 'treePrentDivActive' : '';
    //             // const treeActive = link.dynasty === this.state.link.dynasty || ((parseInt(selectedGroupID) === link.cust_gp_id) && this.state.pageLoad) ? 'treeActive' : '';

    //             (this.checkSamelevel(this.state.link.dynasty, link.dynasty) || ((this.state.selectedList.includes(link.cust_gp_id)) && this.state.pageLoad)) ? 'treePrentDivActive' : '';
    //             const treeActive = link.dynasty === this.state.link.dynasty || ((this.state.selectedList.includes(link.cust_gp_id)) && this.state.pageLoad) ? 'treeActive' : '';

    //             const showCollpaeCount = this.showCollpaseItemCount(link);
    //             const location_type_Select = JSON.parse(link.dynastyAttributes);
    //             let picLocation = brandsLocationLogo.find(b => b.name === location_type_Select.location_type);
    //             return <li>
    //                 <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
    //                     <Link to="#" className={(link.level_id === 5 && link.units && link.units.length > 0)
    //                         || (link['level' + nextLevel] && link['level' + nextLevel].length > 0)
    //                         ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
    //                         onClick={(e) => { this.onItemClick(e, link) }}>
    //                         {showCollpaeCount && <div><span className="treeImg">
    //                             <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
    //                         </span>
    //                             <span className="treeText deviceModel">
    //                                 <b>{groupName}</b></span>
    //                             <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
    //                         {!showCollpaeCount && <div><span className="treeImg">
    //                             <img src={link.imagePath ? link.imagePath : picLocation ? picLocation.img : bg} alt="" />
    //                         </span>
    //                             <span className="treeText deviceModel">
    //                                 <b>{groupName}</b></span></div>}
    //                     </Link>
    //                     {((link['level' + nextLevel] && link['level' + nextLevel].length > 0) ||

    //                         (link.level_id === 5 && link.units && link.units.length > 0)) &&
    //                         <button className="btn btn-primary expdCollpse  " type="button" data-toggle="collapse"
    //                             data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
    //                             onClick={(e) => this.onCollapseButtonClick(e, link)}>
    //                             &nbsp;</button>}
    //                     <ul className="collapse show" id={leveClassName}>
    //                         {this.showLinks(link['level' + nextLevel])
    //                         }
    //                         {this.showUnits(link)}
    //                     </ul>
    //                 </div>
    //             </li>

    //         });
    //     }
    //     level = linkList.level_id;
    //     groupName = linkList.group_name;
    //     const nextLevel = level + 1;
    //     const id = linkList.cust_gp_id + linkList.parent_group_id + linkList.category_id + linkList.level_id;
    //     const leveClassName = 'level' + nextLevel + 'Expand0' + id;
    //     let isParentDivActive = (this.checkSamelevel(this.state.link.dynasty, linkList.dynasty) || ((this.state.selectedList.includes(linkList.cust_gp_id)) && this.state.pageLoad)) ? 'treePrentDivActive' : '';
    //     const treeActive = linkList.dynasty === this.state.link.dynasty || ((this.state.selectedList.includes(linkList.cust_gp_id)) && this.state.pageLoad) ? 'treeActive' : '';
    //     return <ul>
    //         <li>
    //             <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
    //                 <Link to="#" className={linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0
    //                     ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
    //                     onClick={(e) => { this.onItemClick(e, linkList) }}>
    //                     <span className="treeImg">                            
    //                         <img src={linkList.imagePath ? linkList.imagePath : bg} alt="" />
    //                     </span>
    //                     <span className="treeText deviceModel"
    //                     ><b>{groupName}</b></span>                        
    //                 </Link>
    //                 {((linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0) ||

    //                     (linkList.level_id === 5 && linkList.units && linkList.units.length > 0)) &&
    //                     <button className="btn btn-primary expdCollpse " type="button" data-toggle="collapse"
    //                         data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
    //                         onClick={(e) => this.onCollapseButtonClick(e, linkList)}>
    //                         &nbsp;</button>}

    //                 <ul className="collapse show " id={leveClassName}>
    //                     {linkList['level' + nextLevel] && this.showLinks(linkList['level' + nextLevel])}
    //                     {this.showUnits(linkList)}
    //                 </ul>

    //             </div>
    //         </li>
    //     </ul>
    // }

    /**User Story 83428: Org View Collapse/Expand - All assign, transfer Screens*/

    /**
     * Check if the row has at least one visible node
     * @param {*} rowData Row Array
     * @returns Boolean - Return true if it has visible node else return false
     */
    doesRowHasVisibleNode(rowData) {
        return rowData.some((node, i) => node && node.__NODE_DATA__.isNode && (i < 5 ? !this.getNodeStatus(node).isInvisible : !this.getUnitNodeStatus(node).isInvisible));
    }
    /**
    * Get the Node status like invisible, count node
    * @param {*} node Node object
    * @returns Object Status object
    */
    getNodeStatus(node) {
        const nodeData = node.__NODE_DATA__;
        const dynastyArr = node.dynasty.split(',').map(item => parseInt(item));
        // Visibility, Count Node, Display count calculation
        const dynastyArrCopy = [...dynastyArr];
        dynastyArrCopy.pop(); // Remove its own ID
        dynastyArrCopy.length && dynastyArrCopy.pop(); // Remove parent node ID
        const isForeNodeCollapsed = dynastyArrCopy.some(item => this.treeData.collapsedNodes[item]);
        const isParentCollapsed = this.treeData.collapsedNodes[node.parent_group_id];
        const isFirstChild = nodeData.isFirstChild || nodeData.isOnlyChild;
        const parentNode = nodeData.parentRowIndex !== null ? this.state.flattenTreeData[nodeData.parentRowIndex][nodeData.parentColIndex] : null;
        // Active status, Root Node active status calculation
        let isRootActiveNode = false;
        let isActive = false;
            for (let i = 0; i<=5; i++){
                if(dynastyArr.includes(this.state?.selectedList[i])){
                    isActive = true;
                    break;
                }
            }
            isRootActiveNode = this.state?.selectedList.includes(node.cust_gp_id) ? true : false;
        return {
            isInvisible: isForeNodeCollapsed || (isParentCollapsed && !isFirstChild),
            isCountNode: !isForeNodeCollapsed && isParentCollapsed && isFirstChild,
            displayCount: parentNode ? parentNode.child_count : 0,
            isRootActiveNode,
            isActive
        };
    }
    /**
     * Row render callback for Virtual Scroll component
     * @param {*} param Event object
     * @returns ReactDOM
     */
    treeViewRowRenderer({ index, key, style }) {
        const { flattenTreeData } = this.state;
        const rowData = flattenTreeData[index];
        if (!this.doesRowHasVisibleNode(rowData)) {
            return <div className="treeViewRow" key={key} style={style}></div>
        }
        return <div className="treeViewRow" key={key} style={style}>
            {new Array(5).fill().map((_, j) => this.getNodeView(rowData[j], { key: `${key}_${j}` }))}
            {this.getUnitNodeView(rowData[5], { key: `${key}_5` })}
        </div>
    }
    /**
     * Create view elements for Tree View Unit Node
     * @param {*} unitNode Unit node object
     * @param {*} options Additional options
     * @returns ReactDOM
     */
    getUnitNodeView(unitNode, { key }) {
        // For empty
        if (!unitNode) {
            return <div key={key} className="treeViewCell"></div>
        }
        // For node
        const status = this.getUnitNodeStatus(unitNode);
        if (status.isInvisible) {
            return <div key={key} className="treeViewCell"></div>
        } else if (status.isCountNode) {
            const { showElementInfo } = this.state;
            const activeClass = status.isActive ? 'active' : '';
            const rootActiveClass = status.isRootActiveNode ? 'treeActive' : '';
            return <div key={key} className={`treeViewCell unitCell countCell ${activeClass} ${rootActiveClass}`}>
                <Link
                    className="treeViewNode"
                    to="#"
                    onDoubleClick={() => { this.setState({ showElementInfo: !showElementInfo }) }}
                    onClick={(e) => { this.onUnitItemClick(e, unitNode) }}>
                    {status.displayCount}
                </Link>
            </div>
        }
        const { showElementInfo, link } = this.state;
        //this.showUnits(link);
        const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
        const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unitNode.BRAND_NAME && i.MODEL_NAME === unitNode.MODEL_NAME) : [];
        let unitImage = '';
        if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "") {
            unitImage = filteredBrandImage[0].LOGO_URL;
        } else {
            unitImage = brandsMap[unitNode.MODEL_NAME] ? brandsMap[unitNode.MODEL_NAME].img : bg;
        }
        let checkUnitsIDsSelected = this.state.totalUnits && this.state.totalUnits.findIndex((item => unitNode.UNITID == item.UNITID));
        const activeValueKey  = unitNode?.subscription_status !== null && unitNode?.subscription_status !== 'null' ? unitNode.subscription_status === 0 ? 'INACTIVE' : 'ACTIVE' : unitNode.ACTIVATION_STATUS;

        const isUnitInActive = activeValueKey === 'INACTIVE-ONLINE' || activeValueKey === 'INACTIVE';
        const nodeData = unitNode.__NODE_DATA__;
        const { isFirstChild, isMidwayChild, isLastChild } = nodeData;
        const firstChildClass = isFirstChild ? 'firstChild' : '';
        const midwayChildClass = isMidwayChild ? 'midwayChild' : '';
        const lastChildClass = isLastChild ? 'lastChild' : '';
        const activeClass = status.isActive && checkUnitsIDsSelected != -1 ? 'active' : '';
        const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
        return <div key={key} className={`treeViewCell unitCell ${firstChildClass} ${midwayChildClass} ${lastChildClass} ${activeClass} ${rootActiveClass}`}>
            <Link
                to="#"
                className={`treeViewNode ${isUnitInActive ? 'inActiveUnitNode' : ''}`}
            >
                <img className="nodeImg" src={unitImage} />
                <span className="nodeInfo">
                    <b className="d-block text-truncate unitSerialNo">{unitNode.UNITID}</b>
                    <b className="d-block text-truncate">{unitNode.UNIT_NUMBER}</b>
                    <b className="d-block text-truncate">{unitNode.MODEL_NAME}</b>
                    {/* {
                        nodeData.smartGroupsName.map((item, i) => {
                            return <span key={i} className="sgIndicator">{item.sgFirstName}&nbsp;</span>
                        })
                    } */}
                </span>
                {isUnitInActive ? <span className="inActiveIcon"></span> : ''}
            </Link>
        </div>
    }
    /**
     * Tree View node expand/collapse click handler
     * @param {*} node Node object
     */
    handleExpandCollapse(node) {
        if (!this.treeData.collapsedNodes[node.cust_gp_id]) {
            this.treeData.collapsedNodes[node.cust_gp_id] = true;
        } else {
            delete this.treeData.collapsedNodes[node.cust_gp_id];
        }
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }
    /**
     * Return the computed Tree View row height
     * Compute the height based on row content
     * @param {*} param Event object
     * @returns Number
     */
    getTreeViewRowHeight({ index }) {
        const rowData = this.state.flattenTreeData[index];
        if (!this.doesRowHasVisibleNode(rowData)) {
            return 0;
        }
        // Compute row height based on unit node
        const unitNode = rowData[5];
        const status = unitNode ? this.getUnitNodeStatus(unitNode) : null;
        let rowBaseHeight = this.treeData.rowBaseHeight;
        if (unitNode && !status.isInvisible && !status.isCountNode) {
            if (unitNode.UNIT_NUMBER && unitNode.MODEL_NAME) {
                rowBaseHeight = rowBaseHeight + 15;
            }
            if (unitNode.__NODE_DATA__.smartGroupsName.length) {
                rowBaseHeight = rowBaseHeight + 22;
            }
        }
        return rowBaseHeight;
    }
    /**
     * Create view elements for Tree View Node
     * @param {*} node Node object
     * @param {*} options Additional options
     * @returns ReactDOM
     */
    getNodeView(node, { key }) {
        // For empty or connector
        if (!node) {
            return <div key={key} className="treeViewCell"></div>
        } else if (node.__NODE_DATA__.isConnector) {
            const parentNode = this.state.flattenTreeData[node.__NODE_DATA__.parentRowIndex][node.__NODE_DATA__.parentColIndex];
            const activeClass = this.getNodeStatus(parentNode).isActive ? 'active' : '';
            return <div key={key} className={`treeViewCell connector ${activeClass}`}></div>
        }
        // For node
        const nodeData = node.__NODE_DATA__;
        const status = this.getNodeStatus(node);
        console.log ("statusstatus",status);
        if (status.isInvisible) {
            return <div key={key} className="treeViewCell"></div>
        } else if (status.isCountNode) {
            const activeClass = status.isActive ? 'active' : '';
            const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
            return <div key={key} className={`treeViewCell countCell ${activeClass} ${rootActiveClass}`}>
                <div className="treeViewNodeWrapper">
                    <Link
                        className="treeViewNode"
                        to="#"
                        onClick={(e) => { this.onItemClick(e, this.getNodeFromHierarchyData(node)) }}>
                        {status.displayCount}
                    </Link>
                </div>
            </div>
        }
        const dynastyAttributes = node.dynastyAttributes ? JSON.parse(node.dynastyAttributes) : {};
        const nodeImage = node.level_id && (node.level_id === 5 || node.level_id === 4) && brandLocationsMap[dynastyAttributes.location_type] && brandLocationsMap[dynastyAttributes.location_type].img ?  brandLocationsMap[dynastyAttributes.location_type].img : node.imagePath || (brandLocationsMap[dynastyAttributes.location_type]
            ? brandLocationsMap[dynastyAttributes.location_type].img
            : bg);
        const { isFirstChild, isMidwayChild, isLastChild, hasChildren, unitCount } = nodeData;
        const isCollapsed = this.treeData.collapsedNodes[node.cust_gp_id];
        const firstChildClass = isFirstChild ? 'firstChild' : '';
        const midwayChildClass = isMidwayChild ? 'midwayChild' : '';
        const lastChildClass = isLastChild ? 'lastChild' : '';
        const hasChildrenClass = hasChildren ? 'hasChildren' : '';
        const activeClass = status.isActive ? 'active' : '';
        const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
        const hasChildrenClassVisibile = hasChildrenClass == '' ? unitCount ? 'hasChildren' : hasChildrenClass : hasChildrenClass;
        return <div key={key} className={`treeViewCell ${firstChildClass} ${midwayChildClass} ${lastChildClass} ${hasChildrenClassVisibile} ${activeClass} ${rootActiveClass}`}>
            <div className="treeViewNodeWrapper">
                <Link
                    className="treeViewNode"
                    to="#"
                    onClick={(e) => { this.onItemClick(e, this.getNodeFromHierarchyData(node), nodeImage) }}>
                    <img className="nodeImg" src={nodeImage} alt="" />
                    <span className="text-truncate nodeInfo"><b>{node.group_name}</b></span>
                </Link>
                {hasChildren || hasChildren == "" && unitCount ? <button className={`expandCollapseBtn ${isCollapsed ? 'collapsed' : ''}`} onClick={() => this.handleExpandCollapse(node)}></button> : ''}
            </div>
        </div>
    }
    /**
         * Get Node object from Hierarchy Data Structure
         * Use this method to retain the props removed during flatten array conversion
         * @param {*} searchNode Node to search
         * @returns Node object
         */
    getNodeFromHierarchyData(searchNode) {
        const dynastyArr = searchNode.dynasty.split(',').map(item => parseInt(item));
        let node = this.state.linkList;
        for (let i = 0; i < dynastyArr.length; i++) {
            const nodes = node[`level${i + 1}`];
            for (let j = 0; j < nodes.length; j++) {
                if (nodes[j].cust_gp_id === dynastyArr[i]) {
                    node = nodes[j];
                    break;
                }
            }
        }
        node = { ...node };
        node.__NODE_DATA__ = { ...searchNode.__NODE_DATA__ };
        return node;
    }
    /**
     * Get the Unit Node status like invisible, count node
     * @param {*} unitNode Unit Node object
     * @returns Object Status object
     */
    getUnitNodeStatus(unitNode) {
        const nodeData = unitNode.__NODE_DATA__;
        const parentNode = this.state.flattenTreeData[nodeData.parentRowIndex][nodeData.parentColIndex];
        const dynastyArr = parentNode.dynasty.split(',').map(item => parseInt(item));
        // Visibility, Count Node, Display count calculation
        const dynastyArrCopy = [...dynastyArr];
        dynastyArrCopy.pop(); // Remove its own ID
        const isForeNodeCollapsed = dynastyArrCopy.some(item => this.treeData.collapsedNodes[item]);
        const isParentCollapsed = this.treeData.collapsedNodes[parentNode.cust_gp_id];
        const isFirstChild = nodeData.isFirstChild || nodeData.isOnlyChild;
        // Active status, Root Node active status calculation
        let isRootActiveNode = false;
        let isActive = false;
        for (let i = 0; i <= dynastyArr.length; i++) {
            if (dynastyArr.includes(this.state?.selectedList[i])) {
                isActive = true;
                break;
            }
        }

        isRootActiveNode = false;
        // Selection status
        return {
            isInvisible: isForeNodeCollapsed || (isParentCollapsed && !isFirstChild),
            isCountNode: !isForeNodeCollapsed && isParentCollapsed && isFirstChild,
            displayCount: parentNode.child_count,
            isRootActiveNode,
            isActive
        };
    }
    /*  */
    handleManualExpandCollapse = (levelID, data, makeCollapse, testvalue) => {
        this.treeData.active = levelID
        _.forEach(data, item => {
            _.forEach(item, node => {
                if (node && node.category_id == levelID && node.child_count > 0 && makeCollapse) {
                    if (!this.treeData.collapsedNodes[node.cust_gp_id]) {
                        this.treeData.collapsedNodes[node.cust_gp_id] = true;
                        this.treeData.expand[node.level_id] = true;
                    } else {
                        delete this.treeData.collapsedNodes[node.cust_gp_id];
                        delete this.treeData.expand[node.level_id];
                    }
                }
            })
        })
        if(this.treeData && this.treeData.virtualScrollRef && this.treeData.virtualScrollRef.current && this.treeData.virtualScrollRef.current.recomputeRowHeights){
            this.treeData.virtualScrollRef.current.recomputeRowHeights();
        }
    }
    render() {
        const { formatMessage } = this.props.intl;
        let { success_modal, error_modal, selectedList, flattenTreeData } = this.state;
        const { linkList, loaderIconVisible } = this.state;
        
        if(selectedList.length > 0){
            $("#btnfinish").addClass("btn-default-text activeState");
            $("#btnfinish").removeClass("disabled");
        }

        let levelHeader = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                levelHeader = (
                    categoryList.filter(el => el.level_id >= 1).map(item => <li>
                        <Link className={item.level_id === 1 ? "" : this.treeData.expand[item.level_id - 1] ? `collapseExpandHeader ${item.level_id - 1 === this.treeData?.active ? "activeArrow" : ''}` : "collapseExpandHeader expanded"} key={item.level_id} to="#" title={formatMessage({ id: 'KC1693' }, { number: item.level_id })} onClick={() => this.handleManualExpandCollapse(item.level_id - 1, this.state.flattenTreeData, true)}>{item.category_name}</Link>
                    </li>)
                );
            }
        }
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="orgznManagementWrapper">
                        <div className="assignOrgHeader">
                            <ul>
                                <li>
                                    <Link to={{
                                        pathname: this.props.userModel.assignUserBackPage,
                                        data: this.props.userModel.roleId
                                    }} title={formatMessage({ id: 'KC0989' })} onClick={(e) => this.setUserToState(e)} className="backIcon " >&nbsp;</Link>
                                </li>
                                <li>
                                
                                {formatMessage({ id: "KC0582"})}
					            </li>
                                <li className={selectedList.length > 0 ? "" : "disabled text-right"}>
                                    <Link id="btnfinish" to="/assignUserToTopology" title={formatMessage({ id: "KC0056"})} onClick={(e) => this.submitAddUser(e)}><FormattedMessage id={'KC0056'} /></Link>
                                </li>
                            </ul>
                        </div>
                        
                        <div className="collapseExpandHeader flatten">
                            <ul>
                            {levelHeader}
                            </ul>
                        </div>

                        <div className="assignOrgTreeWrapper">
                            <div className="orgznSiteTree">
                                <div className="treeViewBody">
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                ref={this.treeData.virtualScrollRef}
                                                width={width || 100}
                                                height={height || 100}
                                                overscanRowCount={this.treeData.virtualScrollBufferRowCount}
                                                rowCount={flattenTreeData.length}
                                                rowHeight={this.getTreeViewRowHeight}
                                                rowRenderer={this.treeViewRowRenderer} />
                                        )}
                                    </AutoSizer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerNameStringID='KC0581' headerName="User Management" activeClass="UserManagementNav" />
            </div>

        )
    }
};

function mapStateToProps(state) {
    // 
    return {
        userModel: state.userReducer.UserModel,
        searchData: state.advanceSearch.searchData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel,
        searchVisible, searchText, clearSearchText
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignUserToTopology));