import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SuccessMoal from '../modal/success-model';
import ErrorModal from '../modal/error-model';
import auth from '../../service/authService';
import $ from 'jquery';


export default class SSOLoginTenantRedirect extends Component {

    nextPath(path) {
        this.props.history.push(path);
    }

    onSuccessCloseModal() {
        this.props.history.push(`/dashboardLocations`);
    }

    constructor(props) {
        super(props);
        this.state = {
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            loaderIconVisible: false,
            redirect: false
        };
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/`);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#0d3968";
        $("body").addClass("loginBg");
        let { match } = this.props;
        const { params } = match;
    
        if (params.uuid) {
            let result = await this.cognitoUrl(params.uuid)
            console.log("url: ", result.cognito_url);
            window.location.replace(result.cognito_url);
        }
        else {
            this.setState({
                error_modal: {
                    open: true,
                    message: "Un-authorized access."
                },
            })
        }
    }

    cognitoUrl = async (uuid) => {
        this.setState({ loaderIconVisible: true });
        
        return new Promise(async (resolve, reject)=>{
			 auth.getSSOCognitoURL({uuid}).then(async res => { 
				let { status } = res;
				if (status === 200) {
					resolve(res.data)
				} else {
					reject({
						res: {
							data: res.data
						}
					});
				}		
			}).catch(error => {
				this.setState({ loaderIconVisible: false })
				let errorResponse = error.response;
            if (errorResponse && (errorResponse.status === 400 || errorResponse.status === 500)) {
                let responseMessage = errorResponse.data.message || "";
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        message: responseMessage,
                        open: true
                    }
                });
            }
            else {
                this.setState({ loaderIconVisible: false });
            }
			});
		  });
    }

    getParameterByName = (name) => {
        const url = window.location.href;
        console.log("url: " + window.location.href);
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?#&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    render() {
        const { success_modal, error_modal, loaderIconVisible } = this.state;
        return (
            <React.Fragment>
                <div className="loginBg">
                    <div id="adminWrapper">
                        <div id="loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
                            <div className="loadingProgressIn"></div>
                        </div>
                        <nav className="navbar navbar-expand-lg navbar-dark bgAdmin">
                            <div className="collapse navbar-collapse" id="navbarContent">
                                <div className="navbar-header">
                                    <Link className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" to="#">&nbsp;</Link>
                                </div>
                            </div>
                        </nav>

                        <div id="page-wrapper">
                            <div className="welcomText">Kitchen Connect Portal</div>
                            <div className="LoginBtnWrapper">
                                <div id="loginBtnFormID">
                                    <div className="loginButton">
                                        <ul>
                                            <li>&nbsp;</li>
                                            <li>
                                                <div className="loginIWrapper">
                                                    {/* <button class="btn btn-secondary singINBtn" type="button" data-toggle="modal" data-target="#SignInModal" >							  </button> */}
                                                </div>
                                            </li>
                                            <li>&nbsp;</li>
                                        </ul>
                                        <ul>
                                            <li>
                                                {/* <Link to="#" data-toggle="modal" data-target="#SignInModal" title="Create Account">Create Account</Link> */}
                                            </li>
                                            <li>
                                                <div className="loginIWrapper">
                                                    {/* <Link to="/existingUserLogin" title="Use Existing Account" class="useExistingAccount">Use Existing Account</Link>  */}
                                                    {/* <Link to="#" data-toggle="modal" data-target="#SignInModal1" title="Create Account">Create Account</Link> */}
                                                    {/* <Link to="#" title="Language" class="languageSel"></Link> */}
                                                </div>
                                            </li>
                                            <li>&nbsp;</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                            <ErrorModal open={error_modal.open} message={error_modal.message} onCloseModel={() => this.closeModel()} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
};

