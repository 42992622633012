import $ from 'jquery';
import React, { useEffect, useState, useRef } from 'react';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import { headerRow } from './plan';
import PaymentCard from '../SubscriptionForm/paymentCard';
import { BaseService, subscriptionManagement } from '../../service/api';
import http from '../../service/httpService';

const paymentTypeImage = {
    'visa': require('../../images/cardtype/visa.png'),
    'american_express': require('../../images/cardtype/american_express.png'),
    'master': require('../../images/cardtype/mastercard.png'),
    'discover': require('../../images/cardtype/discover.png'),
    'diners_club': require('../../images/cardtype/dinersclub.png'),
    'jcb': require('../../images/cardtype/jcb.png'),
    'unionpay': require('../../images/cardtype/unionpay.png'),
    'cirrus': require('../../images/cardtype/cirrus.png'),
    'delta': require('../../images/cardtype/delta.png'),
    'direct': require('../../images/cardtype/direct.png'),
    'electronvisa': require('../../images/cardtype/electronvisa.png'),
    'maestro': require('../../images/cardtype/maestro.png'),
    'paypal': require('../../images/cardtype/paypal.png'),
    'solo': require('../../images/cardtype/solo.png'),
    'switch': require('../../images/cardtype/switch.png'),
    'westernunion': require('../../images/cardtype/westernunion.png')
}

const Payments = (props) => {
    const { formatMessage } = props.intl;
    const [loaderIconVisible, setLoaderIconVisible] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [noPaymentAvailable, setNoPaymentAvailable] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [editView, setEditView] = useState(false);
    const [isAuth, setIsAuth] = useState(localStorage.getItem('Access-Token') ? true : false)
    const creditcCard = useRef();

    useEffect(() => {
        var urlParams = new URLSearchParams(window.location.search);
        const encodedEmail = urlParams.get("euc");
        const decodedEmail = atob(encodedEmail);
        if (!isAuth) {
            props.history.push(`/login?redirectUri=payment&euc=${encodedEmail}`)
        } else {
            let user = localStorage.getItem('user');
            if (user !== decodedEmail && encodedEmail) {
                if (localStorage.getItem('isCookieAccepted')) {
                    localStorage.clear();
                    localStorage.setItem('isCookieAccepted', true);
                } else {
                    localStorage.clear();
                }
                props.history.push(`/login?redirectUri=payment&euc=${encodedEmail}`)
            }
        }
        $('body').removeClass("loginBg");
        $("div").removeClass("modal-backdrop fade show");
        getPaymentInfo()
        document.body.style.backgroundColor = "#F2F2F2";
        return () => {
        }
    }, []);

    const getPaymentInfo = async () => {
        await http.get(`${subscriptionManagement.paymentApi}?customerid=${localStorage.getItem("customerId")}`, {
            headers: {
                'Content-Type': 'application/json',
            },
            data: {}
        }).then(async response => {
            if (response && response.data) {
                if (response?.data?.payment_profile) {
                    setPaymentDetails(response.data.payment_profile);
                    setNoPaymentAvailable(false);
                } else {
                    setNoPaymentAvailable(true)
                }
            }
        }).catch(err => {
        });
    }

    const updatePaymentMethod = (token) => {
        setLoaderIconVisible(true)
        return new Promise(async (resolve, reject) => {
            await http.post(`${subscriptionManagement.paymentApi}`, {
                "customerid": localStorage.getItem("customerId"),
                token,
                "isBillingAddressSame":true //(creditcCard.current.state.checkedValue).toString()
            },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(async response => {
                    if (response.data?.payment_profile) {
                        setPaymentDetails(response.data?.payment_profile)
                        $('#paymentSuccss').modal('show');
                        setLoaderIconVisible(false);
                    } else {
                        setLoaderIconVisible(false);
                        $('#paymentFaileddModal').modal('show');
                    }
                    resolve(response)
                }).catch(err => {
                    resolve(err)
                });
        });
    }

    const throwError = (err) => {        
        setLoaderIconVisible(false);
        let errMsg = err;
        if (errMsg.message !== "Some fields are not valid.") {
            $('#paymentFaileddModal').modal('show');
        }
    }

    const callChargifyApi = () => {
        return new Promise(async (res, rej) => {
            setLoaderIconVisible(true);
            try {
                const apiCall = await creditcCard.current.handleSubmit();
                res(apiCall);
            } catch (err) {
                setLoaderIconVisible(false);
                let errMsg = err;
                if (errMsg.message !== "Some fields are not valid.") {
                    $('#paymentFaileddModal').modal('show');
                }
                rej(err)
            }
        })
    }

    const setIsLoaded = (val) => {
        setLoaded(val)
    }

    const createCustomerInChargify = async (token) => {
        return new Promise(async (resolve, _) => {
            await http.post(`${subscriptionManagement.createCustomoer}`, {
                "customer_id": localStorage.getItem("customerId"),
                token,
                "isBillingAddressSame": (creditcCard.current.state.checkedValue).toString()
            },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(async response => {
                    await getPaymentInfo();
                    resolve(response)
                }).catch(err => {
                    resolve(err)
                });
        });
    }

    const getCustomerGroupdet = async () => {
        await http.get(BaseService.customer_Group_det,
            {
                headers: {
                    'customer_id': localStorage.getItem("customerId"),
                    'Content-Type': 'application/json'
                }, data: {},
            }).then(async response => {
                localStorage.setItem("chargifySubscriptionStatus", response.data.chargifySubscriptionStatus || 'Inactive');
                localStorage.setItem("subscriptionId", localStorage.getItem('subscriptionId') !== "" ? localStorage.getItem('subscriptionId') : response.data.subscriptionDetails.chargify_subscription_id ? response.data.subscriptionDetails.chargify_subscription_id : "");
                localStorage.setItem("subscriptionPlan", response.data.subscriptionDetails.kc_subscription_plan_id ? response.data.subscriptionDetails.kc_subscription_plan_id : "");
            }).catch(err => {
            });
    }

    const save = async () => {
        try {
            await callChargifyApi();
            const isExistingChargifyCustomer = localStorage.getItem("chargifySubscriptionStatus");
            if (isExistingChargifyCustomer === "Inactive") {
                try {
                    await createCustomerInChargify(creditcCard.current.state.token);
                    await getCustomerGroupdet();
                    await creditcCard.current.loadKCUI(creditcCard.current.state.envData);
                    $('#paymentSuccss').modal('show');
                    setLoaderIconVisible(false);
                } catch (error) {
                    setLoaderIconVisible(false);
                    let errMsg = error;
                    if (errMsg.message !== "Some fields are not valid.") {
                        $('#paymentFaileddModal').modal('show');
                    }
                }
            } else {
                await updatePaymentMethod(creditcCard.current.state.token);
                await creditcCard.current.loadKCUI(creditcCard.current.state.envData);
            }
        } catch (error) {            
            setLoaderIconVisible(false);
            let errMsg = error;
            if (errMsg.message !== "Some fields are not valid.") {
                $('#paymentFaileddModal').modal('show');
            }
        }
    }

    const splitCardNumber = paymentDetails?.masked_card_number?.split('-');
    return (
        <div id="adminWrapper">
            <div className="searchToggleTransparent"></div>
            <div className="toggleTransparent"></div>
            <div className="sideNavToggleTransparent"></div>
            <div className="userToggleTransparent"></div>
            <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
            </div>
            {isAuth ? <div id="page-content-wrapper">
                <div className="lightBgWrapper">
                    <div className="navBarXScroll">
                        <ul>
                            {headerRow(formatMessage, 'Payments')}
                        </ul>
                    </div>
                    {editView || !loaded ? null : noPaymentAvailable ? <div className="tableSMViewW">
                        <div className="paymentsTable">
                            <h2><FormattedMessage id='KC2440' /></h2>
                            <div className="titleContent"><FormattedMessage id='KC2486' /></div>
                            <div className="buttonWrapper alignCenter"><div className="subscriptioninfoD"><FormattedMessage id='KC2487' /></div></div>
                            <div className="buttonWrapper alignCenter"><button type="button" className="btn addPayment" onClick={() => setEditView(true)}><FormattedMessage id='KC2488' /></button></div>
                        </div>
                    </div> :
                        <div className="tableSMViewW">
                            <div className="paymentsTable">
                                <h2><FormattedMessage id='KC2440' /></h2>
                                <div className="titleContent"><FormattedMessage id='KC2486' /></div>
                                {paymentDetails ? <div className="paymentFDetailsO">
                                    <div className="paymentFDetails">
                                        <h4><FormattedMessage id='KC2490' /></h4>
                                        <div className="cardType">{paymentTypeImage[paymentDetails?.card_type] ? <img src={paymentTypeImage[paymentDetails?.card_type]} alt={paymentDetails?.card_type} /> : paymentDetails?.card_type?.toUpperCase()}</div>
                                        <div className="accountNumber"><span>●●●●</span><span>●●●●</span><span>●●●●</span><span>{splitCardNumber?.length ? splitCardNumber[splitCardNumber.length - 1] : ''}</span></div>
                                        <div className="accountHname">{paymentDetails.first_name} {paymentDetails.last_name} <span>{paymentDetails.expiration_month}/{paymentDetails?.expiration_year ? paymentDetails?.expiration_year.toString().charAt(paymentDetails?.expiration_year.toString().length - 2) + paymentDetails?.expiration_year.toString().charAt(paymentDetails?.expiration_year.toString().length - 1) : ''}</span></div>
                                    </div>
                                </div> : null}
                                <div className="buttonWrapper alignCenter"><button type="button" className="btn addPayment" onClick={() => setEditView(true)}><FormattedMessage id='KC2489' /></button></div>
                            </div>
                        </div>}


                    <form className="tableSMViewW" style={{ display: loaded && editView ? 'block' : 'none' }}>
                        <div className="paymentsTable">
                            <h2><FormattedMessage id='KC2440' /></h2>
                            <div className="formRequired"><FormattedMessage id='KC2441' /></div>
                            <PaymentCard ref={creditcCard} loaderIconVisible={loaderIconVisible} handleLoader={(val) => setLoaderIconVisible(val)} setIsLoaded={setIsLoaded} throwError={throwError}/>
                            <div className="buttonWrapper">
                                <button type="button" className="btn cencelBtn" data-dismiss="modal" onClick={async () => {
                                    setEditView(false)
                                    await creditcCard.current.loadKCUI(creditcCard.current.state.envData);
                                }}><FormattedMessage id='KC0021' />
                                </button>
                                <button type="button" className="btn saveBtn" onClick={save}><FormattedMessage id='KC0123' /></button></div>
                        </div>
                        <div className="pageNotes">* <FormattedMessage id='KC2442' /></div>
                    </form>
                </div>
            </div> : null}
            {/* <!-- Payment details updated --> */}
            <div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="paymentSuccss" tabindex="-1" role="dialog">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="confirmMContententD">
                                <div className="h2Title successInfo"><FormattedMessage id='KC2484' /></div>
                                <p><FormattedMessage id='KC2485' /></p>
                            </div>
                        </div>
                        <div className="modal-footer textAlignCenter">
                            <button className="btn confirmnBtn" data-dismiss="modal" type="button" onClick={() => setEditView(false)}><FormattedMessage id='KC0777' /></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- //Payment details updated --> */}
            {/* <!-- Payment details update failed --> */}
            <div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="paymentFaileddModal" tabindex="-1" role="dialog">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="confirmMContententD">
                                <div className="h2Title failedInfo"><FormattedMessage id='KC2491' /></div>
                                <p><FormattedMessage id='KC2492' /></p>
                            </div>
                        </div>
                        <div className="modal-footer textAlignCenter">
                            <button className="btn confirmnBtn" data-dismiss="modal" type="button" onClick={() => setEditView(false)}><FormattedMessage id='KC0777' /></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- //Payment details update failed --> */}
            {isAuth ? <UserManagementHeader headerName={formatMessage({ id: 'KC1668' })} headerNameStringID="KC1668" activeClass="billingPortalNav" /> : null}
        </div >
    )
};

export default injectIntl(Payments)