import React, { Component } from 'react';
import $ from 'jquery';
import { DeviceRegistration } from '../../service/api';
import http from '../../service/httpService';

// POC for prefilling data values
const data = {
    address1: "11, North Street",
    address2: "Test address",
    city: "Chennai",
    zip: "600126",
    state: "TN",
    country: "IN",
}

class PaymentCard extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.chargifyForm = React.createRef();
        this.state = {
            token: '',
            envData: {},
            checkedValue: true,
            custDetails: {}
        };
    }

    handleSubmit = () => {
        return new Promise(async (res, rej) => {
            await this.chargify.token(
                this.chargifyForm.current,

                (token) => {
                    $('.cfy-container--inline.cfy-container--invalid').css('border-color', '#ddd')
                    res(this.setState({ token: token }));
                },

                (error) => {
                    this.props.throwError(error)
                    rej(error);
                }
            );
        })
    }

    getChargifyApiKey = () => {
        return new Promise((res, rej) => {
            let url = DeviceRegistration.getChargifyApiKey;

            http.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {}
            }).then(async response => {
                if (response.data && response.data.length) {
                    const envIDValue = localStorage.getItem('subscriptionDetails') && JSON.parse(localStorage.getItem('subscriptionDetails'))?.env_id ? JSON.parse(localStorage.getItem('subscriptionDetails'))?.env_id : 1
                    const envId = response.data.find((i) => i.id === envIDValue);
                    this.setState({ envData: envId })
                    await this.loadKCUI(envId);
                    res(true);
                }
            }).catch(err => {
                res(false);
            });
        })
    }

    async loadKCUI(response) {
        const subDet = localStorage.getItem('subscriptionDetails') && JSON.parse(localStorage.getItem('subscriptionDetails')) ? JSON.parse(localStorage.getItem('subscriptionDetails')) : "";
        this.setState({
            custDetails: {
                address1: subDet.street_name,
                // address2: subDet.street_name,
                city: subDet.city,
                zip: subDet.zip_code,
                state: subDet.state,
                country: subDet.country,
            }
        })

        const styleObj = {
            field: {
                width: `100%`
            },
            input: {
                width: '100%',
                padding: '5px 30px 5px 5px',
                lineHeight: 'inherit',
                background: 'transparent',
                color: '#0D3968',
                fontSize: '18px',
                border: '1px solid #0D3968',
                borderRadius: '5px',
            },
            label: {
                float: 'left',
                clear: 'both',
                color: '#0D3968',
                fontSize: '18px',
                fontFamily: 'sans-serif',
                fontWeight: 'unset'
            },
            message: { paddingTop: '2px', paddingBottom: '1px' }
        }

        await this.chargify.load({
            selector: '#chargify-form',
            publicKey: response.public_key,
            hideCardImage: true,
            type: 'card',
            serverHost: response.server_host,
            addressDropdowns: true,
            gatewayHandle: response?.payment_gateway?.credit_card?.handle,
            current_vault: response?.payment_gateway?.credit_card?.current_valut,
            fields: {
                number: {
                    selector: '#chargify1',
                    label: 'Card Number *',
                    required: true,
                    placeholder: 'xxxx-xxxx-xxxx-0126',
                    // message: 'This field is not valid. Please update it.',
                    style: styleObj
                },
                firstName: {
                    selector: '#chargify5',
                    label: 'First Name *',
                    required: true,
                    placeholder: 'John',
                    // message: 'This field is not valid. Please update it.',
                    style: styleObj
                },
                lastName: {
                    selector: '#chargify6',
                    label: 'Last Name *',
                    required: true,
                    placeholder: 'Doe',
                    // message: 'This field is not valid. Please update it.',
                    style: styleObj
                },
                month: {
                    selector: '#chargify2',
                    label: 'Expiration Month *',
                    placeholder: 'MM',
                    required: true,
                    // message: 'This field is not valid. Please update it.',
                    style: styleObj
                },
                year: {
                    selector: '#chargify3',
                    label: 'Expiration Year *',
                    placeholder: 'YYYY',
                    required: true,
                    // message: 'This field is not valid. Please update it.',
                    style: styleObj
                },
                cvv: {
                    selector: '#chargify4',
                    label: 'CVV *',
                    placeholder: 'xxx',
                    // message: 'Invalid CVV',
                    required: true,
                    style: styleObj,
                    maxlength: '3',
                },
                address: {
                    selector: '#chargify7',
                    label: 'Address Line 1 *',
                    placeholder: 'Address 1',
                    required: true,
                    style: styleObj,
                    message: 'Invalid Address Line 1',
                    maxlength: '70',
                    value: subDet.street_name
                },
                address2: {
                    selector: '#chargify8',
                    label: 'Address Line 2',
                    placeholder: 'Address 1',
                    required: false,
                    style: styleObj,
                    message: 'Invalid Address Line 2',
                    maxlength: '70',
                    value: subDet.street_name
                },
                city: {
                    selector: '#chargify9',
                    label: 'City *',
                    placeholder: 'City',
                    required: true,
                    style: styleObj,
                    message: 'Invalid City',
                    maxlength: '50',
                    value: subDet.city
                },
                zip: {
                    selector: '#chargify10',
                    label: 'ZIP Code *',
                    placeholder: 'ZIP Code',
                    required: true,
                    style: styleObj,
                    message: 'Invalid ZIP Code',
                    maxlength: '15',
                    value: subDet.zip_code
                },
                country: {
                    selector: '#chargify9',
                    label: 'Country *',
                    placeholder: 'Country',
                    required: true,
                    style: styleObj,
                    message: 'Invalid Country',
                    maxlength: '50',
                    value: subDet.country
                },
                state: {
                    selector: '#chargify9',
                    label: 'State *',
                    placeholder: 'State',
                    required: true,
                    style: styleObj,
                    message: 'Invalid State',
                    maxlength: '50',
                    value: subDet.state
                },
            }
        });

        this.props.handleLoader(false);
        this.props.setIsLoaded(true);
        // $('.cfy-additional-label').html('')
    }

    async componentDidMount() {
        this.chargify = new window.Chargify();
        await this.getChargifyApiKey();
    }

    componentWillUnmount() {
        this.chargify.unload();
    }

    render() {
        return (
            <form ref={this.chargifyForm} className='payment-card-subscription'>
                <div id="chargify-form"></div>
                <div className="paymentForm">
                    <ul>
                        <li>
                            <div id="chargify1"></div>
                        </li>
                    </ul>
                </div>
                <div className="paymentForm">
                    <ul>
                        <li className="colm50 firstLastName">
                            <div id="chargify5"></div>
                        </li>
                        <li className="colm50 firstLastName">
                            <div id="chargify6"></div>
                        </li>
                    </ul>
                </div>
                <div className="paymentForm">
                    <ul>
                        <li className="colm50 firstLastName">
                            <div id="chargify2"></div>
                        </li>
                        <li className="colm50 firstLastName">
                            <div id="chargify3"></div>
                        </li>
                    </ul>
                    <ul>
                        <li className="colm50 firstLastName">
                            <div id="chargify4"></div>
                        </li>
                        <li className='colm50 firstLastName'></li>
                    </ul>
                </div>
                <h2 style={{ marginTop: 15, marginBottom: -20, color: "rgb(13, 57, 104)" }}>Billing Address</h2>
                <div className="paymentForm" style={{ color: "#6E87A2", fontSize: "14px", fontFamily: "sans-serif" }}>* Required fields</div>
                <div class={"customCheckbox customCheckboxWOlabel"}>
                    <input
                        type="checkbox"
                        id='chargify'
                        name={'chargify'}
                        checked={this.state.checkedValue}
                        onChange={(e) => {
                            this.setState({ checkedValue: e.target.checked })
                            this.loadKCUI(this.state.envData);
                        }}
                    />
                    <label style={{ marginTop: '12px', padding: '3px 30px', whiteSpace: 'normal', color: "rgb(13, 57, 104)", fontSize: "18px", fontFamily: "sans-serif" }} for={'chargify'}>{`Billing Address and Shipping Address are the same`}</label>
                </div>

                <div className={`paymentForm ${this.state.checkedValue ? 'disabled' : ''}`} style={{ display: this.state.checkedValue ? 'none' : '' }}>
                    <ul>
                        <li>
                            <div id="chargify7"></div>
                        </li>
                    </ul>
                </div>
                <div className={`paymentForm ${this.state.checkedValue ? 'disabled' : ''}`} style={{ display: this.state.checkedValue ? 'none' : '' }}>
                    <ul>
                        <li>
                            <div id="chargify8"></div>
                        </li>
                    </ul>
                </div>
                <div className={`paymentForm ${this.state.checkedValue ? 'disabled' : ''}`} style={{ display: this.state.checkedValue ? 'none' : '' }}>
                    <ul>
                        <li className="colm50 firstLastName">
                            <div id="chargify9"></div>
                        </li>
                        <li className="colm50 firstLastName">
                            <div id="chargify10"></div>
                        </li>
                    </ul>
                </div>
                <div className={`paymentForm ${this.state.checkedValue ? 'disabled' : ''}`} style={{ display: this.state.checkedValue ? 'none' : '' }}>
                    <ul>
                        {!this.props.loaderIconVisible ?
                            <li className="colm50 firstLastName">
                                <div id="chargify9"></div>
                            </li>
                            : null}
                        <li className="colm50 firstLastName">
                            <div id="chargify9"></div>
                        </li>
                    </ul>
                </div>
                {this.state.checkedValue ? <div style={{ marginTop: 0 }}>
                    {Object.keys(this.state.custDetails).length ? Object.keys(this.state.custDetails).map((dataVal) => {
                        return <p style={{ padding: 1, margin: 0, color: "#6E87A2", fontSize: "14px", fontFamily: "sans-serif" }}>{this.state.custDetails[dataVal]}</p>
                    }) : null}
                </div> : null}
            </form>
        )
    }
}

export default PaymentCard;