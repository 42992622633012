import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { OrganizationViewAPI, UserMgmtAddUser, UnitInfo, DeviceRegistration, smartTagMangement, subscriptionManagement } from '../../../service/api';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { brandLocations, unitModels } from '../../../utils/imgContants';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import uploadImageToCloud from '../organizationManagement/organizationImageUpload';
import { getOrganizationCategoryDetails } from '../../../actions/navigation/organizationmanagement/organizationCategoryDetailsActions'
import { getOrganizationLocationTypes } from '../../../actions/navigation/organizationmanagement/organizationLocationTypesActions'
import { getOrganizationSubElementCount, resetOrganizationSubElementCount } from '../../../actions/navigation/organizationmanagement/organizationSubElementCountActions'
import { getOrganizationUnitsDetails } from '../../../actions/navigation/organizationmanagement/organizationUnitsDetailsActions'
import { deleteOrganizationElement } from '../../../actions/navigation/organizationmanagement/deleteOrganizationElementAction'
import { UserProfile } from '../../../service/api';
import api from '../../../service/api';
import http from '../../../service/httpService';
import qs from 'query-string';
import authService from '../../../service/authService';
import $ from 'jquery';
import * as _ from 'lodash';
import { BRAND_ID, BASECOUNTRY_ID, setBrandId } from '../../../utils/appConstants';
import appconstants from '../../../utils/appConstants';
import moment from '../../../utils/momentHelper.js';

import { getSelectedFilterList } from '../../../actions/listView/locations/filterListAction';
import Constants from '../../../woopraConfig/Woopraconstant';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import { AutoSizer, List } from 'react-virtualized';
import { searchVisible, searchText, clearSearchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../../globalSearchBar/selectOption';
import { FormattedMessage, injectIntl } from 'react-intl';
import TagOrgModal from '../smartTag/tagOrgModel';
import OrgTagsDetail from '../smartTag/orgTagsDetail';
import { readArchivesFromCompressedResponse } from '../../../utils/helpers'
import { withSnackbar } from 'react-simple-snackbar'
import { subscriptionManagementDateFormatter } from '../../subscriptionManagement/invoice.js';

export const snackbarClosingSecond = 6000;
export const snackBarOptions = {
    position: 'bottom-right',
    style: {
        backgroundColor: '#4CAF50',
        color: '#ffffff',
        fontSize: '18px',
        height: '60px',
        fontWeight: 'bold',
        marginBottom: '5%'
    },
    closeStyle: {
        color: '#ffffff',
        fontSize: '16px',
    },
}

var bg = require('../../../images/recipecreation/photoPlaceholder.png')
const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;
const brandLocationsMap = {};
brandsLocationLogo.forEach(item => (brandLocationsMap[item.name] = item));
const brandsMap = {};
brandsLogo.forEach(item => (brandsMap[item.name] = item));
var pattern = new RegExp(/\\/);
// const AdmZip = require('adm-zip');
// var request = require('request');
class OrganizationManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showIcons: { editIcon: false, sameLevelIcon: false, nextLevelIcon: false, deleteIcon: false },
            level: 1,
            link: { group_name: '' },
            linkList: {},
            newLinkCreated: false,
            customerGroupType: { id: '1', name: 'Hospital' },
            country_name: '',
            selectedAddress: '',
            isAddressAvailable: false,
            isLoactionTypeAvailable: false,
            loaderIconVisible: true,
            isUnitItemClicked: false,
            unit: {},
            countyData: [],
            countryNameListFrmOrg: [],
            countryNameLstFrmAPI: [],
            findCountryName: "",
            unitActiveObject: {
                unitIds: [],
                activationStatus: 'ACTIVE',
                assetNumber: ''
            },
            cgIds: '',
            isImageUpload: false,
            imagelink: '',
            imagePreviewUrl: "",
            file: "",
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            Activatestatus: "",
            userselectedData: "",
            sortlevel: "",
            sorttype: "",
            errorDetailsForName: "",
            errorDetailsForAdd1: "",
            errorDetailsForCity: "",
            errorDetailsForCode: "",
            showUserList: [],
            showSmartGroup: [],
            newNodeName: '',
            renameNodeLevelId: '',
            dragDropId: { ID: '', type: '', parentID: '', checkParent: '' },
            dragDropMessage: '',
            dragDropReqData: {},
            dragDropInfoMessage: '',
            selectedUnitList: [],
            assetNumber: '',
            searchData: null,
            flattenTreeData: [], /* Contains flatten hierarchy data structure in Array<Array> */
            unitSmartTagList: [],
            searchUnitSmartTag: '',
            orgSmartTagList: [],
            searchOrgSmartTag: '',
            isChangeHappened: false,
            selectedSmartOrgTag: [],
            selectedSmartUnitTag: [],
            showpopup: [],
            type: 'orgList',
            showOrgPopup: false,
            showStPopUp: false,
            showAssignedUsers: false,
            showTotalElements: false,
            closedModelRef: '',
            needNavigation: false,
            market_id: "",
            unitToggleChange: false,
            isPaymentDetailAvailable: true
        };
        this.filterstate = {
            sCgids: [],
            sCids: [],
            sBrandids: [],
            sModels: [],
            sMachineTypes: [],
            sSmartTags: [],
        };
        this.treeData = {
            virtualScrollBufferRowCount: 10,
            rowBaseHeight: 80,
            virtualScrollRef: React.createRef(),
            collapsedNodes: {},
            activeNode: null,
            expand: {}
        };
        this.onsubmitClick = this.onsubmitClick.bind(this);
        this.getOrgnanizationView = this.getOrgnanizationView.bind(this);
        this.treeViewRowRenderer = this.treeViewRowRenderer.bind(this);
        this.getTreeViewRowHeight = this.getTreeViewRowHeight.bind(this);

    }

    componentDidMount = async (isFIrstTime = true) => {
        document.body.style.backgroundColor = "#F2F2F2";
        let getCountryNameList = JSON.parse(localStorage.getItem("CountryNameList"));
        let _getBrandsList;
        let _getbrandIds = localStorage.getItem('brandId').replace(/[\[\]']+/g, '');
        _getBrandsList = _.isEqual(BRAND_ID.split(',').sort(), _getbrandIds.split(',').sort())
        if (!_getBrandsList) {
            setBrandId(_getbrandIds);
        }
        window.addEventListener('resize', this.setTreeViewHeight);
        this.props.searchVisible(true);
        this.getPaymentInfo()
        this.getOrgSTagDetails('orgList');
        this.getOrgSTagDetails('unitList');
        if (isFIrstTime) {
            // this.props.getOrganizationCategoryDetails();
            this.props.getOrganizationLocationTypes();
        }
        await this.getFilterDetails();
        this.setState({ countryNameLstFrmAPI: getCountryNameList })
        await this.getCustGroupID();
    }

    componentDidUpdate(prevState) {
        /**
         * Tree View header has a dynamic height based on Org API response
         * So set the body height after ORG API success
         */
        this.setTreeViewHeight();
        let { searchData } = prevState;
        let curSearchData = this.state && this.state.searchData;
        if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
            this.getCustGroupID();
        }
    }

    getPaymentInfo = async () => {
        await http.get(`${subscriptionManagement.paymentApi}?customerid=${localStorage.getItem("customerId")}`, {
            headers: {
                'Content-Type': 'application/json',
            },
            data: {}
        }).then(async response => {
            if (response && response.data) {
                this.setState({ isPaymentDetailAvailable: response?.data?.payment_profile ? true : false })
            }
        }).catch(err => {
        });
    }


    /**
     * To remove the search text when navigating to other screens
     */
    componentWillUnmount() {
        this.props.searchText({});
        this.props.searchVisible(false);
    }

    downloadUnitList = (tagName) => {
        this.setState({ loaderIconVisible: true });
        let url = smartTagMangement.getPresignedUrl;
        let registrationFlag = 'no';
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
        if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
            registrationFlag = 'yes';
        }
        url = url + `?registrationFlag=${registrationFlag}&tagName=${tagName}&isView=yes`;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'customerid': localStorage.getItem("customerId"),
                'cgid': localStorage.getItem("custGroupID"),
                'brandid': BRAND_ID,
            }, data: {}
        }).then(async response => {
            if (response && response.data && response.data.data) {
                this.setState({ loaderIconVisible: false });
                if (response.data.data.preSignedUrl && response.data.data.preSignedUrl !== '#') {
                    window.location = response.data.data.preSignedUrl;
                }
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    getOrgSTagDetails = async (type) => {
        let url = smartTagMangement.getTagList;
        if (type === 'orgList') {
            url = url + "ORG"
        } else {
            url = url + "UNIT"
        }
        let registrationFlag = 'no';
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
        if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
            registrationFlag = 'yes';
        }
        await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'customerid': localStorage.getItem("customerId"),
                'cgid': localStorage.getItem("custGroupID"),
                'brandid': BRAND_ID,
                'registrationflag': registrationFlag,
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                this.setState({ [type === "orgList" ? "orgSmartTagList" : "unitSmartTagList"]: response.data.data.length > 0 ? response.data.data : [] });
            }
        }).catch(err => {

        });
    }

    /**
     * Create filter model
     * Retain filter model on page reload
     */
    getFilterDetails = async () => {
        let filterModel = this.props.filterlistInformtion.filterModel;
        const ffModel = localStorage.getItem(appconstants.orgManagementFilter);
        const filterModel1 = ffModel && JSON.parse(ffModel);
        if (filterModel1) {
            filterModel = filterModel1;
        }

        let selectedCountryList = filterModel.find(el => el.field_name === 'country');
        let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
        let selectedLocationList = filterModel.find(el => el.field_name === 'location');
        let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
        let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
        let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
        let selectedModelList = filterModel.find(el => el.field_name === 'model');
        let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
        let selectedUnitSTList = filterModel.find(el => el.field_name === 'unitSmartTagsFilter');
        let selectedOrgSTList = filterModel.find(el => el.field_name === 'orgSmartTagsFilter');

        let selectedCgids = [];
        let selectedCids = [];
        let selectedBrands = [];
        let selectedModels = [];
        let selectedMachineTypes = [];
        let selectedST = [];

        selectedCountryList.dataSource.map(c => {
            return selectedCgids.push(c.id)
        });

        if (selectedRegionList.dataSource.length > 0) {
            selectedCgids = [];
        }

        selectedRegionList.dataSource.map(c => {
            return selectedCgids.push(c.cust_gp_id)
        });

        if (selectedLocationList.dataSource.length > 0) {
            selectedCgids = [];
        }

        selectedLocationList.dataSource.map(c => {
            return selectedCgids.push(c.cust_gp_id)
        });

        if (selectedWithinLocationList.dataSource.length > 0) {
            selectedCgids = [];
        }

        selectedWithinLocationList.dataSource.map(c => {
            return selectedCgids.push(c.cust_gp_id)
        });

        selectedCustomerList.dataSource.map(c => {
            return selectedCids.push(c.ID)
        });

        selectedBrandList.dataSource.map(c => {
            return selectedBrands.push(c.ID)
        });

        selectedModelList.dataSource.map(c => {
            return selectedModels.push(c.ID)
        });

        selectedMachineTypeList.dataSource.map(c => {
            return selectedMachineTypes.push(c.ID)
        });

        selectedUnitSTList && selectedUnitSTList.dataSource.map(c => {
            return selectedST.push(c.id)
        });

        selectedOrgSTList && selectedOrgSTList.dataSource.map(c => {
            return selectedST.push(c.id)
        });
        let cgids = "";
        if (selectedCgids.length > 0) {
            cgids = selectedCgids.join(',');
        }
        else {
            cgids = localStorage.getItem("custGroupID");
        }

        this.filterstate = ({
            sCgids: cgids,
            sCids: selectedCids,
            sBrandids: selectedBrands,
            sModels: selectedModels,
            sMachineTypes: selectedMachineTypes,
            sSmartTags: selectedST,
        });

        var d = document.getElementById("spannavNotifiStatus");
        if (selectedCgids.length > 0 || selectedST.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0 || selectedST.length > 0) {
            d.className = "navNotifiStatus";
        }
        else {
            d.className = "";
        }

    }

    /**
     * Get country list
     */
    // getCountryName = () => {
    //     http.get(OrganizationViewAPI.getCountryRegion,
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             data: {}
    //         }
    //     ).then(response => {
    //         this.setState({
    //             countryNameLstFrmAPI: response.data.map(item => item.country_name)
    //         })



    //     }).catch(err => {

    //     });
    // }

    onSuccessCloseModal = () => {
        this.setState({
            success_modal: {
                open: false
            }
        });
        // To bring success modal to background, reset z-index back to default value
        $('#orgLoader').css('z-index', '');
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    /**
     * Get Customer Group ID by Customer ID
     */
    getCustGroupID = async () => {
        this.props.searchVisible(true);
        let custGroupIDList = "";
        var setcustGroupIDList = "";
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        this.setState({ loaderIconVisible: true, })

        const values = qs.parse(this.props.location.search);
        if (values.user !== undefined) {
            loggedinUser = values.user;
        }
        this.setState({ queryString: loggedinUser });

        await http({
            method: "GET",
            url: `${UserProfile.get_Cust_GroupID}`,
            headers: {
                'customer_id': localStorage.getItem("customerId"),
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'base_country': localStorage.getItem('basecountry'),
                'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
            }, data: {},
        }).then(response => {
            custGroupIDList = response.data.data;
            custGroupIDList.forEach(p => {
                setcustGroupIDList ? setcustGroupIDList = setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList = p.customer_group_id
            });
            if (this.filterstate.sCgids) {
                if (this.filterstate.sCgids.length === 0) {
                    this.getFilterDetails();
                }
                this.getOrgnanizationView(this.filterstate.sCgids);
            } else {
                if (setcustGroupIDList !== "") {
                    this.getOrgnanizationView(setcustGroupIDList);
                }
            }
        }).catch(err => {
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
    }

    /**
     * Process Org hierarchy data
     * @param {*} orgJSON Hierarchy Org data
     */
    processOrgnanizationJSON(orgJSON) {
        // New Customer node display in organization managment
        const linkListFirstLevel = orgJSON.level1[0];
        let { countryNameListFrmOrg, countyData, countryNameLstFrmAPI } = this.state;
        try {
            if (_.isObject(linkListFirstLevel)) {
                if (_.isArray(linkListFirstLevel.level2)) {
                    countryNameListFrmOrg = linkListFirstLevel.level2.map((item) => item.group_name);
                }
                countyData = [...new Set([...countryNameLstFrmAPI])];
            }
        } catch (error) {
            console.log("error in org loading",error)
        }

        const { flattenTreeData } = this.convertToFlattenTreeData(orgJSON.level1);
        this.setState({
            linkList: orgJSON,
            loaderIconVisible: false,
            countryNameListFrmOrg,
            countyData,
            flattenTreeData
        }, () => {
            this.treeData.virtualScrollRef.current.recomputeRowHeights();
        });
    }
    /**
     * Convert Encoding UTF8 to binary
     */
    convertToBinary = (string) => {
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
            codeUnits[i] = string.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
    }


    /**
     * Get Org hierarchy data
     * @param {*} values List of CGIDs
     */
    getOrgnanizationView = async (values) => {
        this.setState({ loaderIconVisible: true, })
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        let { searchData } = this.state;
        let cgidValue = values;
        if (this.filterstate.sSmartTags && this.filterstate.sSmartTags.length) {
            const tags = this.filterstate.sSmartTags;
            const smartOrgTag = [...this.state.orgSmartTagList, ...this.state.unitSmartTagList];
            let filteredTag = smartOrgTag && smartOrgTag.length ? smartOrgTag.filter((i) => tags.includes(i.id)) : [];
            filteredTag = filteredTag && filteredTag.length ? filteredTag.map(i => i.userCGIDs).flat() : [];
            filteredTag = filteredTag && filteredTag.length ? (filteredTag.filter((value, index, array) => array.indexOf(value) === index)).join(',') : '';
            cgidValue = filteredTag && filteredTag.length ? filteredTag : values;
            if (this.filterstate.sCgids && localStorage.getItem("custGroupID") !== this.filterstate.sCgids) {
                cgidValue = cgidValue.concat(`,${this.filterstate.sCgids}`)
            }
        }
        let headerVal = {
            "Cgids": cgidValue,
            'Content-Type': 'application/json',
            'brandids': this.filterstate.sBrandids.length > 0 ? this.filterstate.sBrandids.join(',') : BRAND_ID,
            'useremail': loggedinUser,
            'model': this.filterstate.sModels ? this.filterstate.sModels.join(',') : '',
            'machinetype': this.filterstate.sMachineTypes ? this.filterstate.sMachineTypes.join(',') : '',
        }
        if (this.filterstate.sSmartTags) {
            headerVal['tags'] = this.filterstate.sSmartTags;
        }

        if (this.state.sortlevel !== "") {
            headerVal['sortlevel'] = this.state.sortlevel;
        }

        if (this.state.sorttype !== "") {
            headerVal['sorttype'] = this.state.sorttype;
        }
        let apiUrl = `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`;
        if (searchData && searchData.searchtext) {
            apiUrl = `${apiUrl}?searchText=${searchData.searchtext.toString()}`;
        }
        if (searchData) {
            let searchParam = {};

            Object.keys(searchData).map((item) => {
                if (item !== "searchtext") {
                    searchParam[item] = searchData[item];
                }
            })

            if (searchParam && Object.keys(searchParam).length) {
                apiUrl = `${apiUrl}?searchParams=${btoa(this.convertToBinary(JSON.stringify(searchParam)))}`;
            }
        }
        await http({
            method: "GET",
            url: apiUrl,
            headers: headerVal, data: {},
        }).then(async response => {
            // if (response.data.fetchFromS3) {
            // 	await $.getJSON(response.data.presignedS3URL, (data) => {
            // 		 this.processOrgnanizationJSON(data);
            // 	});
            // }
            // else {
            // 	this.processOrgnanizationJSON(response.data);
            // }         
            let orgJSON = await readArchivesFromCompressedResponse(response.data);
            this.processOrgnanizationJSON(orgJSON);
            this.setState({
                cgIds: values
            })
        }).catch(err => {
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
        this.props.searchVisible(true);
    }

    static getDerivedStateFromProps = (props) => ({ ...props });

    /**
     * Set Unit Activation Status
     * @param {*} unitActiveObject 
     */
    onActiveChange(unitActiveObject) {
        const { unit } = this.state;
        const req_active = unitActiveObject;
        const paymentStatus = localStorage.getItem('subscriptionDetails') ? JSON.parse(localStorage.getItem('subscriptionDetails')).payment_status_id : 0;
        const subscriptionDetails = localStorage.getItem('subscriptionDetails') ? JSON.parse(localStorage.getItem('subscriptionDetails')) : {};
        const isUnitPrepaidDateEnded = unit?.unit_prepaid_end_date ? moment.GetDateTimeDifferenceWithFormat(unit?.unit_prepaid_end_date,'YYYY-MM-DD') > 0 ? false : true : false
        const url = paymentStatus === 1 ? OrganizationViewAPI.active_Deactive_Unit :  subscriptionDetails?.customerPaymentStatus === "NOT REQUIRED" ? OrganizationViewAPI.active_Deactive_Unit : subscriptionDetails.is_new_customer === 0 && isUnitPrepaidDateEnded ? OrganizationViewAPI.active_Deactive_Unit : subscriptionDetails.is_new_customer === 1 && subscriptionDetails.subscription_status === 0 && isUnitPrepaidDateEnded ? OrganizationViewAPI.active_Deactive_Unit : subscriptionManagement.updateSubscription;

        this.putActiveDeactive(url, req_active).then(async response => {
            this.setState({ level: 1, link: { group_name: '' }, linkList: {}, newLinkCreated: false, selectedUnitList: [], unit: {} });
            this.treeData.activeNode = null;
            await this.componentDidMount(false);
            // $('#changesConfirmationModal').modal('show');
            this.loadSuccessChanges();
            this.resetState()
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
            //Error Goes Here
        });
    }

    /**
     * Delete Unit
     * @param {*} unitId Unit ID
     */
    async onUnitDelete(unitId, market_id) {
        const req = {
            "unitIds": [unitId],
        }
        const enableBakingPlan = localStorage.getItem("enableBakingPlan")
        if (enableBakingPlan == 1) req.marketId = market_id
        await this.deleteUnit(OrganizationViewAPI.unassign_Unit, req);
    }

    /**
     * Info popup cancel handler
     * @param {*} 
     * @returns 
     */
    onDeleteClickHandler() {
        WoopraEvents(`${Constants.ORG_MANAGEMENT}-Cancel Action`)
        $('body').css('overflow-y', 'auto');
        const { link } = this.state
        const showIcons = { editIcon: link.sameLevelAccess, sameLevelIcon: link.sameLevelAccess, nextLevelIcon: link.nextLevelAccess, deleteIcon: link.sameLevelAccess }
        const { units } = this.props.OrganizationUnitsDetailsInfo;
        this.setState({ showIcons: showIcons, newLinkCreated: false, isChangeHappened: false, showAssignedUsers: false, showTotalElements: false, selectedSmartUnitTag: [], selectedSmartOrgTag: [] })
        if (units !== undefined) {
            const activeValueKey  = units?.SubscriptionStatus !== null && units?.SubscriptionStatus !== 'null' ? units.SubscriptionStatus === 0 ? 'INACTIVE' : units.SubscriptionStatus === 1 ? 'ACTIVE' : units.ActivationStatus : units.ActivationStatus;

            const copyUnitActiveObject = { unitIds: [], activationStatus: activeValueKey };
            this.treeData.activeNode = null;
            this.setState({
                imagePreviewUrl: "",
                unitActiveObject: copyUnitActiveObject,
                showIcons: { editIcon: false, sameLevelIcon: false, nextLevelIcon: false, deleteIcon: false },
            });
        } else {
            this.treeData.activeNode = null;
            this.setState({
                imagePreviewUrl: "",
                showIcons: { editIcon: false, sameLevelIcon: false, nextLevelIcon: false, deleteIcon: false },
            });
            return;
        }
    }

    /**
     * Info popup submit handler 
     * @returns 
     */
    async onsubmitClick() {
        let { assetNumber, unitToggleChange } = this.state;
        const paymentStatus = localStorage.getItem('subscriptionDetails') ? JSON.parse(localStorage.getItem('subscriptionDetails')).payment_status_id : 0;
        const { link, linkList, isAddressAvailable, selectedAddress, customerGroupType, isUnitItemClicked, unitActiveObject, country_name, findCountryName, selectedSmartOrgTag, selectedSmartUnitTag } = this.state;
        const { units } = this.props.OrganizationUnitsDetailsInfo;
        if (unitToggleChange) {
            if(unitActiveObject.activationStatus === "ACTIVE"){
                if(paymentStatus === 1){
                    $('#unitActivateModalWithoutPayment').modal('show');
                }else{
                    $('#unitActivateModal').modal('show')
                }
            }else{
                $('#deActivateModal').modal('show')
            }
            return;
        }
        $('body').css('overflow-y', 'auto');
        if (isUnitItemClicked) {
            const copyUnitActiveObject = {
                unitIds: [units.ID],
                activationStatus: unitActiveObject.activationStatus,
                assetNumber: assetNumber,
                tags: selectedSmartUnitTag && selectedSmartUnitTag.length ? selectedSmartUnitTag : []
            };
            this.onActiveChange(copyUnitActiveObject);
            this.setState({
                showIcons: { editIcon: false, sameLevelIcon: false, nextLevelIcon: false, deleteIcon: false },
                loaderIconVisible: true, unitActiveObject: copyUnitActiveObject, assetNumber: assetNumber
            });
            return;
        }

        if (!link || !link.group_name || link.group_name.trim() === "") {
            this.setState({
                loaderIconVisible: false, error_modal: { open: true, message: 'KC0944' }, imagePreviewUrl: "",
                showIcons: { editIcon: false, sameLevelIcon: false, nextLevelIcon: false, deleteIcon: false },
            })
            this.setState({
                error_modal: {
                    open: true,
                    message: 'Orgchart node cannot be empty.'
                }
            })
            return;
        }
        const req = {
            "customer_id": localStorage.getItem("customerId"),
            "parent_group_id": link.parent_group_id,
            "group_name": link.group_name,
            "category_id": link.category_id,
            "dynasty": link.dynasty,
            'tags': selectedSmartOrgTag && selectedSmartOrgTag.length ? selectedSmartOrgTag : []
        }

        if (link.level_id === 2) {
            req["country"] = country_name
        }
        if (isAddressAvailable) {
            req['street'] = selectedAddress && selectedAddress.street ? selectedAddress.street.trim() : '';
            req['country'] = (linkList.categoryList.length === 1 && linkList.categoryList[0].level_id === 1) ? selectedAddress.country : (country_name ? country_name : findCountryName);
            req['city'] = selectedAddress && selectedAddress.city ? selectedAddress.city.trim() : '';
            req['zipcode'] = selectedAddress.zipcode;

        }
        if (link.level_id === 4) {
            if (!customerGroupType.id) {
                const groupType = this.props.OrganizationLocationTypesInfo['customer_group_type'][0];
                req['cust_gp_type_name'] = groupType.cust_type_name;
                req['cust_gp_type_id'] = groupType.cust_gp_type_id;
            } else {
                req['cust_gp_type_name'] = customerGroupType.name;
                req['cust_gp_type_id'] = customerGroupType.id;
            }
        }

        if (link.dynasty === 0) {
            this.setState({ loaderIconVisible: true })
            let isImageUpload = false;
            this.postData(OrganizationViewAPI.create_Organization, req, isImageUpload).then(async response => {
                await this.componentDidMount(false);
                let newObj = { nodeName: link.group_name };
                WoopraEvents(`${Constants.ORG_MANAGEMENT}-${Constants.NODE_ADDED}`, newObj);
                this.setState({ newLinkCreated: false, showIcons: { editIcon: false, sameLevelIcon: false, nextLevelIcon: false, deleteIcon: false, loaderIconVisible: false } }, () => {
                    this.resetState();
                    // $('#changesConfirmationModal').modal('show');
                    this.loadSuccessChanges();

                })
            }).catch(err => {
                this.setState({
                    loaderIconVisible: false, error_modal: { open: true, message: 'KC0944' }, imagePreviewUrl: "",
                    showIcons: { editIcon: false, sameLevelIcon: false, nextLevelIcon: false, deleteIcon: false },
                })
                //Error Goes Here
            });
        } else {
            let isImageUpload = false;
            req["cust_gp_id"] = link.cust_gp_id;
            this.editData(OrganizationViewAPI.edit_Organization, req, isImageUpload).then(async response => {
                this.resetState();
                this.setState({ level: 1, link: { group_name: '' }, linkList: {}, newLinkCreated: false, selectedUnitList: [], unit: {} });
                this.treeData.activeNode = null;
                let newObj = { nodeName: link.category_name };
                WoopraEvents(`${Constants.ORG_MANAGEMENT}-${Constants.NODE_UPDATED}`, newObj);
                await this.componentDidMount(false);
                this.loadSuccessChanges();
                // $('#changesConfirmationModal').modal('show');
            }).catch(err => {
                this.setState({
                    loaderIconVisible: false, error_modal: { open: true, message: 'KC0944' }, imagePreviewUrl: "",
                    showIcons: { editIcon: false, sameLevelIcon: false, nextLevelIcon: false, deleteIcon: false },
                })
                //Error Goes Here
            });
        }

        $('#imgBrowseFile').val('');
        this.setState({
            showIcons: { editIcon: true, sameLevelIcon: true, nextLevelIcon: true, deleteIcon: true },
            loaderIconVisible: true, isChangeHappened: false
        });
    }

    /**
     * Request to create new node
     * Upload image to cloud
     * @param {*} url API URL
     * @param {*} reqData Request body
     * @param {*} isImageUpload Whether to upload an image
     * @returns Promise<Response>
     */
    postData = (url, reqData, isImageUpload) => {

        let imageKey, imageUrl, fileUploadprops = "";
        return new Promise((resolve, reject) => {
            let inputFiles = $('#imgBrowseFile')[0];

            if (inputFiles.files[0]) {
                isImageUpload = true;
                fileUploadprops = inputFiles.files[0];
                let fileName = inputFiles.files[0].name;
                let fileextSplit = fileName.split('.');
                let fileext = fileextSplit[1];
                imageKey = Date.now() + '.' + fileext;
                imageUrl = `${OrganizationViewAPI.get_ImageUpload_Presigned}` + '?imageKey=' + imageKey + '&customerId=' + localStorage.getItem("customerId");
                reqData.image_name = localStorage.getItem("customerId") + "/" + imageKey;
            }

            uploadImageToCloud(imageUrl, reqData, fileUploadprops, 'POST', url, isImageUpload).then(function (response) {
                resolve(response);
            }).catch(function (err) {
                reject(err);
            });
        }
        );
    }

    /**
     * Request to update node information
     * Upload image to cloud
     * @param {*} url API URL
     * @param {*} reqData Request body
     * @param {*} isImageUpload Whether to upload an image
     * @returns Promise<Response>
     */
    editData = (url, reqData, isImageUpload) => {
        let imageKey, imageUrl, fileUploadprops = "";
        return new Promise((resolve, reject) => {
            let inputFiles = $('#imgBrowseFile')[0];
            if (inputFiles && inputFiles.files[0]) {
                isImageUpload = true;
                fileUploadprops = inputFiles.files[0];
                let fileName = inputFiles.files[0].name;
                let fileextSplit = fileName.split('.');
                let fileext = fileextSplit[1];
                imageKey = Date.now() + '.' + fileext;
                imageUrl = `${OrganizationViewAPI.get_ImageUpload_Presigned}` + '?imageKey=' + imageKey + '&customerId=' + localStorage.getItem("customerId");
                reqData.image_name = localStorage.getItem("customerId") + "/" + imageKey;
            }
            uploadImageToCloud(imageUrl, reqData, fileUploadprops, 'PUT', url, isImageUpload).then(function (response) {
                resolve(response);
            }).catch(function (err) {
                reject(err);
            });
        }
        );
    }

    /**
     * Request to update node information
     * @param {*} url API URL
     * @param {*} reqData Request body
     * @returns Promise<Response>
     */
    deleteUnit = (url, reqData) => {

        let token = localStorage.getItem("Access-Token");
        let loggedinUser;
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        loggedinUser = loginUser.username;

        return new Promise((resolve, reject) => {

            http.put(url
                , reqData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                        'customerid': localStorage.getItem("customerId"),
                        'useremail': loggedinUser,
                    }
                }
            )
                .then(function (response) {
                    resolve(response);
                }).catch(function (err) {
                    reject(err);
                });
        });
    }

    /**
     * Request to set Unit Activation status
     * @param {*} url API URL
     * @param {*} reqData Request body
     * @returns Promise<Response>
     */
    putActiveDeactive = (url, reqData) => {

        let token = localStorage.getItem("Access-Token");
        let loggedinUser;
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        loggedinUser = loginUser.username;
        return new Promise((resolve, reject) => {

            http.put(url
                , reqData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                        'customerid': localStorage.getItem("customerId"),
                        'useremail': loggedinUser,
                    }
                }
            )
                .then(function (response) {
                    resolve(response);
                }).catch(function (err) {
                    reject(err);
                    //  this.setState({loaderIconVisible:false});
                });
        });
    }

    /**
     * Info popup image file upload handler
     * Preview uploaded image
     * @param {*} e Event object
     */
    handleImageChange = (e) => {
        const { formatMessage } = this.props.intl;

        let file = e.target.files[0];
        if (window.FileReader && file) {
            let reader = new FileReader();
            reader.readAsDataURL(file)
            let fileName = e.target.files[0].name;
            let fileextSplit = fileName.split('.');
            let fileext = fileextSplit[1];
            if ((fileext === "png") || (fileext === "jpeg") || (fileext === "jpg") || (fileext === "bmp")) {
                reader.onloadend = () => {
                    this.setState({
                        loaderIconVisible: false,
                        file: file,
                        imagePreviewUrl: reader.result,
                        isChangeHappened: true
                    });
                    // document.getElementById('imgBrowseFile').value = null;
                    WoopraEvents(`${Constants.IMAGE_UPLOAD}`)
                }
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: formatMessage({ id: "KC1112" })
                    },
                    isChangeHappened: false
                });
            }
        }
        // $("#imgBrowseFile").val('');
    }

    /**
     * Info popup Customer Group dropdown change handler
     * @param {*} event Event object
     */
    onCustomerGroupChange(event) {
        const { OrganizationLocationTypesInfo } = this.props;
        const locationItem = OrganizationLocationTypesInfo['customer_group_type'].find((item) => {
            if (item.cust_gp_type_id === parseInt(event.target.value)) {
                return true;
            }
        })
        const customerGroupType = { id: event.target.value, name: locationItem.cust_type_name }
        this.setState({ customerGroupType: customerGroupType, isChangeHappened: true });
    }

    /**
     * Info popup Country dropdown change handler
     * @param {*} e Event object
     */
    onCountryChange = (e) => {
        this.setState({ country_name: e.target.value, isChangeHappened: true });
        this.onAddressChange(e, 'ADD4')
    }

    updateLevel(levelList, link, levelItems = [], onsameLevel) {
        let nextLevel = 1;
        if (!levelList) return;
        if (levelList && levelList.length > 0) nextLevel = levelList[0].level_id + 1;
        else {
            nextLevel = levelList.level_id + 1;
        }
        const index = levelList.findIndex((item, index) => {
            if (item.cust_gp_id === link.cust_gp_id) {
                let newLink = {};
                if (onsameLevel) {
                    newLink = {
                        category_id: item.category_id,
                        category_name: item.category_name,
                        cust_gp_id: 0,
                        dynasty: 0,
                        dynastyAttributes: null,
                        group_name: '',
                        fixed_group_name: `New ${item && item.category_name} Creation`,
                        level_id: item.level_id,
                        parent_group_id: item.parent_group_id
                    }
                    levelList.push(newLink);
                    this.setState({ newLinkCreated: true, link: newLink }, () => {
                        this.onDoubleClickItem(newLink);
                        return;
                    })
                } else {
                    const lel = item.level_id + 1;
                    let newItem;
                    if (item['level' + lel] && item['level' + lel].length > 0) newItem = item['level' + lel][0];
                    else {
                        newItem = item['level' + lel];
                    }
                    const catId = item.category_id + 1;
                    newLink = {
                        category_id: catId,
                        category_name: item.category_name,
                        cust_gp_id: 0,
                        dynasty: 0,
                        dynastyAttributes: null,
                        group_name: '',
                        fixed_group_name: newItem && newItem.category_name ? `New ${newItem && newItem.category_name} Creation` : "New Node Creation",
                        level_id: lel,
                        parent_group_id: item.cust_gp_id
                    }
                    if (!levelList[index]['level' + lel]) {
                        levelList[index]['level' + lel] = [];
                    }
                    levelList[index]['level' + lel].push(newLink);
                    this.setState({ newLinkCreated: true, link: newLink }, () => {
                        this.onDoubleClickItem(newLink);
                        return;
                    })
                }
            }
        })
        if (index < 0) {
            levelList.map((l1, index) => { this.updateLevel(l1['level' + nextLevel], link, levelItems, onsameLevel) })
        }

        if (levelList && levelList.length > 0 && levelList[0].level_id === 1) {
            return levelList;
        }
    }

    /**
     * Add Same Level, Next Level button click handler
     * Add new node
     * @param {*} onsameLevel If True add sibling node, else add child
     */
    onSamelevelPlusButtonClick(onsameLevel = true) {
        let upDateList = this.state.linkList;
        const { link } = this.state;
        link['fixed_group_name'] = `New ${link && link.category_name} Creation`
        upDateList.level1 = this.updateLevel(upDateList.level1, link, [], onsameLevel);
        this.setState({ linkList: upDateList, newLinkCreated: true, selectedSmartOrgTag: [], selectedSmartUnitTag: [] });
        this.treeData.activeNode = null;
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
        $('#imgBrowseFile').val('');

        let { customerGroupType, } = this.state;
        customerGroupType.id = 4;
        customerGroupType.name = "Office";
        this.setState({ customerGroupType: customerGroupType, imagePreviewUrl: '', isChangeHappened: true, selectedSmartOrgTag: [], selectedSmartUnitTag: [] }, () => {
            if (onsameLevel) {
                if (link.level_id === 1) {
                    $('#customerNodeModal').modal('show');
                } else if (link.level_id === 2) {
                    $('#countryNodeModal').modal('show');
                } else if (link.level_id === 3) {
                    $('#chainNodeModal').modal('show');
                } else if (link.level_id === 4) {
                    $('#locationNodeModal').modal('show');
                } else if (link.level_id === 5) {
                    $('#withinLocationNodeModal').modal('show');
                } else {
                    $('#unitNodeModal').modal('show');
                }
            } else {
                if (link.level_id === 1) {
                    $('#countryNodeModal').modal('show');
                } else if (link.level_id === 2) {
                    $('#chainNodeModal').modal('show');
                } else if (link.level_id === 3) {
                    $('#locationNodeModal').modal('show');
                } else if (link.level_id === 4) {
                    $('#withinLocationNodeModal').modal('show');
                } else {
                    $('#unitNodeModal').modal('show');
                }
            }
        });
    }

    /**
     * Check if addess field present in category
     * @param {*} category_id Category ID
     * @returns Object
     */
    checkIsAddressAvailable(category_id) {
        const { OrganizationCategoryDetailsInfo } = this.props;
        let isLoactionTypeAvailable = false;
        let findIndex = OrganizationCategoryDetailsInfo['customer_group_category'].findIndex(
            (item) => {
                if (item.category_id === category_id && item.category_attributes) {
                    if (item.category_attributes.includes('LocationType')) {
                        isLoactionTypeAvailable = true;
                    }
                    if (item.category_attributes.includes('Address')) {
                        return true;
                    }
                }
            })
        if (findIndex > -1) return { addressAvailable: true, locationAvailable: isLoactionTypeAvailable };
        return { addressAvailable: false, locationAvailable: isLoactionTypeAvailable };
    }

    /**
     * Node double click handler
     * @param {*} link Node object
     */
    async onDoubleClickItem(link, imagePreviewUrl) {
        const { newLinkCreated, countyData, linkList } = this.state;
        const { sameLevelAccess, level_id } = link;
        this.setState({ loaderIconVisible: true })
        const updatedCountryList = [...countyData];
        let getCountryValue, selectedCountry
        if (link && link.level_id) {
            getCountryValue = _.find(linkList.level1[0].level2, { cust_gp_id: link.parent_group_id });
        }
        if (getCountryValue && getCountryValue.dynastyAttributes) {
            selectedCountry = JSON.parse(getCountryValue.dynastyAttributes)
        }
        if (link.level_id === 4) {
            linkList.level1[0].level2.map((val, index) => {
                if (_.find(linkList.level1[0].level2[index].level3, { cust_gp_id: link.parent_group_id }) !== undefined)
                    return selectedCountry = linkList.level1[0].level2[index].dynastyAttributes ? JSON.parse(linkList.level1[0].level2[index].dynastyAttributes) : "";
            })
        }
        selectedCountry && selectedCountry.country ? selectedCountry = selectedCountry.country : selectedCountry = ""
        if (newLinkCreated || sameLevelAccess) {
            this.setState({ countyData: updatedCountryList, findCountryName: selectedCountry })
        }
        if (imagePreviewUrl) {
            this.setState({ imagePreviewUrl })
        }
        await this.getUserList(link.parent_group_id);
        this.toggleElementInfoHandler();
        this.setState({ isChangeHappened: false, loaderIconVisible: false })
    }

    /**
     * Get assigned users for a node
     * @param {*} currCGID CGID
     */
    getUserList = async (currCGID) => {
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        const urlVal = UserMgmtAddUser.getAssignedUsers + "?customerId=" + localStorage.getItem("customerId") + "&email=" + loggedinUser;
        /* User Story 56777: KC Portal - OrgManagement Users assigned to node */
        let storageEmulation = localStorage.getItem('emulation');
        let storageBaseCountry = localStorage.getItem('basecountry');
        let headerParams = {
            'Content-Type': 'application/json',
            'cgid': currCGID
        }
        if (storageEmulation) {
            headerParams.emulation = storageEmulation;
            headerParams.base_country = storageBaseCountry;
        }
        await http({
            method: "GET",
            url: urlVal,
            headers: headerParams,
            data: {},
        }).then(response => {
            this.setState({
                showUserList: response.data && response.data.data && Array.isArray(response.data.data) ? response.data.data : []
            });
        }).catch(err => {
            this.setState({
                showUserList: []
            });
        });
    }

    /**
     * Node click handler
     * @param {*} e Event object
     * @param {*} link Node object
     */
    async onItemClick(e, link, imagePreviewUrl) {
        e.preventDefault();
        if (e.detail > 1) {
            if (link.sameLevelAccess) {
                this.onDoubleClickItem(link, imagePreviewUrl);
            }
            return;
        }
        await this.onItemNodeClick(link, imagePreviewUrl);

    }

    onUnitNodeDoubleClick = (e, unit) => {
        const { UNITID, BRAND_NAME } = unit;
        this.setState({ loaderIconVisible: true, unitActiveObject: {} })
        this.props.getOrganizationUnitsDetails(UNITID, BRAND_NAME).then((res) => {
            if (res && res.smartTagIds) {
                this.setState({ selectedSmartUnitTag: res.smartTagIds })
            }
            $('body').css('overflow-y', 'hidden');

            const units = res && res.units ? res.units : {};
            const activeValueKey  = units?.SubscriptionStatus !== null && units?.SubscriptionStatus !== 'null' ? units.SubscriptionStatus === 0 ? 'INACTIVE' : units.SubscriptionStatus === 1 ? 'ACTIVE' : units.ActivationStatus : units.ActivationStatus;
            let unitActiveObject = {
                unitIds: units.ID,
                activationStatus:activeValueKey,
                assetNumber: units.AssetNumber
            };

            this.setState({
                loaderIconVisible: false,
                isChangeHappened: false,
                unitActiveObject
            }, () => {
                $('#unitNodeModal').modal('show');
            });
        });
    }

    onUnitNodeClick = (e, unit) => {
        const { UNITID, BRAND_NAME, smartGroup } = unit;
        this.setState({
            isUnitItemClicked: true,
            link: {},
            isAddressAvailable: false,
            unit: unit,
            isLoactionTypeAvailable: false,
            level: 6,
            showSmartGroup: smartGroup,
            isChangeHappened: false
        });
        this.selectedunit(UNITID, BRAND_NAME);
        this.onOrgCheckBoxChange(unit);
        let smartTagObj = {
            mode: 'isView',
            type: 'unitList'
        }
        localStorage.setItem('smartTagInfo', JSON.stringify(smartTagObj));
        this.treeData.activeNode = unit;
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }

    async onItemNodeClick(link, imagePreviewUrl) {
        let isAddressAvailable = false;
        let isLoactionTypeAvailable = false;
        const availableObj = this.checkIsAddressAvailable(link.category_id);

        if (availableObj.addressAvailable) {
            isAddressAvailable = true;
        }
        if (availableObj.locationAvailable) {
            isLoactionTypeAvailable = true;
        }
        const { level_id } = link;
        let { customerGroupType, country_name, newLinkCreated, cgIds } = this.state;
        let selectedAddress = null;
        if (link.dynastyAttributes) {
            selectedAddress = JSON.parse(link.dynastyAttributes);
            if (selectedAddress.location_type_id !== 'undefined') {
                customerGroupType.id = selectedAddress.location_type_id;
                customerGroupType.name = selectedAddress.location_type;
            }
            country_name = selectedAddress.country;
        } else {
            selectedAddress = {
                street: '',
                country: '',
                city: '',
                zipcode: '',
                country_name: '',
            };
            link['selectedAddress'] = selectedAddress;
        }


        link['fixed_group_name'] = link.group_name;
        let isAddressAvail = false;
        if (level_id !== 2 && level_id !== 5) {
            isAddressAvail = true;
        }
        const showIcons = { editIcon: link.sameLevelAccess, sameLevelIcon: link.sameLevelAccess, nextLevelIcon: link.nextLevelAccess, deleteIcon: link.sameLevelAccess };
        this.getUserList(link.parent_group_id);
        if (!newLinkCreated) {
            this.props.getOrganizationSubElementCount(link.cust_gp_id, cgIds).then((res) => {
                if (res && res.smartTagIds) {
                    this.setState({ selectedSmartOrgTag: this.state.newLinkCreated ? [] : res.smartTagIds, showIcons });
                }
            });
        } else {
            this.props.resetOrganizationSubElementCount();
        }
        this.setState({
            link: link, level: level_id, showIcons: showIcons, customerGroupType: customerGroupType,
            selectedAddress: selectedAddress, isAddressAvailable: isAddressAvail,
            loaderIconVisible: false,
            isLoactionTypeAvailable: isLoactionTypeAvailable, isUnitItemClicked: false, imagelink: link.imagePath, unit: {}, country_name: country_name, imagePreviewUrl: ""
        }, () => {
        });
        if (imagePreviewUrl) {
            this.setState({ imagePreviewUrl, showIcons });
        }
        let smartTagObj = {
            'mode': 'isView',
            'type': 'orgList'
        };
        localStorage.setItem('smartTagInfo', JSON.stringify(smartTagObj));
        this.treeData.activeNode = link;
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }

    /**
     * Unit node click handler
     * @param {*} e Event
     * @param {*} unit Unit node object
     */
    onUnitItemClick(e, unit) {
        ;
        e.preventDefault();
        if (e.detail > 1) {
            this.onUnitNodeClick(e, unit);
            this.onUnitNodeDoubleClick(e, unit);
            return;
        }
        this.onUnitNodeClick(e, unit);
    }

    /**
     * Request to get unit details
     * @param {*} UNITID Unit Serial No
     * @param {*} BRAND_NAME Brand Name
     */
    selectedunit(UNITID, BRAND_NAME) {
        let url = api.organizaionUnitsDetailsUrl + UNITID + '/' + BRAND_NAME;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }, data: {}
        }).then(response => {
            if (response && response.data) {
                let restval = response.data;
                let assetFieldValue = restval.units.AssetNumber;
                const activeValueKey  = restval.units?.SubscriptionStatus !== null && restval.units?.SubscriptionStatus !== 'null' ? restval.units?.SubscriptionStatus === 0 ? 'INACTIVE' : restval.units?.SubscriptionStatus === 1 ? 'ACTIVE' : restval.units.ActivationStatus : restval.units.ActivationStatus;
                const UnitActiveObject = { unitIds: restval.units.id, activationStatus:activeValueKey, assetNumber: assetFieldValue };
                this.setState({ unitActiveObject: UnitActiveObject, assetNumber: assetFieldValue, market_id: restval.units.market_id });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    showSTagInfo = (node, type = "org") => {
        this.setState({
            showStPopUp: true,
            isUnitItemClicked: type === "unit" ? true : false,
            unit: type === "unit" ? node : {},
            link: type === "org" ? node : {},
        }, () => {
            $('#unitSmartTagsDetails').modal('show');
        })

    }

    /**
     * Drag Drop confirmation model handler
     * Request to change the customer group
     */
    postDragDrop = () => {
        const reqData = this.state.dragDropReqData;
        this.setState({ loaderIconVisible: true })
        const url = OrganizationViewAPI.dragDrop;

        http.post(url
            , reqData,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(async response => {
                let { data } = response;
                if (data.httpCode === 200) {
                    this.getCustGroupID();
                    this.setState({
                        // loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: data.body ? (data.body.stringID || data.body.message || 'KC1111') : 'KC1111',
                        },
                        selectedUnitList: []
                    });
                    $('#orgLoader').css('z-index', 1000); // To bring modal to foreground
                    WoopraEvents(`${Constants.DRAG_AND_CONFIRM}`);
                }
            })
            .catch(err => {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: err.response && err.response.data ? err.response.data.message : "KC1110",
                    },
                });
            });
    }

    /**
     * Drag Start event handler for unit nodes
     */
    onDragStart = () => {
        const { formatMessage } = this.props.intl;

        let unit = this.state.selectedUnitList;
        if (unit.length > 20) {
            this.setState({
                dragDropInfoMessage: formatMessage({ id: 'KC1102' }),
                dragDropId: []
            }, () => {
                $('#detailsInfoModal').modal('show')
            })
            return;
        }
        if (unit && unit.length) {
            let dragDropId = [];
            for (let i = 0; i < unit.length; i++) {
                dragDropId.push({ ID: unit[i].id, type: 'unit', parentID: unit[i].CUSTOMER_GROUP_ID, checkParent: unit[i].CUSTOMER_GROUP_ID, levelId: 6 });
            }
            this.setState({
                dragDropId
            })
        }
    }

    /**
     * Request to get list of units by CGID
     * @param {*} cgid CGID
     * @returns Promise<Response>
     */
    getUnitsViaCGID = (cgid) => {
        return new Promise((resolve, _) => {
            this.setState({ loaderIconVisible: true })
            http({
                method: "GET",
                url: UnitInfo.unitList,
                headers: {
                    'Content-Type': 'application/json',
                    'cgid': cgid,
                    'cid': localStorage.getItem("customerId"),
                    'brandid': BRAND_ID,
                    "basecountry": BASECOUNTRY_ID,
                    "userid": localStorage.getItem("user"),
                }, data: {},
            }).then(response => {
                if (response && response.data) {
                    this.setState({ loaderIconVisible: false })
                    resolve(response.data.units);
                }

            }).catch(err => {
                resolve([]);
            });
        })
    }

    /**
     * Drag Start event handler for non-unit nodes
     * @param {*} node Drag Node object
     */
    onDragLevelStart(node) {
        const droppedElement = node;

        if (droppedElement && droppedElement.length) {
            return;
        } else if (droppedElement && droppedElement.cust_gp_id) {
            let dragDropId = [];
            dragDropId.push({ ID: droppedElement.cust_gp_id, type: 'level', parentID: droppedElement.cust_gp_id, checkParent: droppedElement.parent_group_id, levelId: droppedElement.level_id, dynastyAttributes: droppedElement.dynastyAttributes })
            this.setState({
                dragDropId
            })
        }
    }

    onDragOver = (ev) => {
        ev.preventDefault();
    }

    /**
     * Drop event handler for non-unit nodes
     * @param {*} node Drop Node object
     * @param {*} linkList Drop Node and its siblings
     */
    onDrop(node, linkList) {
        const { formatMessage } = this.props.intl;

        let organizationAuthirizedFeature = authService.getFeature("/organizationManagement");
        if (organizationAuthirizedFeature.is_editable === 0) {
            this.setState({
                dragDropInfoMessage: formatMessage({ id: 'KC1109' }),
                selectedUnitList: [],
                dragDropId: []
            }, () => {
                $('#detailsInfoModal').modal('show')
            })
            return;
        }
        let dragDropId = this.state.dragDropId;
        if (!dragDropId || dragDropId.length <= 0) {
            return;
        }
        let ID = dragDropId.length > 0 ? dragDropId.map((i) => i.ID).join(',') : '';
        let type = dragDropId.length > 0 && dragDropId[0].type;
        let parentID = dragDropId.length > 0 ? dragDropId.map((i) => i.parentID) : [];
        let checkParent = dragDropId.length > 0 ? dragDropId.map((i) => i.checkParent) : [];
        let levelId = dragDropId.length > 0 && dragDropId[0].levelId;
        let dynastyAttributesFrom = dragDropId.length > 0 && dragDropId[0].dynastyAttributes ? dragDropId[0].dynastyAttributes : '';

        const droppedElement = node;

        if (!droppedElement.sameLevelAccess) {
            if (!droppedElement.nextLevelAccess) {
                this.setState({
                    dragDropInfoMessage: formatMessage({ id: 'KC1109' }),
                    selectedUnitList: [],
                    dragDropId: []
                }, () => {
                    $('#detailsInfoModal').modal('show')
                })
                return;
            }
        }

        for (let i = 0; i < linkList.length; i++) {
            if (linkList[i] && linkList[i].cust_gp_id) {
                if (linkList[i].cust_gp_id === droppedElement.cust_gp_id && dragDropId.length > 0 && !dragDropId.map((i) => i.ID).includes(droppedElement.cust_gp_id)) {
                    let message = '';
                    if (checkParent.includes(linkList[i].cust_gp_id)) {
                        message = formatMessage({ id: 'KC1108' }, { type: type });
                        this.setState({
                            dragDropInfoMessage: message,
                            selectedUnitList: [],
                            dragDropId: []
                        }, () => {
                            $('#detailsInfoModal').modal('show')
                        })
                    } else if (linkList[i].level_id < 3) {
                        message = formatMessage({ id: 'KC1107' }, { type: type });
                        this.setState({
                            dragDropInfoMessage: message,
                            selectedUnitList: [],
                            dragDropId: []
                        }, () => {
                            $('#detailsInfoModal').modal('show')
                        })
                    } else {
                        message = (levelId - linkList[i].level_id === 1) ? formatMessage({ id: 'KC1103' }, { type: type, ID: ID, group_name: linkList[i].group_name }) : `KC1104`;
                        if (levelId - linkList[i].level_id === 1) {
                            const unitCgid = dragDropId.length > 0 ? dragDropId[0].ID : '';
                            message = dragDropId.length > 1
                                ? formatMessage({ id: 'KC1106' }, { length: dragDropId.length, type: type, groupName: linkList[i].group_name })
                                : formatMessage({ id: 'KC1105' }, { length: dragDropId.length, type: type, groupName: linkList[i].group_name });
                            let dynastyReqValue = '';
                            // if its level 5
                            if (levelId === 5 && dynastyAttributesFrom && dynastyAttributesFrom !== '') {
                                //level 5
                                const parsedValue = JSON.parse(dynastyAttributesFrom);
                                if (parsedValue && parsedValue.externalid) {
                                    let linkedListparsedValue = JSON.parse(linkList[i].dynastyAttributes);
                                    if (linkedListparsedValue && linkedListparsedValue.externalid) {
                                        linkedListparsedValue.externalid = parsedValue.externalid;
                                        dynastyReqValue = JSON.stringify(linkedListparsedValue);//updated external id
                                    } else {
                                        linkedListparsedValue.externalid = parsedValue.externalid;
                                        dynastyReqValue = JSON.stringify(linkedListparsedValue);//added external id
                                    }
                                } else {
                                    // if no external id for level 5
                                    // and also external id there for level 4, remove it
                                    let linkedListparsedValue = JSON.parse(linkList[i].dynastyAttributes);
                                    if (linkedListparsedValue.externalid) {
                                        delete linkedListparsedValue.externalid;
                                    }
                                    dynastyReqValue = JSON.stringify(linkedListparsedValue);
                                }
                            } else {
                                dynastyReqValue = linkList[i].dynastyAttributes
                            }
                            const reqData = {
                                "category_id": levelId,
                                "customer_id": localStorage.getItem("customerId"),
                                "to_parent_cust_gp_id": linkList[i].cust_gp_id,
                                "to_parent_dynasty": linkList[i].dynasty,
                                "to_dynastyAttributes": dynastyReqValue,
                                [type === 'level' && dragDropId.length > 1 ? "from_parent_group_id" : "from_cust_gp_id"]: parentID && parentID.length ? parentID[0] : '',
                                "isSingle": type === 'unit' ? false : dragDropId.length > 1 ? false : true,
                            };
                            if (type === 'unit') {
                                reqData['units'] = dragDropId.map((i) => Number(i.ID));
                            }
                            if (type === 'level') {
                                this.getUnitsViaCGID(unitCgid).then((unitLength) => {
                                    if (unitLength && unitLength.length > 20 && unitLength.length !== 0) {
                                        this.setState({
                                            dragDropInfoMessage: formatMessage({ id: 'KC1102' }),
                                            selectedUnitList: [],
                                            dragDropId: []
                                        }, () => {
                                            $('#detailsInfoModal').modal('show')
                                        })
                                        return;
                                    } else {
                                        this.setState({
                                            dragDropMessage: message,
                                            dragDropReqData: reqData,
                                            selectedUnitList: [],
                                            dragDropId: []
                                        }, () => {
                                            $('#dragDropModal').modal('show');
                                        })
                                    }
                                })
                            } else {
                                this.setState({
                                    dragDropMessage: message,
                                    dragDropReqData: reqData,
                                    selectedUnitList: [],
                                    dragDropId: []
                                }, () => {
                                    $('#dragDropModal').modal('show');
                                })
                            }
                        } else {
                            this.setState({
                                dragDropInfoMessage: message,
                                selectedUnitList: [],
                                dragDropId: []
                            }, () => {
                                $('#detailsInfoModal').modal('show')
                            })
                        }

                    }
                }
            }
        }
    }

    /**
     * Unit node selection change handler
     * Collect selected units in an array
     * @param {*} unit Unit data
     */
    onOrgCheckBoxChange = (unit) => {
        const ID = unit.UNITID;
        let { selectedUnitList, showIcons } = this.state;
        const findIndex = selectedUnitList.findIndex((i) => i.UNITID === ID);
        if (findIndex >= 0) {
            selectedUnitList.splice(findIndex, 1);
        } else {
            selectedUnitList.push(unit)
        }
        showIcons['editIcon'] = selectedUnitList.length === 1 ? true : false;
        showIcons['deleteIcon'] = selectedUnitList.length === 1 ? true : false;
        this.setState({ selectedUnitList, showIcons });
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }

    /**
     * Info popup Group Name change handler
     * @param {*} e Event object
     * @returns 
     */
    groupNameChangeHandler(e) {
        if (pattern.test(e.target.value.trim())) {
            return
        }
        const { link } = this.state;
        this.setState({ link: { ...link, group_name: e.target.value } });
    }

    /**
     * Info popup Address change handler
     * @param {*} e Event object
     * @param {*} addressName Address field name
     * @returns 
     */
    onAddressChange(e, addressName) {
        if (pattern.test(e.target.value.trim())) {
            return
        }
        const { newLinkCreated, selectedAddress } = this.state;
        let updateAddress = {};
        if (newLinkCreated && !selectedAddress) {
            updateAddress = { city: '', street: '', zipcode: '', country: '' }
        } else {
            updateAddress = selectedAddress;
        }
        if (addressName === 'ADD1') {
            updateAddress.street = e.target.value;
        }
        if (addressName === 'ADD2') {
            updateAddress.city = e.target.value;
        }
        if (addressName === 'ADD3') {
            updateAddress.zipcode = e.target.value;
        }
        if (addressName === 'ADD4') {
            updateAddress.country = e.target.value;
        }

        this.setState({ selectedAddress: updateAddress, isChangeHappened: true })
    }


    callDowngradeAPIEaas = (unit) => {
        return new Promise(async (res, rej) => {
            this.setState({ loaderIconVisible: true });
            const postData = {
                "customerId": localStorage.getItem('customerId'),
                "allocationType": "DOWNGRADE",
                "subscriptionType": "EAAS",
                "units": unit
            };
            await http({
                method: "POST",
                url: DeviceRegistration.downgradeApi,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: postData
            }).then(async response => {
                this.setState({ loaderIconVisible: false })
                res(response);
            }).catch(err => {
                res(err);
            });
        })
    }

    downgradeNode = async (CGID) => {
        const units = await this.getUnitsViaCGID(CGID);
        const deleteUnit = [];
        for (let index = 0; index < units.length; index++) {
            const element = units[index];
            if (element && element.UNITID) {
                deleteUnit.push(element.UNITID);
            }
        }
        return deleteUnit.join(',')
    }

    /**
     * Delete node confirmation handler
     */
    deleteClickHandler = async (isFromUnit = false, isNavigate = false) => {
        const { link, unit, market_id, isUnitItemClicked, selectedUnitList } = this.state;
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        let customerIdValue = localStorage.getItem("customerId");
        if (!isUnitItemClicked) {
            try {
                this.setState({ loaderIconVisible: true });
                const unitString = await this.downgradeNode(link.cust_gp_id);
                this.props.deleteOrganizationElement(link.cust_gp_id, loggedinUser, customerIdValue).then(async (res) => {
                    await this.getCustGroupID();
                    await this.callDowngradeAPIEaas(unitString)
                    WoopraEvents(`${Constants.ORG_MANAGEMENT}-${link.group_name}-${Constants.DELETED_NODE}`);
                    this.treeData.activeNode = null;
                    this.setState({
                        showIcons: { editIcon: false, sameLevelIcon: false, nextLevelIcon: false, deleteIcon: false },
                        newLinkCreated: false, loaderIconVisible: false, selectedUnitList: [], level: 1, link: { group_name: '' }, linkList: {}, unit: {}
                    }, () => {
                        // $('#changesConfirmationModal').modal('show');
                        this.loadSuccessChanges();
                    });
                });

            } catch (error) {

            }
        } else {
            this.setState({ loaderIconVisible: true });
            await this.onUnitDelete(unit.id, market_id);
            await this.callDowngradeAPIEaas(unit.UNITID);
            await this.componentDidMount(false);
            this.treeData.activeNode = null;
            this.setState({
                showIcons: { editIcon: false, sameLevelIcon: false, nextLevelIcon: false, deleteIcon: false },
                newLinkCreated: false, loaderIconVisible: false, selectedUnitList: [], level: 1, link: { group_name: '' }, linkList: {}, unit: {}
            }, () => {
                $('#unitNodeModal').modal('hide');
                if (isFromUnit && isNavigate) {
                    this.props.history.push('/unitRegistration');
                } else {
                    // $('#changesConfirmationModal').modal('show');
                    this.loadSuccessChanges();
                }
            });
        }
    }

    /**
     * Info popup Activation Status toogle handler
     * @param {*} e Event object
     */
    onActivationChange(e) {
        const { formatMessage } = this.props.intl;

        const { unit } = this.state;
        let { assetNumber } = this.state;
        let unitActiveObject = {};
        if (e.target.className.indexOf('active') > -1) {
            unitActiveObject = {
                "unitIds": [unit.id],
                "activationStatus": 'INACTIVE-ONLINE',
                "assetNumber": assetNumber
            }
            this.setState({
                unitActiveObject: unitActiveObject,
                Activatestatus: formatMessage({ id: "KC1100" }),
                userselectedData: 'Inactive',
                isChangeHappened: true,
                unitToggleChange: true
            });
        } else {
            unitActiveObject = {
                "unitIds": [unit.id],
                "activationStatus": 'ACTIVE',
                "assetNumber": assetNumber
            }
            this.setState({
                unitActiveObject: unitActiveObject,
                Activatestatus: formatMessage({ id: "KC1101" }),
                userselectedData: 'active',
                isChangeHappened: true,
                unitToggleChange: true
            });
        }
    }

    /**
     * Activation Status change confirmation handler
     */
    confirmClickHandler() {
        const { unit, userselectedData } = this.state;
        let { assetNumber } = this.state;
        let unitActiveObject = {};
        if (userselectedData === 'active') {
            unitActiveObject = {
                "unitIds": [unit.id],
                "activationStatus": 'INACTIVE-ONLINE',
                "assetNumber": assetNumber
            }
            WoopraEvents(`${Constants.DEVIVCE_ACTIVATION_STATUS}-${unit.UNITID}-${unitActiveObject.activationStatus}`);
        } else {
            unitActiveObject = {
                "unitIds": [unit.id],
                "activationStatus": 'ACTIVE',
                "assetNumber": assetNumber
            }
            WoopraEvents(`${Constants.DEVIVCE_ACTIVATION_STATUS}-${unit.UNITID}-${unitActiveObject.activationStatus}`);
        }
        this.setState({ unitActiveObject: unitActiveObject });
    }

    removeSpacesOnChange = (e) => {
        var keyCode = e.which || e.keyCode;
        if (keyCode === 32) {
            e.preventDefault();
            return false;
        }
    }

    checkSmartTagAssociation = (cust_gp_id) => {
        for (let index = 0; index < this.state.orgSmartTagList.length; index++) {
            const element = this.state.orgSmartTagList[index];
            if (element.allcgids && element.allcgids.includes(Number(cust_gp_id))) {
                // this.hanleCheckboxForOrgTag(element.id)
            }
        }
    }


    /**
     * Info icon click handler
     * Update ShowElement info state, and assign unit activation status to object
     */
    toggleElementInfoHandler = () => {
        $('body').css('overflow-y', 'hidden');
        this.setState({ unitActiveObject: {} })

        const { unitActiveObject, linkList } = this.state;
        const units = _.isObject(this.props.OrganizationUnitsDetailsInfo) ? this.props.OrganizationUnitsDetailsInfo.units : {};
        if (unitActiveObject.unitIds === undefined) {
            unitActiveObject.unitIds = [];
        }
        const copyUnitId = [...unitActiveObject.unitIds];
        if (units && units.ID) {
            copyUnitId.push(units.ID);
        }
        if (units && (units.subscription_status || units.ACTIVATION_STATUS)) {
            const activationKey = units?.subscription_status !== null && units?.subscription_status !== 'null' ? units.subscription_status === 0 ? 'INACTIVE' : units.subscription_status === 1 ? 'ACTIVE' : units.ACTIVATION_STATUS : units.ACTIVATION_STATUS
            unitActiveObject.activationStatus = activationKey;
        }
        const copyUnitActiveObject = { ...unitActiveObject, unitIds: copyUnitId };
        this.setState({ unitActiveObject: copyUnitActiveObject })
        // pre selected country name display
        const { countyData, link } = this.state;
        const updatedCountryList = [...countyData];
        let getCountryValue, selectedCountry
        if (link && link.level_id) {
            getCountryValue = _.find(linkList.level1[0].level2, { cust_gp_id: link.parent_group_id })
        }
        // if (link && link.cust_gp_id) {
        //     this.checkSmartTagAssociation(link.cust_gp_id);
        // }
        if (getCountryValue && getCountryValue.dynastyAttributes) {
            selectedCountry = JSON.parse(getCountryValue.dynastyAttributes)
        }
        if (link.level_id === 4) {
            linkList.level1[0].level2.map((val, index) => {
                if (_.find(linkList.level1[0].level2[index].level3, { cust_gp_id: link.parent_group_id }) !== undefined)
                    return selectedCountry = linkList.level1[0].level2[index].dynastyAttributes ? JSON.parse(linkList.level1[0].level2[index].dynastyAttributes) : "";
            })
        }
        selectedCountry && selectedCountry.country ? selectedCountry = selectedCountry.country : selectedCountry = ""
        this.setState({
            countyData: updatedCountryList,
            findCountryName: selectedCountry,
            isChangeHappened: false,
        }, () => {
            if (link.level_id === 1) {
                $('#customerNodeModal').modal('show');
            } else if (link.level_id === 2) {
                $('#countryNodeModal').modal('show');
            } else if (link.level_id === 3) {
                $('#chainNodeModal').modal('show');
            } else if (link.level_id === 4) {
                $('#locationNodeModal').modal('show');
            } else if (link.level_id === 5) {
                $('#withinLocationNodeModal').modal('show');
            } else {
                $('#unitNodeModal').modal('show');
            }
        });
    };

    /**
     * Sort dropdown click handler
     * @param {*} sorttype Sort type ex. asc, desc etc.
     * @param {*} sortlevel Level to sort ex. 1 - 6
     */
    handleDropDown = async (sorttype, sortlevel) => {
        this.setState({
            sortlevel: sortlevel,
            sorttype: sorttype,
        }, () => {
            this.getCustGroupID();
        })

    }

    /**
     * Level header double click handler
     * @param {*} renameNodeLevelId Level ID
     * @param {*} newNodeName New level name
     */
    onHeadingDoubleClick(renameNodeLevelId, newNodeName) {
        if (renameNodeLevelId <= 5) {
            $('#doubleClickModal').modal('toggle');
            this.setState({ renameNodeLevelId, newNodeName })
        }
    }

    /**
     * Level rename confirmation handler
     */
    renamedNodeName = () => {
        const { formatMessage } = this.props.intl;

        this.setState({ loaderIconVisible: true })
        const { newNodeName, renameNodeLevelId } = this.state;

        let reqData = {
            "caption": newNodeName,
        };
        let url = UserProfile.renameNodeName + renameNodeLevelId.toString();

        http.put(url, reqData, {
            headers: {
                'Content-Type': 'application/json',
                "cid": localStorage.getItem('customerId'),
                "user": localStorage.getItem("user")
            }
        }).then(async response => {
            this.getCustGroupID();
            if (response && response.data && response.data.successCode) {
                this.setState({
                    success_modal: {
                        open: true,
                        message: formatMessage({ id: "KC1099" })
                    }
                });
            }
            else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: response && response.data && response.data.responseCode ? formatMessage({ id: "KC2289" }) : ""
                    }
                });
            }
            $('#orgLoader').css('z-index', 1000); // To bring modal to foreground
            WoopraEvents(`${Constants.RENAME_COLUMN}-${newNodeName}`);
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response && err.response.data && err.response.data.message ? err.response.data.message : "KC1148",
                },
            });
            WoopraEvents(`${Constants.RENAME_COLUMN}-${Constants.RENAME_COLUMN_FAILED}`);
        });
    }

    /**
     * New level name change handler
     * @param {*} val New level name
     * @returns 
     */
    enterNodeTitleName = (val) => {
        let nodeName = val;
        if (pattern.test(nodeName.trim())) {
            return
        }
        this.setState({
            newNodeName: nodeName.length > 30 ? this.state.newNodeName : nodeName,
        })
    }

    // Woopra Events
    trackEvent = (e) => {
        WoopraEvents(`${Constants.ORG_MANAGEMENT}-${e.trackTitle}`);
    }
    canceledRename = () => {
        WoopraEvents(`${Constants.ORG_MANAGEMENT}-${Constants.CANCLED_RENAME}`);
    }
    cancelRename = () => {
        WoopraEvents(`${Constants.ORG_MANAGEMENT}-${Constants.CANCEL_RENAME}`);
    }
    confirmRename = () => {
        WoopraEvents(`${Constants.ORG_MANAGEMENT}-${Constants.CONFIRM_RENAME}`);
    }

    /**
     * Info popup Asset No change handler
     * User Story 43172: KC Portal - Organization Mgmt - Unit Asset ID Editable implementation
     * @param {*} e Event object
     */
    editAssetFieldHandler = (e) => {
        let assetNumber = e.target.name === "assetNumber" && e.target.value;
        this.setState({ assetNumber: e.target.value, isChangeHappened: true });
    }

    /**
     * Set tree view container height
     */
    setTreeViewHeight() {
        const windowHeight = $(window).height();
        const $treeViewBody = $('.orgTreeViewOuter .treeViewBody');
        $treeViewBody.css('height', windowHeight - $treeViewBody.offset().top);
    }

    /**
     * Convert hierarchy data structure into flatten array
     * @param {*} nodes Array<HierarchyNode> Array of hierarchy node object
     * @param {*} nodeLevel Node level No
     * @param {*} currentRowIndex Current row index for Tree Data
     * @param {*} flattenTreeData Array<Array> Accumulated flatten tree data
     * @param {*} parentRowIndex Parent Node row index
     * @param {*} parentColIndex Parent Node column index
     * @returns Array<Array>
     */
    convertToFlattenTreeData(nodes, nodeLevel = 1, currentRowIndex = 0, flattenTreeData = [], parentRowIndex = null, parentColIndex = null) {
        let prevRowIndex = 0;
        const nodeIndexes = [];
        const nodesLength = nodes.length;
        for (let i = 0; i < nodesLength; i++) {
            const node = nodes[i];

            // Set row and column index
            i > 0 && currentRowIndex++;
            const rowIndex = currentRowIndex;
            const level = node.level_id || nodeLevel; // If level_id not available in API response, read from method param. Fix for DEV env
            const colIndex = level - 1;

            // Set connectors in midway indexes, but not for level 1 and 6
            if ((level > 1 && level < 6) && i > 0) {
                const endRowIndex = rowIndex - 1; // Exclude current row
                let midwayRowsCount = endRowIndex - prevRowIndex;
                if (midwayRowsCount > 0) {
                    const startRowIndex = prevRowIndex + 1; // Exclude start index
                    for (let j = startRowIndex; j <= endRowIndex; j++) {
                        const rowData = flattenTreeData[j] || [];
                        rowData[colIndex] = {
                            __NODE_DATA__: {
                                isConnector: true,
                                rowIndex: j,
                                colIndex,
                                parentRowIndex,
                                parentColIndex
                            }
                        };
                        flattenTreeData[j] = rowData;
                    }
                }
            }

            // Create node object
            const childNodesProp = level < 5 ? `level${level + 1}` : 'units';
            const { [childNodesProp]: childNodes, ...nodeClone } = node;
            const nodeData = {
                isNode: true,
                rowIndex,
                colIndex,
                parentRowIndex,
                parentColIndex,
                hasChildren: !!node.child_count,
                isFirstChild: nodesLength > 1 && i === 0,
                isMidwayChild: i > 0 && i < (nodesLength - 1),
                isLastChild: nodesLength > 1 && i === (nodesLength - 1),
                isOnlyChild: nodesLength === 1
            };
            if (level === 6) {
                const smartGroupsName = this.getUnitSmartGroupsName(nodeClone);
                nodeData.smartGroupsName = smartGroupsName;
            }
            nodeClone.__NODE_DATA__ = nodeData;

            // Set node object in respective index
            const rowData = flattenTreeData[rowIndex] || [];
            rowData[colIndex] = nodeClone;
            flattenTreeData[rowIndex] = rowData;
            nodeIndexes.push([rowIndex, colIndex]);

            // Process its children
            if (nodeData.hasChildren) {
                const result = this.convertToFlattenTreeData(
                    childNodes,
                    nodeLevel + 1,
                    currentRowIndex,
                    flattenTreeData,
                    rowIndex,
                    colIndex
                );
                flattenTreeData = result.flattenTreeData;
                flattenTreeData[rowIndex][colIndex].__NODE_DATA__.childNodeIndexes = result.nodeIndexes;
                currentRowIndex = result.currentRowIndex;
            }
            prevRowIndex = rowIndex;
        }
        return { flattenTreeData, nodeIndexes, currentRowIndex };
    }

    /**
     * Get Unit Smart Group names for display at Unit Node
     * @param {*} unitNode Unit Node object
     * @returns Array<String> Smart Group names
     */
    getUnitSmartGroupsName(unitNode) {
        let smartGroups = unitNode.smartGroup || [];
        let smartGroupsName = [];
        if (smartGroups.length > 3) {
            smartGroupsName.push({
                sgName: '',
                sgFirstName: smartGroups.length,
            });
        } else if (smartGroups.length > 0) {
            smartGroups.forEach(item => {
                smartGroupsName.push({
                    sgName: item.name,
                    sgFirstName: item.smartGroupKeyName && item.smartGroupKeyName !== 'null'
                        ? item.smartGroupKeyName
                        : item.name.charAt(0)
                });
            });
        }
        return smartGroupsName;
    }

    /**
     * Get Node object from Hierarchy Data Structure
     * Use this method to retain the props removed during flatten array conversion
     * @param {*} searchNode Node to search
     * @returns Node object
     */
    getNodeFromHierarchyData(searchNode) {
        const dynastyArr = searchNode.dynasty.split(',').map(item => parseInt(item));
        let node = this.state.linkList;
        for (let i = 0; i < dynastyArr.length; i++) {
            const nodes = node[`level${i + 1}`] ? node[`level${i + 1}`] : [];
            for (let j = 0; j < nodes.length; j++) {
                if (nodes[j].cust_gp_id === dynastyArr[i]) {
                    node = nodes[j];
                    break;
                }
            }
        }
        node = { ...node };
        node.__NODE_DATA__ = { ...searchNode.__NODE_DATA__ };
        return node;
    }

    /**
     * Tree View node expand/collapse click handler
     * @param {*} node Node object
     */
    handleExpandCollapse(node) {
        if (!this.treeData.collapsedNodes[node.cust_gp_id]) {
            this.treeData.collapsedNodes[node.cust_gp_id] = true;
        } else {
            delete this.treeData.collapsedNodes[node.cust_gp_id];
        }
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }

    /**
     * Get the Node status like invisible, count node
     * @param {*} node Node object
     * @returns Object Status object
     */
    getNodeStatus(node) {
        const nodeData = node.__NODE_DATA__;
        const dynastyArr = node.dynasty.split(',').map(item => parseInt(item));

        // Visibility, Count Node, Display count calculation
        const dynastyArrCopy = [...dynastyArr];
        dynastyArrCopy.pop(); // Remove its own ID
        dynastyArrCopy.length && dynastyArrCopy.pop(); // Remove parent node ID
        const isForeNodeCollapsed = dynastyArrCopy.some(item => this.treeData.collapsedNodes[item]);
        const isParentCollapsed = this.treeData.collapsedNodes[node.parent_group_id];
        const isFirstChild = nodeData.isFirstChild || nodeData.isOnlyChild;
        const parentNode = nodeData.parentRowIndex !== null ? this.state.flattenTreeData[nodeData.parentRowIndex][nodeData.parentColIndex] : null;

        // Active status, Root Node active status calculation
        let isRootActiveNode = false;
        let isActive = false;
        if (this.treeData.activeNode && this.treeData.activeNode.__NODE_DATA__.colIndex < 5) {
            isActive = dynastyArr.indexOf(this.treeData.activeNode.cust_gp_id) >= 0;
            isRootActiveNode = node.cust_gp_id === this.treeData.activeNode.cust_gp_id;
        }

        return {
            isInvisible: isForeNodeCollapsed || (isParentCollapsed && !isFirstChild),
            isCountNode: !isForeNodeCollapsed && isParentCollapsed && isFirstChild,
            displayCount: parentNode ? parentNode.child_count : 0,
            isRootActiveNode,
            isActive
        };
    }

    /**
     * Create view elements for Tree View Node
     * @param {*} node Node object
     * @param {*} options Additional options
     * @returns ReactDOM
     */
    getNodeView(node, { key }) {
        // For empty or connector
        if (!node) {
            return <div key={key} className="treeViewCell"></div>
        } else if (node.__NODE_DATA__.isConnector) {
            const parentNode = this.state.flattenTreeData[node.__NODE_DATA__.parentRowIndex][node.__NODE_DATA__.parentColIndex];
            const activeClass = this.getNodeStatus(parentNode).isActive ? 'active' : '';
            return <div key={key} className={`treeViewCell connector ${activeClass}`}></div>
        }

        // For node
        const nodeData = node.__NODE_DATA__;
        const draggableProps = {
            draggable: true,
            onDragStart: (e) => this.onDragLevelStart(node),
            onDragOver: (e) => this.onDragOver(e),
            onDrop: (e) => {
                // Get drop node siblings
                let parentNode = node;
                if (nodeData.parentRowIndex !== null) {
                    // If dropped into a root node
                    parentNode = this.state.flattenTreeData[nodeData.parentRowIndex][nodeData.parentColIndex];
                }
                const nodeWithHierarchy = this.getNodeFromHierarchyData(parentNode);
                const nodeSiblings = nodeWithHierarchy[`level${parentNode.level_id + 1}`];
                return this.onDrop(node, nodeSiblings || []);
            }
        };
        const status = this.getNodeStatus(node);
        if (status.isInvisible) {
            return <div key={key} className="treeViewCell"></div>
        } else if (status.isCountNode) {
            const activeClass = status.isActive ? 'active' : '';
            const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
            return <div key={key} className={`treeViewCell countCell ${activeClass} ${rootActiveClass}`}>
                <div className="treeViewNodeWrapper" {...draggableProps}>
                    <Link
                        className="treeViewNode"
                        to="#"
                        // onDoubleClick={() => { this.onDoubleClickItem(this.getNodeFromHierarchyData(node)) }}
                        onClick={(e) => { this.onItemClick(e, this.getNodeFromHierarchyData(node)) }}>
                        {status.displayCount}
                    </Link>
                </div>
            </div>
        }
        const dynastyAttributes = node.dynastyAttributes ? JSON.parse(node.dynastyAttributes) : {};
        let nodeImage = node.imagePath || (brandLocationsMap[dynastyAttributes.location_type]
            ? brandLocationsMap[dynastyAttributes.location_type].img
            : bg);
        if (node.level_id && (node.level_id === 5 || node.level_id === 4) && brandLocationsMap[dynastyAttributes.location_type] && brandLocationsMap[dynastyAttributes.location_type].img) {
            nodeImage = brandLocationsMap[dynastyAttributes.location_type].img;
        }
        const { isFirstChild, isMidwayChild, isLastChild, hasChildren } = nodeData;
        const isCollapsed = this.treeData.collapsedNodes[node.cust_gp_id];
        const firstChildClass = isFirstChild ? 'firstChild' : '';
        const midwayChildClass = isMidwayChild ? 'midwayChild' : '';
        const lastChildClass = isLastChild ? 'lastChild' : '';
        const hasChildrenClass = hasChildren ? 'hasChildren' : '';
        const activeClass = status.isActive ? 'active' : '';
        const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
        return <div key={key} className={`treeViewCell ${firstChildClass} ${midwayChildClass} ${lastChildClass} ${hasChildrenClass} ${activeClass} ${rootActiveClass}`}>
            <div className="treeViewNodeWrapper" {...draggableProps}>
                <Link
                    className="treeViewNode"
                    to="#"
                    // onDoubleClick={() => { this.onDoubleClickItem(this.getNodeFromHierarchyData(node), nodeImage) }}
                    onClick={(e) => {
                        if (e.defaultPrevented) return
                        e.preventDefault();
                        this.onItemClick(e, this.getNodeFromHierarchyData(node), nodeImage)
                    }}>
                    <img className="nodeImg" src={nodeImage || bg} alt="" />
                    <span className="text-truncate nodeInfo"><b>{node.group_name}</b></span>
                    {node.isOrgTagExist && <button className="btn orgTagedUnits" type="button" onClick={(e) => {
                        if (e.defaultPrevented) return
                        e.preventDefault()
                        this.showSTagInfo(node, "org")
                    }}>&nbsp;</button>}
                </Link>
                {hasChildren ? <button className={`expandCollapseBtn ${isCollapsed ? 'collapsed' : ''}`} onClick={() => this.handleExpandCollapse(node)}></button> : ''}
            </div>
        </div>
    }

    /**
     * Get the Unit Node status like invisible, count node
     * @param {*} unitNode Unit Node object
     * @returns Object Status object
     */
    getUnitNodeStatus(unitNode) {
        const nodeData = unitNode.__NODE_DATA__;
        const parentNode = this.state.flattenTreeData[nodeData.parentRowIndex][nodeData.parentColIndex];
        const dynastyArr = parentNode.dynasty.split(',').map(item => parseInt(item));

        // Visibility, Count Node, Display count calculation
        const dynastyArrCopy = [...dynastyArr];
        dynastyArrCopy.pop(); // Remove its own ID
        const isForeNodeCollapsed = dynastyArrCopy.some(item => this.treeData.collapsedNodes[item]);
        const isParentCollapsed = this.treeData.collapsedNodes[parentNode.cust_gp_id];
        const isFirstChild = nodeData.isFirstChild || nodeData.isOnlyChild;

        // Active status, Root Node active status calculation
        let isRootActiveNode = false;
        let isActive = false;
        if (this.treeData.activeNode) {
            if (this.treeData.activeNode.__NODE_DATA__.colIndex < 5) {
                isActive = dynastyArr.indexOf(this.treeData.activeNode.cust_gp_id) >= 0;
                isRootActiveNode = false;
            } else {
                isActive = unitNode.UNITID === this.treeData.activeNode.UNITID;
                isRootActiveNode = true;
            }
        }

        // Selection status
        const isSelected = this.state.selectedUnitList.findIndex(item => item.UNITID === unitNode.UNITID) >= 0;

        return {
            isInvisible: isForeNodeCollapsed || (isParentCollapsed && !isFirstChild),
            isCountNode: !isForeNodeCollapsed && isParentCollapsed && isFirstChild,
            displayCount: parentNode.child_count,
            isRootActiveNode,
            isActive,
            isSelected
        };
    }


    /**
     * Create view elements for Tree View Unit Node
     * @param {*} unitNode Unit node object
     * @param {*} options Additional options
     * @returns ReactDOM
     */
    getUnitNodeView(unitNode, { key }) {
        // For empty
        if (!unitNode) {
            return <div key={key} className="treeViewCell"></div>
        }

        // For node
        const draggableProps = {
            draggable: true,
            onDragStart: (e) => this.onDragStart()
        };
        const status = this.getUnitNodeStatus(unitNode);
        if (status.isInvisible) {
            return <div key={key} className="treeViewCell"></div>
        } else if (status.isCountNode) {
            const activeClass = status.isActive ? 'active' : '';
            const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
            return <div key={key} className={`treeViewCell unitCell countCell ${activeClass} ${rootActiveClass}`}>
                <div className="treeViewNodeWrapper" {...draggableProps}>
                    <Link
                        className="treeViewNode"
                        to="#"
                        // onDoubleClick={async (e) => {
                        //     // await this.onUnitItemClick(e, unitNode, "double");
                        //     this.toggleElementInfoHandler();
                        //     this.setState({ imagePreviewUrl: unitImage });
                        // }}
                        onClick={(e) => { this.onUnitItemClick(e, unitNode); this.setState({ imagePreviewUrl: unitImage }); }}>
                        {status.displayCount}
                    </Link>
                </div>
            </div>
        }
        const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
        const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unitNode.BRAND_NAME && i.MODEL_NAME === unitNode.MODEL_NAME) : [];
        let unitImage = '';
        if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "") {
            unitImage = filteredBrandImage[0].LOGO_URL;
        } else {
            unitImage = brandsMap[unitNode.MODEL_NAME] ? brandsMap[unitNode.MODEL_NAME].img : bg;
        }
        const activeValueKey  = unitNode?.subscription_status !== null && unitNode?.subscription_status !== 'null' ? unitNode.subscription_status === 0 ? 'INACTIVE' : 'ACTIVE' : unitNode.ACTIVATION_STATUS;

        const isUnitInActive = activeValueKey === 'INACTIVE-ONLINE' || activeValueKey === 'INACTIVE';
        const nodeData = unitNode.__NODE_DATA__;
        const { isFirstChild, isMidwayChild, isLastChild } = nodeData;
        const firstChildClass = isFirstChild ? 'firstChild' : '';
        const midwayChildClass = isMidwayChild ? 'midwayChild' : '';
        const lastChildClass = isLastChild ? 'lastChild' : '';
        const activeClass = status.isActive ? 'active' : '';
        const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
        return <div key={key} className={`treeViewCell unitCell ${firstChildClass} ${midwayChildClass} ${lastChildClass} ${activeClass} ${rootActiveClass}`}>
            <div className="treeViewNodeWrapper" {...draggableProps}>
                <Link
                    to="#"
                    className={`treeViewNode ${isUnitInActive ? 'inActiveUnitNode' : ''}`}
                    // onDoubleClick={async (e) => {
                    //     // await this.onUnitItemClick(e, unitNode, "double");
                    //     this.toggleElementInfoHandler();
                    //     this.setState({ imagePreviewUrl: unitImage });
                    // }}
                    onClick={(e) => {
                        if (e.defaultPrevented) return
                        e.preventDefault();
                        this.onUnitItemClick(e, unitNode);
                        this.setState({ imagePreviewUrl: unitImage })
                    }}>
                    <img className="nodeImg" src={unitImage} />
                    <span className="nodeInfo">
                        <b className="d-block text-truncate unitSerialNo">{unitNode.UNITID}</b>
                        <b className="d-block text-truncate">{unitNode.UNIT_NUMBER}</b>
                        <b className="d-block text-truncate">{unitNode.MODEL_NAME}</b>
                    </span>
                    {isUnitInActive ? <span className="inActiveIcon"></span> : ''}
                    {unitNode.isUnitTagExist && <button className="btn tagedUnits" type="button" onClick={(e) => {
                        if (e.defaultPrevented) return
                        e.preventDefault()
                        this.showSTagInfo(unitNode, "unit")
                    }}>&nbsp;</button>}
                    {
                        status.isSelected ?
                            <React.Fragment>
                                <div className="nodeCheckbox">
                                    <input
                                        className="d-none"
                                        id={unitNode.UNITID}
                                        name={unitNode.UNITID}
                                        type="checkbox"
                                        checked={true}
                                        value={unitNode.UNITID}
                                        onChange={(e) => this.onOrgCheckBoxChange(unitNode)} />
                                    <label htmlFor={unitNode.UNITID}></label>
                                </div>
                            </React.Fragment>
                            : ''
                    }
                </Link>
            </div>
        </div>
    }

    /**
     * Check if the row has at least one visible node
     * @param {*} rowData Row Array
     * @returns Boolean - Return true if it has visible node else return false
     */
    doesRowHasVisibleNode(rowData) {
        return rowData.some((node, i) => node && node.__NODE_DATA__.isNode && (i < 5 ? !this.getNodeStatus(node).isInvisible : !this.getUnitNodeStatus(node).isInvisible));
    }

    /**
     * Row render callback for Virtual Scroll component
     * @param {*} param Event object
     * @returns ReactDOM
     */
    treeViewRowRenderer({ index, key, style }) {
        const { flattenTreeData } = this.state;
        const rowData = flattenTreeData[index];
        if (!this.doesRowHasVisibleNode(rowData)) {
            return <div className="treeViewRow" key={key} style={style}></div>
        }
        return <div className="treeViewRow" key={key} style={style}>
            {new Array(5).fill().map((_, j) => this.getNodeView(rowData[j], { key: `${key}_${j}` }))}
            {this.getUnitNodeView(rowData[5], { key: `${key}_5` })}
        </div>
    }

    /**
     * Return the computed Tree View row height
     * Compute the height based on row content
     * @param {*} param Event object
     * @returns Number
     */
    getTreeViewRowHeight({ index }) {
        const rowData = this.state.flattenTreeData[index];
        if (!this.doesRowHasVisibleNode(rowData)) {
            return 0;
        }
        // Compute row height based on unit node
        const unitNode = rowData[5];
        const status = unitNode ? this.getUnitNodeStatus(unitNode) : null;
        let rowBaseHeight = this.treeData.rowBaseHeight;
        if (unitNode && !status.isInvisible && !status.isCountNode) {
            if (unitNode.UNIT_NUMBER && unitNode.MODEL_NAME) {
                rowBaseHeight = rowBaseHeight + 15;
            }
            if (unitNode.__NODE_DATA__.smartGroupsName.length) {
                rowBaseHeight = rowBaseHeight + 22;
            }
        }
        return rowBaseHeight;
    }

    formBreadCrum = (dynasty) => {
        const { flattenTreeData } = this.state;
        let crum = [];
        const dynastyArr = dynasty.split(',');
        for (let index = 0; index < flattenTreeData.length; index++) {
            for (let indexJ = 0; indexJ < flattenTreeData[index].length; indexJ++) {
                const nodeEle = flattenTreeData[index][indexJ];
                if (nodeEle && nodeEle.cust_gp_id) {
                    if (dynastyArr.includes(nodeEle.cust_gp_id.toString())) {
                        crum.push(nodeEle.group_name);
                    }
                }
            }
        }
        // return crum && crum.length ? crum.join(' &gt; ') : '';
        return crum && crum.length ? crum.join(' > ') : '';
    }

    callSmartTag = async (selectedNodeList, selectedSmartTag, type) => {
        this.setState({
            loaderIconVisible: true,
        });
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        let userName = localStorage.getItem("userName");
        for (let index = 0; index < selectedSmartTag.length; index++) {
            const idEle = selectedSmartTag[index];
            let element = type === "UNIT" ? this.state.unitSmartTagList.filter((i) => i.tagName === idEle) : this.state.orgSmartTagList.filter((i) => i.tagName === idEle);
            if (element.length) {
                element = element[0];
            } else {
                return;
            }
            let postdata = {
                "tagName": element.tagName,
                "description": element.description,
                "tagType": type,
                "createdBy": element.createdBy,
                "createdByName": element.createdByName,
                "updatedBy": loggedinUser,
                "updatedByName": userName,
                "customerGroupId": selectedNodeList,
                "unitId": selectedNodeList,
                "customerId": + localStorage.getItem("customerId"),
            };

            let url = smartTagMangement.createOrgTag;

            const id = element.id;
            await this.triggerSmartTag(url + `/${id}`, postdata, 'put');
        }
    }

    triggerSmartTag = (url, postdata, method) => {
        return new Promise((res, rej) => {
            http[method](url, postdata,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            ).then(response => {
                if (response && response.data) {
                    res(response.data)
                }
            }).catch(err => {
                res(err);
            });
        })
    }

    resetState = () => {
        const { isChangeHappened, selectedUnitList } = this.state
        let editMode = selectedUnitList && selectedUnitList.length > 0 && !isChangeHappened ? true : false;
        this.treeData.activeNode = null;
        this.setState({
            selectedSmartOrgTag: [],
            selectedSmartUnitTag: [],
            searchUnitSmartTag: '',
            searchOrgSmartTag: '',
            isChangeHappened: false,
            selectedUnitList: [],
            showAssignedUsers: false,
            showTotalElements: false,
            showIcons: { editIcon: editMode, sameLevelIcon: false, nextLevelIcon: false, deleteIcon: editMode },
            newLinkCreated: false,
            loaderIconVisible: false,
            level: 1,
            unitActiveObject: {}
        })
    }

    // clear Global Search
    clearGlobalSearch = () => {
        this.props.clearSearchText();
    }

    render() {
        const { formatMessage } = this.props.intl;
		const paymentStatus = localStorage.getItem('subscriptionDetails') ? JSON.parse(localStorage.getItem('subscriptionDetails')).payment_status_id : 0;

        const { OrganizationLocationTypesInfo, OrganizationSubElementCountInfo, OrganizationUnitsDetailsInfo } = this.props;
        let {
            unitActiveObject,
            loaderIconVisible,
            isUnitItemClicked,
            countyData,
            linkList,
            findCountryName,
            showUserList,
            success_modal,
            unit,
            error_modal,
            country_name,
            link,
            selectedAddress,
            imagePreviewUrl,
            Activatestatus,
            dragDropMessage,
            dragDropInfoMessage,
            newNodeName,
            assetNumber,
            level, newLinkCreated, showIcons,
            flattenTreeData,
            showpopup,
            type, isPaymentDetailAvailable
        } = this.state;

        const { units } = OrganizationUnitsDetailsInfo;
        let organizationAuthirizedFeature = authService.getFeature("/organizationManagement");
        let unitRegistrationFeature = authService.getFeature("/addUnit");
        let isSmartTagAccess = authService.getFeature("/smartTag/tagList");
        let disableEdit = '';
        if (organizationAuthirizedFeature.is_editable === 0) {
            disableEdit = 'disabled'
        } else disableEdit = '';

        let city, zipcode, street, pickUnit, locationTypeCheck, picLocation;


        if (selectedAddress) {
            city = selectedAddress.city;
            zipcode = selectedAddress.zipcode;
            street = selectedAddress.street;
        }
        const toggleSwitchClass = ['btn', 'btn-secondary'];

        let state = '';
        let toggleUISwitch = null;
        if (unitActiveObject && unitActiveObject.activationStatus === 'ACTIVE') {
            state = 'ACTIVE'
            toggleSwitchClass.push('focus', 'active')
        } else {
            state = 'INACTIVE-ONLINE';
        }

        toggleUISwitch = (<label className={toggleSwitchClass.join(' ')} id="activeDeactive"
            onClick={(e) => this.onActivationChange(e)}>
            <input type="checkbox" autoComplete="off" />
        </label>);
        let pickUnits = {};

        if (isUnitItemClicked && units) {
            const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
            const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === units.BRAND_NAME && i.MODEL_NAME === units.MODEL_NAME) : [];
            if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "") {
                pickUnits['img'] = filteredBrandImage[0].LOGO_URL;
                pickUnits['alt'] = filteredBrandImage[0].MODEL_NAME;
                pickUnits['title'] = filteredBrandImage[0].MODEL_NAME;
            } else {
                pickUnits = brandsLogo.find(b => b.name === units.MODEL_NAME);
            }
        }
        if (link.level_id === 2 || link.level_id === 5) {
            locationTypeCheck = selectedAddress.location_type;
            picLocation = brandsLocationLogo.find(b => b.name === locationTypeCheck);
        }
        let withTernaryImageLoad = imagePreviewUrl ? imagePreviewUrl
            : pickUnit ? pickUnit.img
                : picLocation && !newLinkCreated ? picLocation.img
                    : null;
        if (link.level_id && (link.level_id === 5 || link.level_id === 4)) {
            withTernaryImageLoad = picLocation ? picLocation.img : null;
        }
        if (isUnitItemClicked && units) {
            withTernaryImageLoad = imagePreviewUrl ? imagePreviewUrl
                : pickUnits ? pickUnits.img
                    : picLocation ? picLocation.img
                        : bg;
        }

        let levelHeader = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                levelHeader = (
                    categoryList.map((item) => <div key={item.level_id} className="treeViewCell">
                        <Link className={item.level_id === 1 ? "" : this.treeData.expand[item.level_id - 1] ? "collapseExpandHeader" : "collapseExpandHeader expanded"} key={item.level_id} to="#" title={formatMessage({ id: 'KC1693' }, { number: item.level_id })} data-target="#doubleClickModal" onClick={() => this.handleManualExpandCollapse(item.level_id - 1, this.state.flattenTreeData, true)} onDoubleClick={() => this.onHeadingDoubleClick(item.level_id, item.category_name)}>{item.category_name}</Link>
                        {item.level_id !== 1 ? <div className="dropdown datepickerBtnGroup">
                            <button className="btn dropdown-toggle dateRangeSelection" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">&nbsp;</button>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <button onClick={() => this.handleDropDown("asc", item.level_id)} className="dropdown-item border0" ><FormattedMessage id='KC0526' /></button>
                                <button onClick={() => this.handleDropDown("desc", item.level_id)} className="dropdown-item border0" ><FormattedMessage id='KC0527' /></button>
                                <button onClick={() => this.handleDropDown("newfirst", item.level_id)} className="dropdown-item border0" ><FormattedMessage id='KC0528' /></button>
                                <button onClick={() => this.handleDropDown("oldfirst", item.level_id)} className="dropdown-item border0" ><FormattedMessage id='KC0529' /></button>
                            </div>
                        </div> : ""}
                    </div>)
                );
                /* To keep header and body columns identical in size */
                while (levelHeader.length < 6) {
                    levelHeader.push(<div key={levelHeader.length + 1} className="treeViewCell emptyCell"></div>);
                }
            }
        }
        //User Story 46124: Organization Mgmt - Avoid deletion of Customer Node
        const deleteBtnClass = ['btn btn-default-text orgznTrash'];
        if (link && link.level_id === 1) {
            deleteBtnClass.push('disabled');
        }
        countyData = countyData.sort();
        const isValidDate = unit?.unit_prepaid_end_date ? moment.GetDateTimeDifference() > 0 ? false : true : false

        return (

            <div id="adminWrapper">
                <div id="orgLoader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-Welbilt">
                    <div className="collapse navbar-collapse" id="navbarContent">
                        <div className="navbar-header">

                            <Link className="navbar-brand welbiltRLogo" title={formatMessage({ id: 'KC0038' })} to="/mainMenu">&nbsp;</Link>
                        </div>
                        <ul className="navbar-nav mr-auto selectedNavbarLeft">
                            <li className="nav-item">
                                <Link className="nav-link" to="#" title={formatMessage({ id: 'KC0519' })}>
                                    <span className="orgznManagementNav"><FormattedMessage id='KC0519' /></span>
                                </Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav mr-auto navbarRight">
                            <li className="nav-item">
                                <Link className="nav-link navFilter" title={formatMessage({ id: 'KC0996' })} to="#">&nbsp;</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link navSearch" title={formatMessage({ id: 'KC0037' })} to="#">&nbsp;</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link navAlarm" title={formatMessage({ id: 'KC0997' })} to="#">&nbsp;</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link navUser" title={formatMessage({ id: 'KC0998' })} to="#">&nbsp;</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav mr-auto navbarRight">
                            <li className="nav-item">
                                <Link className="nav-link navBack" title={formatMessage({ id: 'KC0989' })} to="/mainmenu">&nbsp;</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div id="page-content-wrapper" className='withSearchBar'>
                    <div className="orgznManagementWrapper">
                        <div className="navHeader">
                            <ul>
                                <li>
                                    <Link to="/organizationManagement" title={formatMessage({ id: "KC0519" })} className="active"><FormattedMessage id="KC0519" /></Link>
                                    {unitRegistrationFeature && (unitRegistrationFeature.is_editable === 0 || unitRegistrationFeature.is_editable === 1) ? <Link to="/unitRegistration" onClick={() => this.clearGlobalSearch()} title={formatMessage({ id: "KC0631" })} ><FormattedMessage id="KC0631" /></Link> : ""}
                                    {isSmartTagAccess && (isSmartTagAccess.is_editable === 0 || isSmartTagAccess.is_editable === 1) ? <Link to="/smartTag/tagList" onClick={() => this.clearGlobalSearch()} title={formatMessage({ id: "KC2318" })} ><FormattedMessage id="KC2318" /></Link> : ""}
                                </li>
                                <li>
                                    <input id="imgBrowseFile" onChange={(e) => { this.handleImageChange(e) }} type="file" name="pic" accept="image/*" className="menuUpdateFile" style={{ display: 'none' }} />
                                    <button type="button" title={formatMessage({ id: 'KC0138' })} className="btn btn-default-text orgznInfo" data-target="#userToggle"
                                        id="userToggle" onClick={(e) => { this.state.isUnitItemClicked ? this.onUnitNodeDoubleClick(e, unit) : this.toggleElementInfoHandler() }}
                                        // disabled={showIcons.editIcon || isUnitItemClicked || newLinkCreated ? '' : 'disabled'}>&nbsp;
                                        disabled={showIcons.editIcon ? '' : 'disabled'}>&nbsp;
                                    </button>
                                    {organizationAuthirizedFeature.is_editable === 1 &&
                                        <React.Fragment>
                                            <button type="button" title={formatMessage({ id: 'KC0539' })} className="btn btn-default-text orgznAddBelow"
                                                disabled={(level === 1 || level === 6 || newLinkCreated) ? true : showIcons.sameLevelIcon ? '' : 'disabled'}
                                                onClick={() => { this.onSamelevelPlusButtonClick(true) }} >&nbsp;
                                            </button>

                                            <button type="button" title={formatMessage({ id: 'KC0540' })} className="btn btn-default-text orgznAddRight"
                                                disabled={(level === 5 || level === 6 || newLinkCreated) ? true : showIcons.nextLevelIcon ? '' : 'disabled'}
                                                onClick={() => { this.onSamelevelPlusButtonClick(false) }} >&nbsp;
                                            </button>

                                            <button type="button" title={formatMessage({ id: 'KC0524' })} className={deleteBtnClass.join(' ')}
                                                data-toggle="modal" data-target={this.state.isUnitItemClicked ? "#unitUnAssignModal" : "#confirmationDeleteModal"} onClick={() => this.setState({ needNavigation: this.state.isUnitItemClicked ? true : false })}
                                                disabled={showIcons.deleteIcon ? '' : 'disabled'}>&nbsp;
                                            </button>
                                        </React.Fragment>
                                    }
                                </li>
                            </ul>
                        </div>
                        <div className="orgTreeViewOuter">
                            <div className="treeViewTable">
                                <div className="treeViewHeader">
                                    {levelHeader}
                                </div>
                                <div className="treeViewBody">
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                ref={this.treeData.virtualScrollRef}
                                                width={width || 100}
                                                height={height || 100}
                                                overscanRowCount={this.treeData.virtualScrollBufferRowCount}
                                                rowCount={flattenTreeData.length}
                                                rowHeight={this.getTreeViewRowHeight}
                                                rowRenderer={this.treeViewRowRenderer} />
                                        )}
                                    </AutoSizer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabIndex="-1" role="dialog" aria-labelledby=" confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md loginModal">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    {
                                        newLinkCreated
                                            ? <FormattedMessage id="KC1771" values={{ elementName: isUnitItemClicked ? unit.UNITID : link.group_name }} />
                                            : (
                                                isUnitItemClicked
                                                    ? <FormattedMessage id="KC1772" values={{ unit: unit.UNITID }} />
                                                    : <FormattedMessage id="KC1773" values={{ category: link.category_name, group: link.group_name }} />
                                            )
                                    }
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0580' /></button>
                                <button className="btn btn-secondary deleteYesBtn" data-dismiss="modal"
                                    onClick={() => { this.deleteClickHandler() }} type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Active Status Modal Toggle starts  */}
                {/* <div className="modal fade confirmationDeleteModal  activeStatusModal" data-keyboard="true" data-backdrop="static" id="activeStatusModal" tabIndex="-1" role="dialog" aria-labelledby="activeStatusModal">
                    <div className="modal-dialog modal-md loginModal">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    {Activatestatus ? <FormattedMessage id={Activatestatus} /> : ''}
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0580' /></button>
                                <button className="btn btn-secondary deleteYesBtn" data-dismiss="modal"
                                    onClick={() => { this.confirmClickHandler() }} type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* Active Status Modal Toggle ends  */}

                {/* {Heading DoubleClick Confirmation Modal } */}
                <div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="doubleClickModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="popupHeader">
                                    <p><FormattedMessage id='KC1095' /></p>
                                    <p><FormattedMessage id='KC1094' /></p>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button" onClick={this.cancelRename}><FormattedMessage id='KC0021' /></button>
                                <button className="btn btn-secondary deleteYesBtn" data-dismiss="modal" data-toggle="modal" data-target="#secondModalBox1" onClick={this.confirmRename}><FormattedMessage id='KC0558' /> </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {Heading DoubleClick Confirmation Modal } */}

                {/* Modal for Drag and drop */}
                <div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="dragDropModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="popupHeader">
                                    <p>{dragDropMessage ? <FormattedMessage id={dragDropMessage} /> : ''}</p>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0021' /></button>
                                <button className="btn btn-secondary deleteYesBtn" data-dismiss="modal" onClick={() => this.postDragDrop()}><FormattedMessage id='KC0558' /> </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal for drag and dropd end */}

                {/* Second ConfirmBox Open */}
                <div className="modal fade confirmationDeleteModal confirmationSecond" data-keyboard="true" data-backdrop="static" id="secondModalBox1" tabindex="-1" role="dialog" aria-labelledby="secondModalBoxLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmationYesNo">
                                    <h5 className="text-center "><FormattedMessage id='KC1093' /></h5>
                                    <div className="borbottom"></div>
                                    <div className="modalFormWrapper">
                                        <div className="modalForm">
                                            <ul>
                                                <li>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder={formatMessage({ id: 'KC0241' })} value={newNodeName} onChange={(e) => this.enterNodeTitleName(e.target.value)} />
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button" onClick={this.canceledRename}><FormattedMessage id='KC0021' /></button>
                                <button className={newNodeName.length > 0 ? "btn btn-secondary deleteYesBtn" : "btn btn-secondary deleteYesBtn disabled"} id="secondModalBox" data-dismiss="modal" type="button" onClick={this.renamedNodeName}  ><FormattedMessage id='KC0558' /> </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Second ConfirmBox end */}
                {/* Info Model */}
                <div className="modal fade infoModal" data-keyboard="true" data-backdrop="static" id="detailsInfoModal" tabindex="-1" role="dialog" aria-labelledby="detailsInfoModalLabel">
                    <div className="modal-dialog modal-md infoModal">
                        <div className="modal-content">
                            <div className="modal-header modal-header-danger">
                                <FormattedMessage id='KC0138' /> <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                            </div>
                            <div className="modal-body">
                                <div className="detailsInfoContent">
                                    <p>{dragDropInfoMessage ? <FormattedMessage id={dragDropInfoMessage} /> : ''}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Info Model Ends */}
                {/* <!-- Unit Node Modal --> */}
                {this.loadModalForUnit(unit, assetNumber, disableEdit, isSmartTagAccess, toggleUISwitch, formatMessage)}
                {/* <!-- //Unit Node Modal --> */}
                {/* <!-- Confirmation Modal --> */}
                {/* {this.loadSuccessChanges()} */}
                { /* <!-- Confirmation Modal --> */}
                {/* <!-- Customer Node Modal --> */}
                {this.loadModalForCustomer(link, showUserList, OrganizationSubElementCountInfo, withTernaryImageLoad, disableEdit, formatMessage)}
                {/* <!-- //Customer Node Modal --> */}
                {/* <!-- Location Node Modal --> */}
                {this.loadModalForLocation(link, street, city, zipcode, country_name, formatMessage, countyData, showUserList, OrganizationSubElementCountInfo, OrganizationLocationTypesInfo, disableEdit, organizationAuthirizedFeature, isSmartTagAccess, formatMessage)}
                {/* <!-- //Location Node Modal --> */}
                {/* <!-- Within Location Node Modal --> */}
                {this.loadModalForWithinLocation(link, withTernaryImageLoad, showUserList, OrganizationSubElementCountInfo, disableEdit, isSmartTagAccess, formatMessage)}
                {/* <!-- //Within Location Node Modal --> */}
                {/*<!-- Country Node Modal --> */}
                {this.loadModalForCountry(link, withTernaryImageLoad, showUserList, OrganizationSubElementCountInfo, disableEdit, isSmartTagAccess, formatMessage)}
                {/* <!-- //Country Node Modal -->*/}
                {/*<!-- Chain Node Modal --> */}
                {this.loadModalForChain(link, withTernaryImageLoad, showUserList, OrganizationSubElementCountInfo, disableEdit, isSmartTagAccess, formatMessage)}
                {/* <!-- //Chain Node Modal --> */}
                {/* <!-- ST Details Modal --> */}
                {showpopup && showpopup.length ? this.loadTagPopup(showpopup, type, formatMessage) : null}
                {/* <!-- //ST Details Modal --> */}
                {/* <!-- Unassign Unit Modal --> */}
                <div class="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="unitUnAssignModal" tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="contentDiv">
                                    <p><FormattedMessage id='KC2347' /></p>
                                    <p><FormattedMessage id='KC2348' /></p>
                                </div>
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button class="btn btn-secondary deleteNoBtn" data-toggle="modal" onClick={() => {
                                    if (this.state.closedModelRef === "#unitNodeModal") {
                                        $('#unitNodeModal').modal('show');
                                    }
                                }} data-dismiss="modal" type="button">Cancel</button>
                                <button class="btn btn-secondary deleteYesBtn" data-dismiss="modal" type="button" onClick={() => {
                                    this.deleteClickHandler(true, this.state.needNavigation);
                                }}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Unassign Unit --> */}
                {/* <!-- Discard Confirmation Modal --> */}
                <div class="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="unitDiscardModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="deleteConfirmation">
                                    <FormattedMessage id='KC2346' />
                                </div>
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button class="btn btn-secondary deleteNoBtn" data-toggle="modal" data-target={this.state.closedModelRef} data-dismiss="modal" type="button"><FormattedMessage id='KC0021' /></button>
                                <button class="btn btn-secondary deleteYesBtn" data-dismiss="modal" type="button" onClick={() => {
                                    if (this.state.closedModelRef === "#unitNodeModal") {
                                        this.treeData.activeNode = null;
                                        this.resetState();
                                        this.onOrgCheckBoxChange(unit)
                                    } else {
                                        this.onDeleteClickHandler()
                                    }
                                }}><FormattedMessage id='KC0777'/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Org tag */}
                {this.state.showOrgPopup ? <TagOrgModal details={showpopup && showpopup.length ? showpopup[0] : {}} /> : null}
                {this.state.showStPopUp ? <OrgTagsDetail closeSTModel={() => this.setState({ showStPopUp: false })} nodeDet={this.state.link} unitDet={this.state.unit} isUnit={this.state.isUnitItemClicked} formBreadCrum={this.formBreadCrum} /> : ""}

                {/* <div class="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="deActivateModal" tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="confirmMContententD">
                                    <div class="h3Title"><FormattedMessage id='KC2456' /></div>
                                    {toggleSubscription.subscription === 'YES' ? <p>This unit is under monthly subscription and it will <br /> become inactive from 1st day of next month.</p> : toggleSubscription.subscription === 'PREPAID' ? <p>This unit is under free trial subscription and it will <br /> become inactive from {moment(toggleSubscription.unit_prepaid_end_date).format(subscriptionManagementDateFormatter)}.</p> : ''}
                                </div>
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button class="btn confirmYes" data-dismiss="modal" type="button"><FormattedMessage id="KC0021" /></button>
                                <button class="btn confirmNo" data-dismiss="modal" type="button" onClick={() => putActiveDeactive(toggleSubscription)}><FormattedMessage id="KC0558" /></button>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <!-- Deactivate unit --> */}
                <div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="deActivateModal" tabindex="-1" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmMContententD">
                                    <div className="h3Title"><FormattedMessage id='KC2456' /></div>
                                    {paymentStatus === 1 ? '' : unit?.chargeable_status === 'YES' ? <p><FormattedMessage id='KC2508' /> <br /> <FormattedMessage id='KC2509' /></p> : unit?.chargeable_status === 'PREPAID' ? <p><FormattedMessage id='KC2510' /> <br /> <FormattedMessage id='KC2511' values={{ date: unit?.unit_prepaid_end_date ? moment.GetDateTimeByFormat(unit?.unit_prepaid_end_date, subscriptionManagementDateFormatter) : ' - ' }} /></p> : ''}
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn confirmYes" data-dismiss="modal" type="button"><FormattedMessage id="KC0021" /></button>
                                <button className="btn confirmNo" data-dismiss="modal" type="button" onClick={() => {
                                    this.setState({ unitToggleChange: false }, () => {
                                        this.onsubmitClick()
                                    })
                                }}><FormattedMessage id="KC0558" /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Deactivate unit -->
            <!-- Activate Unit --> */}
                <div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="unitActivateModal" tabindex="-1" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmMContententD">
                                    <div className="h3Title">{!isPaymentDetailAvailable ? <FormattedMessage id='KC2494' /> : isPaymentDetailAvailable && (unit?.chargeable_status === 'PREPAID' || unit?.chargeable_status === 'YES') && isValidDate ? <FormattedMessage id='KC2475' /> : <FormattedMessage id='KC2457' />} {!isPaymentDetailAvailable ? <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> : null}</div>
                                    <p>{!isPaymentDetailAvailable ? <FormattedMessage id='KC2507' /> : isPaymentDetailAvailable && (unit?.chargeable_status === 'PREPAID' || unit?.chargeable_status === 'YES') && isValidDate ? <FormattedMessage id='KC2493' /> : ''}</p>
                                </div>
                            </div>
                            {!isPaymentDetailAvailable ?
                                <div className="modal-footer">
                                    <button className="btn confirmNo" data-dismiss="modal" type="button" onClick={() => this.props.history.push(`/subscription/payments`)}><FormattedMessage id="Add Payment details" /></button>
                                </div> : isPaymentDetailAvailable && (unit?.chargeable_status === 'PREPAID' || unit?.chargeable_status === 'YES') && isValidDate ?
                                    <div className="modal-footer textAlignCenter">
                                        <button className="btn confirmYes" data-dismiss="modal" type="button" onClick={() => {
                                            this.setState({ unitToggleChange: false }, () => {
                                                this.onsubmitClick()
                                            })
                                        }}><FormattedMessage id="KC0611" /></button>
                                        <button className="btn confirmNo" data-dismiss="modal" type="button"><FormattedMessage id="KC0580" /></button>
                                    </div> :
                                    <div className="modal-footer textAlignCenter">
                                        <button className="btn confirmYes" data-dismiss="modal" type="button"><FormattedMessage id="KC0021" /></button>
                                        <button className="btn confirmNo" data-dismiss="modal" type="button" onClick={() => {
                                            this.setState({ unitToggleChange: false }, () => {
                                                this.onsubmitClick()
                                            })
                                        }}><FormattedMessage id="KC0558" /></button>
                                    </div>}
                        </div>
                    </div>
                </div>
                {/* <!-- //Activate Unit --> */}
            {/* <!-- Activate Unit -->  */}
                <div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="unitActivateModalWithoutPayment" tabindex="-1" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmMContententD">
                                    <div className="h3Title"><FormattedMessage id='KC2457' /></div>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn confirmYes" data-dismiss="modal" type="button"><FormattedMessage id="KC0021" /></button>
                                <button className="btn confirmNo" data-dismiss="modal" type="button" onClick={() => {
                                    this.setState({ unitToggleChange: false }, () => {
                                        this.onsubmitClick()
                                    })
                                }}><FormattedMessage id="KC0558" /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Activate Unit --> */}

                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerName="Organization Management" headerNameStringID="KC0519" activeClass="orgznManagementNav" filterKey={appconstants.orgManagementFilter} history={this.props.history} />
            </div >
        )
    }

    hanleCheckboxForOrgTag = (id) => {
        let tagArr = this.state.selectedSmartOrgTag;
        if (tagArr.includes(id)) {
            const index = tagArr.findIndex((i) => i === id);
            tagArr.splice(index, 1);
        } else {
            tagArr.push(id);
        }
        this.setState({ selectedSmartOrgTag: tagArr, isChangeHappened: true })
    }

    hanleCheckboxForUnitTag = (id) => {
        let tagArr = this.state.selectedSmartUnitTag;
        if (tagArr.includes(id)) {
            const index = tagArr.findIndex((i) => i === id);
            tagArr.splice(index, 1);
        } else {
            tagArr.push(id);
        }
        this.setState({ selectedSmartUnitTag: tagArr, isChangeHappened: true })
    }

    loadModalForCountry = (link, withTernaryImageLoad, showUserList, OrganizationSubElementCountInfo, disableEdit, isSmartTagAccess, formatMessage) => {
        let countryDataList = this.state.orgSmartTagList;
        countryDataList = countryDataList && countryDataList.length > 0 ? countryDataList : [];
        const countrysearchedItemInFilter = countryDataList && countryDataList.filter(
            (tag) => {
                return tag.tagName.toLowerCase().indexOf(this.state.searchOrgSmartTag.toLowerCase()) !== -1;
            }
        );
        countryDataList = countrysearchedItemInFilter;
        countryDataList = countryDataList.sort((a, b) => (this.state.selectedSmartOrgTag.includes(a.id) && this.state.selectedSmartOrgTag.includes(b.id)) ? 0 : this.state.selectedSmartOrgTag.includes(a.id) ? -1 : this.state.selectedSmartOrgTag.includes(b.id) ? 1 : 0);
        return <div className="modal fade orgNodeModal" data-keyboard="true" id="countryNodeModal" tabindex="-1" role="dialog">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header modal-header-danger">
                        <span className="largeText">{link && link.fixed_group_name} {!this.state.newLinkCreated && <FormattedMessage id='KC0325' />}</span>
                        <span className="smallText">{link && link.dynasty ? this.formBreadCrum(link.dynasty) : ''}</span>
                        <button type="button" data-dismiss="modal" data-toggle="modal" data-target={this.state.isChangeHappened ? "#unitDiscardModal" : ""} onClick={() => !this.state.isChangeHappened ? [this.resetState(), this.onDeleteClickHandler()] : this.setState({ closedModelRef: '#countryNodeModal' })} className="btn cancel modalDiscard">&nbsp;</button>
                        <button type="button" className={`btn save ${disableEdit} ${link && link.group_name && link.group_name.trim() !== "" ? "" : "disabled"}`} data-dismiss="modal" data-toggle="modal" onClick={() => {
                            if (this.state.isChangeHappened) {
                                this.onsubmitClick()
                            } else {
                                this.resetState();
                            }
                        }}>&nbsp;</button>
                    </div>
                    <div className="modal-body">
                        <div className="orgNodeModalDiv">
                            <ul>
                                <li>
                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li>
                                                <div className="importImageDiv" onClick={() => disableEdit === "" ? $('#imgBrowseFile').trigger('click') : ''}>
                                                    <div className="importImage">
                                                        <a href="javascript:void(0)" className="menuBrowse" title="Camera"><img src={withTernaryImageLoad || require("../../../images/recipecreation/photoPlaceholder.png")} /></a>
                                                    </div>
                                                    {!withTernaryImageLoad || !link.imagePath || link.imagePath === "" ? <label><FormattedMessage id='KC1229' /></label> : null}
                                                </div>
                                            </li>
                                            <li>
                                                <input type="text" className={`form-control ${disableEdit}`} placeholder={formatMessage({ id: "KC0597" })} value={link && link.group_name} onChange={(e) => {
                                                    this.groupNameChangeHandler(e);
                                                    this.setState({ isChangeHappened: true })
                                                }} />
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li><FormattedMessage id='KC0534' /></li>
                                            <li className="smallLi">{showUserList && showUserList.length}</li>
                                            <li className="smallLi"><button type="button" className="btn stUnitsDetails" onClick={() => this.setState({ showTotalElements: false, showAssignedUsers: true })}>&nbsp;</button></li>
                                        </ul>
                                        <ul>
                                            <li><FormattedMessage id='KC2349' /></li>
                                            <li className="smallLi">{OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.totalSubElementCount}</li>
                                            <li className="smallLi"><button type="button" className="btn stUnitsDetails" onClick={() => this.setState({ showTotalElements: true, showAssignedUsers: false })}>&nbsp;</button></li>
                                        </ul>
                                    </div>

                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li><button type="button" data-toggle="modal" data-target="#confirmationDeleteModal" className={`btn DeleteNodeBtn ${disableEdit}`} onClick={() => {
                                                $('#countryNodeModalD').modal('hide');
                                                $('#countryNodeModal').modal('hide');
                                            }}><FormattedMessage id='KC2350' /></button></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    {this.state.orgSmartTagList && this.state.orgSmartTagList.length && !this.state.showAssignedUsers && !this.state.showTotalElements ? <div className="smartTagsList">
                                        <div className="STunitsTable">
                                            <ul className="tHead">
                                                <li>
                                                    <div className="tableSerarchBar">
                                                        <input className="form-control tagsSearch" type="text" value={this.state.searchOrgSmartTag} placeholder={formatMessage({ id: "KC2618" })} onChange={(e) => this.setState({ searchOrgSmartTag: e.target.value })} />
                                                    </div>
                                                </li>
                                                <li><FormattedMessage id='KC0325' /></li>
                                                <li><FormattedMessage id='KC2131' /></li>
                                            </ul>
                                        </div>
                                        <div className="STunitsTableSrlBar">
                                            <div className="STunitsTable">
                                                {countryDataList && countryDataList.length ? countryDataList.map((org, index) => {
                                                    const isChecked = this.state.selectedSmartOrgTag && this.state.selectedSmartOrgTag.length && this.state.selectedSmartOrgTag.includes(org.id) ? true : false;
                                                    return (
                                                        <ul key={index} className={`tBody ${isChecked ? 'activeRow' : ''}`}>
                                                            <li><div className="tagTypeLocation"><span>{org.tagName}</span></div></li>
                                                            <li><button type="button" className="btn stUnitsDetails" onClick={() => {
                                                                let smartTagObj = JSON.parse(localStorage.getItem('smartTagInfo'));
                                                                smartTagObj['selectedRow'] = org;
                                                                localStorage.setItem('smartTagInfo', JSON.stringify(smartTagObj));
                                                                let showpopup = [];
                                                                showpopup.push(org);
                                                                this.setState({ showpopup, showOrgPopup: true, type: 'orgList' }, () => {
                                                                    $('#stUnitDetailsModal').modal('show');
                                                                });
                                                            }} >&nbsp;</button></li>
                                                            <li>
                                                                <div className={`customCheckbox customCheckboxWOlabel ${disableEdit}`}>
                                                                    <input id={org.tagName + 'country'} name={org.tagName + 'country'} type="checkbox" checked={isChecked} onChange={(e) => this.hanleCheckboxForOrgTag(org.id)} />
                                                                    <label for={org.tagName + 'country'}></label>
                                                                </div>
                                                            </li>
                                                        </ul>);
                                                }) : <div className='alignmentCenter'><FormattedMessage id="KC0205" /></div>}
                                            </div>
                                        </div>

                                    </div> : null}
                                    {this.state.showTotalElements ?
                                        <div className="smartTagsList">
                                            <div class="totalSubElements active" id="totalSubElementsM1">
                                                <button type="button" id="closeBtnTSEM1" class="btn totalSubElementsClose" onClick={() => this.setState({ showTotalElements: false, showAssignedUsers: this.state.orgSmartTagList && this.state.orgSmartTagList.length ? false : true, searchOrgSmartTag: '' })}>&nbsp;</button>
                                                <div class="STaUsersTable">
                                                    <ul class="tHead">
                                                        <li class="tHeadTitle"><FormattedMessage id="KC2349" />: {OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.totalSubElementCount}</li>
                                                        <li>&nbsp;</li>
                                                    </ul>
                                                </div>
                                                <div class="TSESrlBar" dangerouslySetInnerHTML={{ __html: this.formElements(OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.subElementCount) }} />
                                            </div>
                                        </div>
                                        : null}
                                    {(this.state.showAssignedUsers || !this.state.orgSmartTagList || this.state.orgSmartTagList.length === 0) && !this.state.showTotalElements ?
                                        <div className="smartTagsList">
                                            <div class="assignedUsersDiv customerV" >
                                                {this.state.orgSmartTagList && this.state.orgSmartTagList.length ? <button type="button" id="closeBtnM1" class="btn assignedUsersClose" onClick={() => this.setState({ showAssignedUsers: false, showTotalElements: false, searchOrgSmartTag: '' })}>&nbsp;</button> : null}
                                                <div class="STaUsersTable">
                                                    <ul class="tHead">
                                                        <li><FormattedMessage id="KC0534" /></li>
                                                        <li><FormattedMessage id="KC0588" /></li>
                                                    </ul>
                                                </div>
                                                <div class="STaUsersTableSrlBar">
                                                    <div class="STaUsersTable">
                                                        {showUserList && showUserList.length ? showUserList.map((user, index) => {
                                                            return (<ul key={index} class="tBody">
                                                                <li>{user.name}</li>
                                                                <li>{user.roleName}</li>
                                                            </ul>)
                                                        }) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }


    handleManualExpandCollapse = (levelID, data, makeCollapse) => {
        _.forEach(data, item => {
            _.forEach(item, node => {
                if (node && node.category_id == levelID && node.child_count > 0 && makeCollapse) {
                    if (!this.treeData.collapsedNodes[node.cust_gp_id]) {
                        this.treeData.collapsedNodes[node.cust_gp_id] = true;
                        this.treeData.expand[node.level_id] = true;
                    } else {
                        delete this.treeData.collapsedNodes[node.cust_gp_id];
                        delete this.treeData.expand[node.level_id];
                    }
                }
            })
        })
        if (this.treeData && this.treeData.virtualScrollRef && this.treeData.virtualScrollRef.current && this.treeData.virtualScrollRef.current.recomputeRowHeights) {
            this.treeData.virtualScrollRef.current.recomputeRowHeights();
        }
    }


    loadTagPopup(showpopup, type, formatMessage) {
        return <div className="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="stUnitDetailsModal" tabindex="-1" role="dialog" aria-labelledby="stUnitDetailsModalLabel">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header modal-header-danger">
                        <FormattedMessage id="KC2312" />
                    </div>
                    <div className="modal-body">
                        {showpopup && showpopup.map((item, i) => <div className="reportDetailsD">
                            <div className="createdEditedDetailsO">
                                <ul className="tHead">
                                    <li>
                                        <div className={type === "orgList" ? "tagTypeLocation" : "tagTypeUnit"}><span>{item.tagName}</span></div>
                                    </li>
                                </ul>
                            </div>
                            <h6><FormattedMessage id="KC0195" /></h6>
                            <p>{item.description ? item.description : 'NA'}</p>
                            <div className="createdEditedDetailsO">
                                <ul className="tHead">
                                    <li>
                                        <p><FormattedMessage id="KC2313" /> <button type="button" onClick={() => $('#stUnitDetailsModal').modal('hide')} data-toggle="modal" data-target="#orgViewModal" className={(item.allcgids && item.allcgids.length === 0) || (item.allunitIds && item.allunitIds.length === 0) ? "orgViewIcon btn disabled" : "orgViewIcon btn"}><img src={require('../../../images/icons/iconUnitSelection.svg').default} /></button> {type === 'orgList' ? formatMessage({ id: "KC2306" }) : formatMessage({ id: "KC2307" })}</p>
                                    </li>
                                    {type === 'orgList' ? "" :
                                        <li>
                                            <Link to='#' onClick={() => this.downloadUnitList(item.tagName)} className="downloadListof"><FormattedMessage id="KC2314" /></Link>
                                        </li>}
                                </ul>
                            </div>
                            {(item.allcgids && item.allcgids.length === 0) || (item.allunitIds && item.allunitIds.length === 0) ? <p class="notUsed"><FormattedMessage id='KC2357' /></p> : null}
                            <div className="createdEditedDetailsO">
                                <ul className="tHead">
                                    <li><FormattedMessage id="KC0589" /></li>
                                    <li><FormattedMessage id="KC1278" /></li>
                                </ul>
                                <ul className="tBody">
                                    <li>
                                        <div className="createdEditedDetails">
                                            <ul>
                                                <li><FormattedMessage id="KC0241" />:</li>
                                                <li>{item.createdByName ? item.createdByName : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0136" />:</li>
                                                <li>{item.createdBy ? item.createdBy : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0298" />:</li>
                                                <li>{item.createdAt ? moment.GetDateTimeByFormat(item.createdAt, 'LLL') : ''}</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="createdEditedDetails">
                                            <ul>
                                                <li><FormattedMessage id="KC0241" />:</li>
                                                <li>{item.updatedByName ? item.updatedByName : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0136" />:</li>
                                                <li>{item.updatedBy ? item.updatedBy : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0298" />:</li>
                                                <li>{item.UpdatedAt ? moment.GetDateTimeByFormat(item.UpdatedAt, 'LLL') : ''}</li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>)}
                    </div>
                    <div className="modal-footer textAlignCenter">
                        <button className="btn btn-secondary okBtn" data-dismiss="modal" onClick={() => this.setState({ showpopup: [], showOrgPopup: false })} type="button"><FormattedMessage id="KC0777" /></button>
                    </div>
                </div>
            </div>
        </div >;
    }

    loadModalForWithinLocation(link, withTernaryImageLoad, showUserList, OrganizationSubElementCountInfo, disableEdit, isSmartTagAccess, formatMessage) {
        let dataList = this.state.orgSmartTagList;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        const searchedItemInFilter = dataList && dataList.filter(
            (tag) => {
                return tag.tagName.toLowerCase().indexOf(this.state.searchOrgSmartTag.toLowerCase()) !== -1;
            }
        );
        dataList = searchedItemInFilter;
        dataList = dataList.sort((a, b) => (this.state.selectedSmartOrgTag.includes(a.id) && this.state.selectedSmartOrgTag.includes(b.id)) ? 0 : this.state.selectedSmartOrgTag.includes(a.id) ? -1 : this.state.selectedSmartOrgTag.includes(b.id) ? 1 : 0);
        return <div className="modal fade orgNodeModal" data-keyboard="true" id="withinLocationNodeModal" tabindex="-1" role="dialog">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header modal-header-danger">
                        <span className="largeText">{link && link.fixed_group_name} {!this.state.newLinkCreated && <FormattedMessage id='KC0325' />}</span>
                        <span className="smallText">{link && link.dynasty ? this.formBreadCrum(link.dynasty) : ''}</span>
                        <button type="button" data-dismiss="modal" data-toggle="modal" data-target={this.state.isChangeHappened ? "#unitDiscardModal" : ""} onClick={() => !this.state.isChangeHappened ? [this.resetState(), this.onDeleteClickHandler()] : this.setState({ closedModelRef: '#withinLocationNodeModal' })} className="btn cancel modalDiscard">&nbsp;</button>
                        <button type="button" className={`btn save ${disableEdit} ${link && link.group_name && link.group_name.trim() !== "" ? "" : "disabled"}`} data-dismiss="modal" data-toggle="modal" onClick={() => {
                            if (this.state.isChangeHappened) {
                                this.onsubmitClick()
                            } else {
                                this.resetState();
                            }
                        }}>&nbsp;</button>
                    </div>
                    <div className="modal-body">
                        <div className="orgNodeModalDiv">
                            <ul>
                                <li>
                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li>
                                                <div className="imgOuterDiv">
                                                    <img src={withTernaryImageLoad || require("../../../images/recipecreation/photoPlaceholder.png")} />
                                                </div>
                                            </li>
                                            <li>
                                                <input type="text" className="form-control" placeholder={formatMessage({ id: "KC0597" })} value={link && link.group_name} onChange={(e) => {
                                                    this.groupNameChangeHandler(e);
                                                    this.setState({ isChangeHappened: true })
                                                }} />
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li><FormattedMessage id='KC0534' /></li>
                                            <li className="smallLi">{showUserList && showUserList.length}</li>
                                            <li className="smallLi"><button type="button" className="btn stUnitsDetails" onClick={() => this.setState({ showTotalElements: false, showAssignedUsers: true })}>&nbsp;</button></li>
                                        </ul>
                                        <ul>
                                            <li><FormattedMessage id='KC2349' /></li>
                                            <li className="smallLi">{OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.totalSubElementCount}</li>
                                            <li className="smallLi"><button type="button" className="btn stUnitsDetails" onClick={() => this.setState({ showTotalElements: true, showAssignedUsers: false })}>&nbsp;</button></li>
                                        </ul>
                                    </div>

                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li><button type="button" data-toggle="modal" data-target="#confirmationDeleteModal" className={`btn DeleteNodeBtn ${disableEdit}`} onClick={() => {
                                                $('#withinLocationNodeModalD').modal('hide');
                                                $('#withinLocationNodeModal').modal('hide');
                                            }}><FormattedMessage id='KC2350' /></button></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    {this.state.orgSmartTagList && this.state.orgSmartTagList.length && !this.state.showAssignedUsers && !this.state.showTotalElements ? <div className="smartTagsList">
                                        <div className="STunitsTable">
                                            <ul className="tHead">
                                                <li>
                                                    <div className="tableSerarchBar">
                                                        <input className="form-control tagsSearch" type="text" value={this.state.searchOrgSmartTag} placeholder={formatMessage({ id: "KC2618" })} onChange={(e) => this.setState({ searchOrgSmartTag: e.target.value })} />
                                                    </div>
                                                </li>
                                                <li><FormattedMessage id='KC0325' /></li>
                                                <li><FormattedMessage id='KC2131' /></li>
                                            </ul>
                                        </div>
                                        <div className="STunitsTableSrlBar">
                                            <div className="STunitsTable">
                                                {dataList && dataList.length ? dataList.map((org, index) => {
                                                    const isChecked = this.state.selectedSmartOrgTag && this.state.selectedSmartOrgTag.length && this.state.selectedSmartOrgTag.includes(org.id) ? true : false;
                                                    return (
                                                        <ul key={index} className={`tBody ${isChecked ? 'activeRow' : ''}`}>
                                                            <li><div className="tagTypeLocation"><span>{org.tagName}</span></div></li>
                                                            <li><button type="button" className="btn stUnitsDetails" onClick={() => {
                                                                let smartTagObj = JSON.parse(localStorage.getItem('smartTagInfo'));
                                                                smartTagObj['selectedRow'] = org;
                                                                localStorage.setItem('smartTagInfo', JSON.stringify(smartTagObj));
                                                                let showpopup = [];
                                                                showpopup.push(org);
                                                                this.setState({ showpopup, showOrgPopup: true, type: 'orgList' }, () => {
                                                                    $('#stUnitDetailsModal').modal('show');
                                                                });
                                                            }} >&nbsp;</button></li>
                                                            <li>
                                                                <div className={`customCheckbox customCheckboxWOlabel ${disableEdit}`}>
                                                                    <input id={org.tagName + 'wlocation'} name={org.tagName + 'wlocation'} type="checkbox" checked={isChecked} onChange={(e) => this.hanleCheckboxForOrgTag(org.id)} />
                                                                    <label for={org.tagName + 'wlocation'}></label>
                                                                </div>
                                                            </li>
                                                        </ul>);
                                                }) : <div className='alignmentCenter'><FormattedMessage id="KC0205" /></div>}
                                            </div>
                                        </div>

                                    </div> : null}
                                    {this.state.showTotalElements ?
                                        <div className="smartTagsList">
                                            <div class="totalSubElements active" id="totalSubElementsM1">
                                                <button type="button" id="closeBtnTSEM1" class="btn totalSubElementsClose" onClick={() => this.setState({ showTotalElements: false, showAssignedUsers: this.state.orgSmartTagList && this.state.orgSmartTagList.length ? false : true, searchOrgSmartTag: '' })}>&nbsp;</button>
                                                <div class="STaUsersTable">
                                                    <ul class="tHead">
                                                        <li class="tHeadTitle"><FormattedMessage id="KC2349" />: {OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.totalSubElementCount}</li>
                                                        <li>&nbsp;</li>
                                                    </ul>
                                                </div>
                                                <div class="TSESrlBar" dangerouslySetInnerHTML={{ __html: this.formElements(OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.subElementCount) }} />
                                            </div>
                                        </div>
                                        : null}
                                    {(this.state.showAssignedUsers || !this.state.orgSmartTagList || this.state.orgSmartTagList.length === 0) && !this.state.showTotalElements ?
                                        <div className="smartTagsList">
                                            <div class="assignedUsersDiv active" >
                                                {this.state.orgSmartTagList && this.state.orgSmartTagList.length ? <button type="button" id="closeBtnM1" class="btn assignedUsersClose" onClick={() => this.setState({ showAssignedUsers: false, showTotalElements: false, searchOrgSmartTag: '' })}>&nbsp;</button> : null}
                                                <div class="STaUsersTable">
                                                    <ul class="tHead">
                                                        <li><FormattedMessage id="KC0534" /></li>
                                                        <li><FormattedMessage id="KC0588" /></li>
                                                    </ul>
                                                </div>
                                                <div class="STaUsersTableSrlBar">
                                                    <div class="STaUsersTable">
                                                        {showUserList && showUserList.length ? showUserList.map((user, index) => {
                                                            return (<ul key={index} class="tBody">
                                                                <li>{user.name}</li>
                                                                <li>{user.roleName}</li>
                                                            </ul>)
                                                        }) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    formElements = (subElements) => {
        let divEle = '';
        for (let index = 0; index < subElements.length; index++) {
            const element = subElements[index];
            divEle = divEle + `<ol ${index === 0 ? 'class="organizational-chart"' : ''}><li><div>
                    <h2>${element.category_name}: <div class="countOfNode">${element.count}</div></h2>
                </div>`
        }
        for (let index = 0; index < subElements.length; index++) {
            divEle = divEle + `</li></ol>`
        }
        return divEle;
    }

    loadModalForLocation(link, street, city, zipcode, country_name, formatMessage, countyData, showUserList, OrganizationSubElementCountInfo, OrganizationLocationTypesInfo, disableEdit, organizationAuthirizedFeature, isSmartTagAccess) {
        let dataList = this.state.orgSmartTagList;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        const searchedItemInFilter = dataList && dataList.filter(
            (tag) => {
                return tag.tagName.toLowerCase().indexOf(this.state.searchOrgSmartTag.toLowerCase()) !== -1;
            }
        );
        dataList = searchedItemInFilter;
        dataList = dataList.sort((a, b) => (this.state.selectedSmartOrgTag.includes(a.id) && this.state.selectedSmartOrgTag.includes(b.id)) ? 0 : this.state.selectedSmartOrgTag.includes(a.id) ? -1 : this.state.selectedSmartOrgTag.includes(b.id) ? 1 : 0);
        let locationImage = this.state.customerGroupType && this.state.customerGroupType.name ? brandsLocationLogo.find(b => b.name === this.state.customerGroupType.name) : {};
        const isValid = link && link.group_name && link.group_name.trim() !== "" && street && street.trim() !== "" && country_name && country_name.trim() !== "" && zipcode && zipcode.trim() !== "" && street && street.trim() !== "" && city && city.trim() !== "";
        return <div className="modal fade orgNodeModal" data-keyboard="true" id="locationNodeModal" tabindex="-1" role="dialog">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header modal-header-danger">
                        <span className="largeText">{link && link.fixed_group_name} {!this.state.newLinkCreated && <FormattedMessage id='KC0325' />}</span>
                        <span className="smallText">{link && link.dynasty ? this.formBreadCrum(link.dynasty) : ''}</span>
                        <button type="button" data-dismiss="modal" data-toggle="modal" data-target={this.state.isChangeHappened ? "#unitDiscardModal" : ""} onClick={() => !this.state.isChangeHappened ? [this.resetState(), this.onDeleteClickHandler()] : this.setState({ closedModelRef: '#locationNodeModal' })} className="btn cancel modalDiscard">&nbsp;</button>
                        <button type="button" className={`btn save ${disableEdit} ${isValid ? "" : "disabled"}`} data-dismiss="modal" data-toggle="modal" onClick={() => {
                            if (this.state.isChangeHappened) {
                                this.onsubmitClick()
                            } else {
                                this.resetState();
                            }
                        }}>&nbsp;</button>
                    </div>
                    <div className="modal-body">
                        <div className="orgNodeModalDiv">
                            <ul>
                                <li>
                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li>
                                                <div className="imgOuterDiv">
                                                    <img src={locationImage && locationImage.img ? locationImage.img : require("../../../images/recipecreation/photoPlaceholder.png")} />
                                                </div>
                                            </li>
                                            <li>
                                                <input type="text" className={`form-control ${disableEdit}`} placeholder={formatMessage({ id: "KC0597" })} value={link && link.group_name} onChange={(e) => {
                                                    this.groupNameChangeHandler(e);
                                                    this.setState({ isChangeHappened: true })
                                                }} />
                                                <select className={organizationAuthirizedFeature.is_editable === 0 ? "form-control" : "form-control"} disabled={disableEdit}
                                                    value={this.state.customerGroupType.id} onChange={(event) => this.onCustomerGroupChange(event)}>
                                                    {OrganizationLocationTypesInfo && OrganizationLocationTypesInfo['customer_group_type'] && OrganizationLocationTypesInfo['customer_group_type'].map(
                                                        item => <option value={item.cust_gp_type_id}>{item.cust_type_name}</option>
                                                    )}

                                                </select>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li><FormattedMessage id='KC0051' /></li>
                                        </ul>
                                        <ul>
                                            <li><input type="text" className={`form-control ${disableEdit}`} placeholder = {formatMessage({id:'KC2621'})} onChange={(e) => this.onAddressChange(e, 'ADD1')} value={street || ""} /> </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <input type="text" className={`form-control streetAdd ${disableEdit}`} placeholder={formatMessage({id:'KC0098'})} value={city || ""} onChange={(e) => this.onAddressChange(e, 'ADD2')} />
                                                <input type="text" className={`form-control zipCode ${disableEdit}`} placeholder={formatMessage({id:'KC1508'})} value={zipcode || ""} onChange={(e) => this.onAddressChange(e, 'ADD3')} onKeyPress={(e) => this.removeSpacesOnChange(e)} />
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <select onChange={(e) => this.onCountryChange(e)} value={country_name} className={`form-control ${disableEdit}`}>
                                                    <option value="">{formatMessage({ id: 'KC1770' })}</option>
                                                    {countyData && countyData.map((item, i) => <option key={item + i}
                                                        value={item}>{item}</option>)}
                                                </select>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li><FormattedMessage id='KC0534' /></li>
                                            <li className="smallLi">{showUserList && showUserList.length}</li>
                                            <li className="smallLi"><button type="button" className="btn stUnitsDetails" onClick={() => this.setState({ showTotalElements: false, showAssignedUsers: true })}>&nbsp;</button></li>
                                        </ul>
                                        <ul>
                                            <li><FormattedMessage id='KC2349' /></li>
                                            <li className="smallLi">{OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.totalSubElementCount}</li>
                                            <li className="smallLi"><button type="button" className="btn stUnitsDetails" onClick={() => this.setState({ showTotalElements: true, showAssignedUsers: false })}>&nbsp;</button></li>
                                        </ul>
                                    </div>

                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li><button type="button" data-toggle="modal" data-target="#confirmationDeleteModal" className={`btn DeleteNodeBtn ${disableEdit}`} onClick={() => {
                                                $('#locationNodeModalD').modal('hide');
                                                $('#locationNodeModal').modal('hide');
                                            }}><FormattedMessage id='KC2350' /></button></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    {this.state.orgSmartTagList && this.state.orgSmartTagList.length && !this.state.showAssignedUsers && !this.state.showTotalElements ?
                                        <div className="smartTagsList">
                                            <div className="STunitsTable">
                                                <ul className="tHead">
                                                    <li>
                                                        <div className="tableSerarchBar">
                                                            <input className="form-control tagsSearch" type="text" value={this.state.searchOrgSmartTag} placeholder={formatMessage({ id: "KC2618" })} onChange={(e) => this.setState({ searchOrgSmartTag: e.target.value })} />
                                                        </div>
                                                    </li>
                                                    <li><FormattedMessage id='KC0325' /></li>
                                                    <li><FormattedMessage id='KC2131' /></li>
                                                </ul>
                                            </div>
                                            <div className="STunitsTableSrlBar">
                                                <div className="STunitsTable">
                                                    {dataList && dataList.length ? dataList.map((org, index) => {
                                                        const isChecked = this.state.selectedSmartOrgTag && this.state.selectedSmartOrgTag.length && this.state.selectedSmartOrgTag.includes(org.id) ? true : false;
                                                        return (
                                                            <ul key={index} className={`tBody ${isChecked ? 'activeRow' : ''}`}>
                                                                <li><div className="tagTypeLocation"><span>{org.tagName}</span></div></li>
                                                                <li><button type="button" className="btn stUnitsDetails" onClick={() => {
                                                                    let smartTagObj = JSON.parse(localStorage.getItem('smartTagInfo'));
                                                                    smartTagObj['selectedRow'] = org;
                                                                    localStorage.setItem('smartTagInfo', JSON.stringify(smartTagObj));
                                                                    let showpopup = [];
                                                                    showpopup.push(org);
                                                                    this.setState({ showpopup, showOrgPopup: true, type: 'orgList' }, () => {
                                                                        $('#stUnitDetailsModal').modal('show');
                                                                    });
                                                                }} >&nbsp;</button></li>
                                                                <li>
                                                                    <div className={`customCheckbox customCheckboxWOlabel ${disableEdit}`}>
                                                                        <input id={org.tagName + 'location'} name={org.tagName + 'location'} type="checkbox" checked={isChecked} onChange={(e) => this.hanleCheckboxForOrgTag(org.id)} />
                                                                        <label for={org.tagName + 'location'}></label>
                                                                    </div>
                                                                </li>
                                                            </ul>);
                                                    }) : <div className='alignmentCenter'><FormattedMessage id="KC0205" /></div>}
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {this.state.showTotalElements ?
                                        <div className="smartTagsList">
                                            <div class="totalSubElements active" id="totalSubElementsM1">
                                                <button type="button" id="closeBtnTSEM1" class="btn totalSubElementsClose" onClick={() => this.setState({ showTotalElements: false, showAssignedUsers: this.state.orgSmartTagList && this.state.orgSmartTagList.length ? false : true, searchOrgSmartTag: '' })}>&nbsp;</button>
                                                <div class="STaUsersTable">
                                                    <ul class="tHead">
                                                        <li class="tHeadTitle"><FormattedMessage id="KC2349" />: {OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.totalSubElementCount}</li>
                                                        <li>&nbsp;</li>
                                                    </ul>
                                                </div>
                                                <div class="TSESrlBar" dangerouslySetInnerHTML={{ __html: this.formElements(OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.subElementCount) }} />
                                            </div>
                                        </div>
                                        : null}
                                    {(this.state.showAssignedUsers || !this.state.orgSmartTagList || this.state.orgSmartTagList.length === 0) && !this.state.showTotalElements ?
                                        <div className="smartTagsList">
                                            <div class="assignedUsersDiv active" >
                                                {this.state.orgSmartTagList && this.state.orgSmartTagList.length ? <button type="button" id="closeBtnM1" class="btn assignedUsersClose" onClick={() => this.setState({ showAssignedUsers: false, showTotalElements: false, searchOrgSmartTag: '' })}>&nbsp;</button> : null}
                                                <div class="STaUsersTable">
                                                    <ul class="tHead">
                                                        <li><FormattedMessage id="KC0534" /></li>
                                                        <li><FormattedMessage id="KC0588" /></li>
                                                    </ul>
                                                </div>
                                                <div class="STaUsersTableSrlBar">
                                                    <div class="STaUsersTable">
                                                        {showUserList && showUserList.length ? showUserList.map((user, index) => {
                                                            return (<ul key={index} class="tBody">
                                                                <li>{user.name}</li>
                                                                <li>{user.roleName}</li>
                                                            </ul>)
                                                        }) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    loadSuccessChanges() {
        const { openSnackbar, closeSnackbar } = this.props;
        const { formatMessage } = this.props.intl;
        openSnackbar(formatMessage({ id: 'KC2351' }), snackbarClosingSecond);
        this.onDeleteClickHandler()
        // return <div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="changesConfirmationModal" tabindex="-1" role="dialog">
        //     <div className="modal-dialog modal-md">
        //         <div className="modal-content">
        //             <div className="modal-body">
        //                 <div className="confirmationDiv">
        //                     <FormattedMessage id='KC2351' />
        //                 </div>
        //             </div>
        //             <div className="modal-footer textAlignCenter">
        //                 <button className="btn btn-secondary confirmationOKBtn" id="nodeSaveConfirmation" data-dismiss="modal" type="button" onClick={() => this.onDeleteClickHandler()}><FormattedMessage id='KC0777' /></button>
        //             </div>
        //         </div>
        //     </div>
        // </div>;
    }

    loadModalForChain(link, withTernaryImageLoad, showUserList, OrganizationSubElementCountInfo, disableEdit, isSmartTagAccess, formatMessage) {
        let dataList = this.state.orgSmartTagList;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        const searchedItemInFilter = dataList && dataList.filter(
            (tag) => {
                return tag.tagName.toLowerCase().indexOf(this.state.searchOrgSmartTag.toLowerCase()) !== -1;
            }
        );
        dataList = searchedItemInFilter;
        dataList = dataList.sort((a, b) => (this.state.selectedSmartOrgTag.includes(a.id) && this.state.selectedSmartOrgTag.includes(b.id)) ? 0 : this.state.selectedSmartOrgTag.includes(a.id) ? -1 : this.state.selectedSmartOrgTag.includes(b.id) ? 1 : 0);
        return <div className="modal fade orgNodeModal" data-keyboard="true" id="chainNodeModal" tabindex="-1" role="dialog">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header modal-header-danger">
                        <span className="largeText">{link && link.fixed_group_name} {!this.state.newLinkCreated && <FormattedMessage id='KC0325' />}</span>
                        <span className="smallText">{link && link.dynasty ? this.formBreadCrum(link.dynasty) : ''}</span>
                        <button type="button" data-dismiss="modal" data-toggle="modal" data-target={this.state.isChangeHappened ? "#unitDiscardModal" : ""} onClick={() => !this.state.isChangeHappened ? [this.resetState(), this.onDeleteClickHandler()] : this.setState({ closedModelRef: '#chainNodeModal' })} className="btn cancel modalDiscard">&nbsp;</button>
                        <button type="button" className={`btn save ${disableEdit} ${link && link.group_name && link.group_name.trim() !== "" ? "" : "disabled"}`} data-dismiss="modal" data-toggle="modal" onClick={() => {
                            if (this.state.isChangeHappened) {
                                this.onsubmitClick()
                            } else {
                                this.resetState();
                            }
                        }}>&nbsp;</button>
                    </div>
                    <div className="modal-body">
                        <div className="orgNodeModalDiv">
                            <ul>
                                <li>
                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li>
                                                <div className="importImageDiv" onClick={() => disableEdit === "" ? $('#imgBrowseFile').trigger('click') : ""}>
                                                    <div className="importImage">
                                                        <a href="javascript:void(0)" className="menuBrowse" title="Camera"><img src={withTernaryImageLoad || require("../../../images/recipecreation/photoPlaceholder.png")} /></a>
                                                    </div>
                                                    {!withTernaryImageLoad || !link.imagePath || link.imagePath === "" ? <label><FormattedMessage id='KC1229' /></label> : null}
                                                </div>
                                            </li>
                                            <li>
                                                <input type="text" className={`form-control ${disableEdit}`} placeholder={formatMessage({ id: "KC0597" })} value={link && link.group_name} onChange={(e) => {
                                                    this.groupNameChangeHandler(e);
                                                    this.setState({ isChangeHappened: true })
                                                }} />
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li><FormattedMessage id='KC0534' /></li>
                                            <li className="smallLi">{showUserList && showUserList.length}</li>
                                            <li className="smallLi"><button type="button" className="btn stUnitsDetails" onClick={() => this.setState({ showTotalElements: false, showAssignedUsers: true })}>&nbsp;</button></li>
                                        </ul>
                                        <ul>
                                            <li><FormattedMessage id='KC2349' /></li>
                                            <li className="smallLi">{OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.totalSubElementCount}</li>
                                            <li className="smallLi"><button type="button" className="btn stUnitsDetails" onClick={() => this.setState({ showTotalElements: true, showAssignedUsers: false })}>&nbsp;</button></li>
                                        </ul>
                                    </div>

                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li><button type="button" data-toggle="modal" data-target="#confirmationDeleteModal" className={`btn DeleteNodeBtn ${disableEdit}`} onClick={() => {
                                                $('#chainNodeModalD').modal('hide');
                                                $('#chainNodeModal').modal('hide');
                                            }}><FormattedMessage id='KC2350' /></button></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    {this.state.orgSmartTagList && this.state.orgSmartTagList.length && !this.state.showAssignedUsers && !this.state.showTotalElements ?
                                        <div className="smartTagsList">
                                            <div className="STunitsTable">
                                                <ul className="tHead">
                                                    <li>
                                                        <div className="tableSerarchBar">
                                                            <input className="form-control tagsSearch" type="text" value={this.state.searchOrgSmartTag} placeholder={formatMessage({ id: "KC2618" })} onChange={(e) => this.setState({ searchOrgSmartTag: e.target.value })} />
                                                        </div>
                                                    </li>
                                                    <li><FormattedMessage id='KC0325' /></li>
                                                    <li><FormattedMessage id='KC2131' /></li>
                                                </ul>
                                            </div>
                                            <div className="STunitsTableSrlBar">
                                                <div className="STunitsTable">
                                                    {dataList && dataList.length ? dataList.map((org, index) => {
                                                        const isChecked = this.state.selectedSmartOrgTag && this.state.selectedSmartOrgTag.length && this.state.selectedSmartOrgTag.includes(org.id) ? true : false;
                                                        return (
                                                            <ul key={index} className={`tBody ${isChecked ? 'activeRow' : ''}`}>
                                                                <li><div className="tagTypeLocation"><span>{org.tagName}</span></div></li>
                                                                <li><button type="button" className="btn stUnitsDetails" onClick={() => {
                                                                    let smartTagObj = JSON.parse(localStorage.getItem('smartTagInfo'));
                                                                    smartTagObj['selectedRow'] = org;
                                                                    localStorage.setItem('smartTagInfo', JSON.stringify(smartTagObj));
                                                                    let showpopup = [];
                                                                    showpopup.push(org);
                                                                    this.setState({ showpopup, showOrgPopup: true, type: 'orgList' }, () => {
                                                                        $('#stUnitDetailsModal').modal('show');
                                                                    });
                                                                }} >&nbsp;</button></li>
                                                                <li>
                                                                    <div className={`customCheckbox customCheckboxWOlabel ${disableEdit}`}>
                                                                        <input id={org.tagName + 'chain'} name={org.tagName + 'chain'} type="checkbox" checked={isChecked} onChange={(e) => this.hanleCheckboxForOrgTag(org.id)} />
                                                                        <label for={org.tagName + 'chain'}></label>
                                                                    </div>
                                                                </li>
                                                            </ul>);
                                                    }) : <div className='alignmentCenter'><FormattedMessage id="KC0205" /></div>}
                                                </div>
                                            </div>
                                        </div> : null}
                                    {this.state.showTotalElements ?
                                        <div className="smartTagsList">
                                            <div class="totalSubElements active" id="totalSubElementsM1">
                                                <button type="button" id="closeBtnTSEM1" class="btn totalSubElementsClose" onClick={() => this.setState({ showTotalElements: false, showAssignedUsers: this.state.orgSmartTagList && this.state.orgSmartTagList.length ? false : true, searchOrgSmartTag: '' })}>&nbsp;</button>
                                                <div class="STaUsersTable">
                                                    <ul class="tHead">
                                                        <li class="tHeadTitle"><FormattedMessage id="KC2349" />: {OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.totalSubElementCount}</li>
                                                        <li>&nbsp;</li>
                                                    </ul>
                                                </div>
                                                <div class="TSESrlBar" dangerouslySetInnerHTML={{ __html: this.formElements(OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.subElementCount) }} />
                                            </div>
                                        </div>
                                        : null}
                                    {(this.state.showAssignedUsers || !this.state.orgSmartTagList || this.state.orgSmartTagList.length === 0) && !this.state.showTotalElements ?
                                        <div className="smartTagsList">
                                            <div class="assignedUsersDiv active" >
                                                {this.state.orgSmartTagList && this.state.orgSmartTagList.length ? <button type="button" id="closeBtnM1" class="btn assignedUsersClose" onClick={() => this.setState({ showAssignedUsers: false, showTotalElements: false, searchOrgSmartTag: '' })}>&nbsp;</button> : null}
                                                <div class="STaUsersTable">
                                                    <ul class="tHead">
                                                        <li><FormattedMessage id="KC0534" /></li>
                                                        <li><FormattedMessage id="KC0588" /></li>
                                                    </ul>
                                                </div>
                                                <div class="STaUsersTableSrlBar">
                                                    <div class="STaUsersTable">
                                                        {showUserList && showUserList.length ? showUserList.map((user, index) => {
                                                            return (<ul key={index} class="tBody">
                                                                <li>{user.name}</li>
                                                                <li>{user.roleName}</li>
                                                            </ul>)
                                                        }) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    loadModalForCustomer(link, showUserList, OrganizationSubElementCountInfo, withTernaryImageLoad, disableEdit, formatMessage) {
        return <div className="modal fade orgNodeModal" data-keyboard="true" id="customerNodeModal" tabindex="-1" role="dialog">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        {link && link.fixed_group_name} {!this.state.newLinkCreated && <FormattedMessage id='KC0325' />}
                        <button type="button" data-dismiss="modal" data-toggle="modal" data-target={this.state.isChangeHappened ? "#unitDiscardModal" : ""} onClick={() => !this.state.isChangeHappened ? [this.resetState(), this.onDeleteClickHandler()] : this.setState({ closedModelRef: '#customerNodeModal' })} className="btn cancel modalDiscard">&nbsp;</button>
                        <button type="button" className={`btn save ${disableEdit} ${link && link.group_name && link.group_name.trim() !== "" ? "" : "disabled"}`} data-dismiss="modal" data-toggle="modal" onClick={() => {
                            if (this.state.isChangeHappened) {
                                this.onsubmitClick()
                            } else {
                                this.resetState();
                            }
                        }}>&nbsp;</button>
                    </div>
                    <div className="modal-body">
                        <div className="orgNodeModalDiv">
                            <ul>
                                <li>
                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li>
                                                <div className="importImageDiv" onClick={() => disableEdit === "" ? $('#imgBrowseFile').trigger('click') : ''}>
                                                    <div className="importImage">
                                                        <a href="javascript:void(0)" className="menuBrowse" title="Camera"><img src={withTernaryImageLoad || require("../../../images/recipecreation/photoPlaceholder.png")} /></a>
                                                    </div>
                                                    {!withTernaryImageLoad || !link.imagePath || link.imagePath === "" ? <label><FormattedMessage id='KC1229' /></label> : null}
                                                </div>
                                            </li>
                                            <li>
                                                <input type="text" className={`form-control ${disableEdit}`} placeholder={formatMessage({ id: "KC0597" })} value={link && link.group_name} onChange={(e) => {
                                                    this.groupNameChangeHandler(e);
                                                    this.setState({ isChangeHappened: true })
                                                }} />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li><FormattedMessage id='KC0534' /></li>
                                            <li className="smallLi">{showUserList && showUserList.length}</li>
                                            {/* <li className="smallLi"><button type="button" className="btn stUnitsDetails"  onClick={()=>this.setState({ showTotalElements: false, showAssignedUsers: true })}>&nbsp;</button></li> */}
                                        </ul>
                                        <ul>
                                            <li><FormattedMessage id='KC2349' /></li>
                                            <li className="smallLi">{OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.totalSubElementCount}</li>
                                            <li className="smallLi"><button type="button" className="btn stUnitsDetails" onClick={() => this.setState({ showTotalElements: true, showAssignedUsers: false })}>&nbsp;</button></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    {this.state.showTotalElements ?
                                        <div className="smartTagsList">
                                            <div class="totalSubElements active" id="totalSubElementsM1">
                                                <button type="button" id="closeBtnTSEM1" class="btn totalSubElementsClose" onClick={() => this.setState({ showTotalElements: false, showAssignedUsers: true })}>&nbsp;</button>
                                                <div class="STaUsersTable">
                                                    <ul class="tHead">
                                                        <li class="tHeadTitle"><FormattedMessage id="KC2349" />: {OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.totalSubElementCount}</li>
                                                        <li>&nbsp;</li>
                                                    </ul>
                                                </div>
                                                <div class="TSESrlBar" dangerouslySetInnerHTML={{ __html: this.formElements(OrganizationSubElementCountInfo && OrganizationSubElementCountInfo.subElementCount) }} />
                                            </div>
                                        </div>
                                        : null}
                                    {!this.state.showTotalElements ?
                                        <div className="smartTagsList">
                                            <div class="assignedUsersDiv active" >
                                                <div class="STaUsersTable">
                                                    <ul class="tHead">
                                                        <li><FormattedMessage id="KC0534" /></li>
                                                        <li><FormattedMessage id="KC0588" /></li>
                                                    </ul>
                                                </div>
                                                <div class="STaUsersTableSrlBar">
                                                    <div class="STaUsersTable">
                                                        {showUserList && showUserList.length ? showUserList.map((user, index) => {
                                                            return (<ul key={index} class="tBody">
                                                                <li>{user.name}</li>
                                                                <li>{user.roleName}</li>
                                                            </ul>)
                                                        }) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    loadModalForUnit(unit, assetNumber, disableEdit, isSmartTagAccess, toggleUISwitch, formatMessage) {
        let dataList = this.state.unitSmartTagList;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        const searchedItemInFilter = dataList && dataList.filter(
            (tag) => {
                return tag.tagName.toLowerCase().indexOf(this.state.searchUnitSmartTag.toLowerCase()) !== -1;
            }
        );
        dataList = searchedItemInFilter;
        dataList = dataList.sort((a, b) => (this.state.selectedSmartUnitTag.includes(a.id) && this.state.selectedSmartUnitTag.includes(b.id)) ? 0 : this.state.selectedSmartUnitTag.includes(a.id) ? -1 : this.state.selectedSmartUnitTag.includes(b.id) ? 1 : 0);
        return <div className="modal fade orgNodeModal" data-keyboard="true" id="unitNodeModal" tabindex="-1" role="dialog">
            <div className={`modal-dialog ${this.state.unitSmartTagList && this.state.unitSmartTagList.length ? "modal-md" : "modal-sm"}`}>
                <div className="modal-content">
                    <div className="modal-header modal-header-danger">
                        <FormattedMessage id='KC1505' />
                        <button type="button" data-dismiss="modal" data-toggle="modal" data-target={this.state.isChangeHappened ? "#unitDiscardModal" : ""} onClick={() => !this.state.isChangeHappened ? this.resetState() : this.setState({ closedModelRef: '#unitNodeModal' })} className="btn cancel modalDiscard">&nbsp;</button>
                        <button type="button" className={`btn save ${disableEdit}`} data-dismiss="modal" data-toggle="modal" onClick={() => {
                            if (this.state.isChangeHappened) {
                                this.onsubmitClick()
                            } else {
                                this.resetState();
                            }
                        }}>&nbsp;</button>
                    </div>
                    <div className="modal-body">
                        <div className="orgNodeModalDiv">
                            <ul>
                                <li>
                                    <div className="orgNodeModalTable">
                                        <ul>
                                            <li>
                                                <div className="imgOuterDiv">
                                                    <img src={this.state.imagePreviewUrl || "images/unit/MerrychefConneX12.svg"} />
                                                </div>
                                            </li>
                                            <li> <FormattedMessage id='KC0105' />
                                                <input type="text" placeholder={formatMessage({ id: 'KC2361' })} className={`form-control ${disableEdit}`} value={assetNumber} onChange={(e) => this.editAssetFieldHandler(e)} />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="orgUnitModalTable">
                                        <ul>
                                            <li><FormattedMessage id='KC0104' /></li>
                                            <li>{unit ? unit.UNITID : ''}</li>
                                        </ul>
                                        <ul>
                                            <li><FormattedMessage id='KC0062' /></li>
                                            <li>{unit ? unit.BRAND_NAME : ''}</li>
                                        </ul>
                                        <ul>
                                            <li><FormattedMessage id='KC0064' /></li>
                                            <li>{unit ? unit.MODEL_NAME : ''}</li>
                                        </ul>
                                        <ul>
                                            <li><FormattedMessage id='KC0536' /></li>
                                            <li>
                                                {/* <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className={`btn btn-secondary ${this.state.unitActiveObject && this.state.unitActiveObject.activationStatus === "ACTIVE" ? "active" : "focus"} ${disableEdit}`} id="activeDeactive" onClick={(e) => this.onActivationChange(e)}>
                                                        <input type="checkbox" autocomplete="off" /> &nbsp;
                                                    </label>
                                                </div> */}
                                                <Link to="#" className="btn-group-toggle" >
                                                    {toggleUISwitch}
                                                </Link>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li><FormattedMessage id='KC2352' /></li>
                                            <li>
                                                <button type="button" className={`btn unassignNodeBtn ${disableEdit}`} data-dismiss="modal" data-toggle="modal" data-target="#unitUnAssignModal" onClick={() => this.setState({ needNavigation: true, closedModelRef: "#unitNodeModal" })}>&nbsp;</button>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                {this.state.unitSmartTagList && this.state.unitSmartTagList.length ? <li><div className="smartTagsList">
                                    <div className="STunitsTable">
                                        <ul className="tHead">
                                            <li>
                                                <div className="tableSerarchBar">
                                                    <input className="form-control tagsSearch" type="text" value={this.state.searchUnitSmartTag} placeholder={formatMessage({ id: "KC2618" })} onChange={(e) => this.setState({ searchUnitSmartTag: e.target.value })} />
                                                </div>
                                            </li>
                                            <li><FormattedMessage id='KC0325' /></li>
                                            <li><FormattedMessage id='KC2131' /></li>
                                        </ul>
                                    </div>
                                    <div className="STunitsTableSrlBar">
                                        <div className="STunitsTable">
                                            {dataList && dataList.length ? dataList.map((unit, index) => {
                                                const isChecked = this.state.selectedSmartUnitTag && this.state.selectedSmartUnitTag.length && this.state.selectedSmartUnitTag.includes(unit.id) ? true : false;
                                                return (
                                                    <ul key={index} className={`tBody ${isChecked ? 'activeRow' : ''}`}>
                                                        <li><div className="tagTypeUnit"><span>{unit.tagName}</span></div></li>
                                                        <li><button type="button" className="btn stUnitsDetails" onClick={async () => {
                                                            let smartTagObj = JSON.parse(localStorage.getItem('smartTagInfo'));
                                                            smartTagObj['selectedRow'] = unit;
                                                            await localStorage.setItem('smartTagInfo', JSON.stringify(smartTagObj));
                                                            let showpopup = [];
                                                            showpopup.push(unit);
                                                            this.setState({ showpopup, showOrgPopup: true, type: 'unitList' }, () => {
                                                                $('#stUnitDetailsModal').modal('show');
                                                            });
                                                        }} >&nbsp;</button></li>
                                                        <li>
                                                            <div className={`customCheckbox customCheckboxWOlabel ${disableEdit}`}>
                                                                <input id={unit.tagName + 'unitView'} name={unit.tagName + 'unitView'} type="checkbox" checked={isChecked} onChange={(e) => this.hanleCheckboxForUnitTag(unit.id)} />
                                                                <label for={unit.tagName + 'unitView'}></label>
                                                            </div>
                                                        </li>
                                                    </ul>);
                                            }) : <div className='alignmentCenter'><FormattedMessage id="KC0791" /></div>}
                                        </div>
                                    </div>
                                </div> </li> : null}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
};

function mapStateToProps(state) {
    return {
        OrganizationCategoryDetailsInfo: state.OrganizationCategoryDetailsInformation.OrganizationCategoryDetailsInformation,
        OrganizationLocationTypesInfo: state.OrganizationLocationTypesInformation.OrganizationLocationTypesInformation,
        OrganizationSubElementCountInfo: state.OrganizationSubElementCountInformation.OrganizationSubElementCountInformation,
        OrganizationUnitsDetailsInfo: state.OrganizationUnitsDetailsInformation.OrganizationUnitsDetailsInformation,
        deleteElementInfo: state.deleteElementInformation.deleteElementInformation,
        filterlistInformtion: state.filterModel,
        searchData: state.advanceSearch.searchData,
        selectedModels: state.filterInformation.selectedModels,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getOrganizationCategoryDetails,
        getOrganizationLocationTypes,
        getSelectedFilterList,
        getOrganizationSubElementCount,
        getOrganizationUnitsDetails,
        resetOrganizationSubElementCount,
        deleteOrganizationElement,
        searchVisible,
        searchText,
        clearSearchText
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withSnackbar(OrganizationManagement, snackBarOptions)));