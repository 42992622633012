import React, { PureComponent } from 'react';
import http from '../../../service/httpService';
import { BaseService, ReportManagement, UnitInfo } from '../../../service/api';
import { format, addDays } from 'date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { CUSTOMER_ID, STANDARDIZED_BRAND, BRAND_ID_VALUE } from '../../../utils/appConstants';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { UserBrandList } from '../../controls/userBrandList';
import { FormattedMessage } from 'react-intl';
import { reactIntl } from '../../../locale/locale-provider';
import { peakHourConfiguration } from './peakHourConfiguration';
import { compileConfiguration } from '../../DashboardRenderers/configuration-compiler';
import { apiDateFormat, encriptData, getDateRange } from '../../../utils/dashboardRenderer';
import { invokePostMethod } from '../../DashboardRenderers/dashboardRenderer';
import _ from 'lodash';
import { ungzip } from 'pako';

am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);

var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;

class PeakHoursChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      peakHourChartInformation: [],
      total_unit_count: 0,
      loading: true,
      filterType: "Month",
      peakHour: 0,
      sd: '',
      ed: '',
      startDate: '',
      brandsAvailable: false,
      endDate: '',
      sdmindate: todaysDate,
      sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
      edmaxdate: todaysDate,
      edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
      legendText: "",
      showBlueDot: false,
      datapickflag: false,
    }
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  async componentDidMount() {
    let chart = am4core.create("peakHoursDBChartdivAllLocations", am4charts.XYChart);
    const brandListArr = await JSON.parse(localStorage.getItem('availableUnitsBrand'));
    if (brandListArr && brandListArr.length > 0) {
      this.setState({
        brandsAvailable: true
      });
    }
  }

  handleChangeStart(date) {
    this.setState({
      startDate: date,
      datapickflag:true
    }, () => {
      let selectedtext = 'Date Range';
      var e = { target: { text: selectedtext } , nodeText: selectedtext};
      this.onDropDownHandler(e);
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
      datapickflag:true
    }, () => {
      let selectedtext = 'Date Range';
      var e = { target: { text: selectedtext } , nodeText: selectedtext};
      this.onDropDownHandler(e);
    });
  }

  onBrandSelection(e, type = '') {
    let brandid = 0;
    let legendText = "";

    brandid =  BRAND_ID_VALUE[e.target.text];
    legendText = e.target.text === "CONVOTHERM" ? "KC0093" : e.target.text === "CREM" ? "KC0176"
      : (e.target.text === "MERCO" || e.target.text === "FRYMASTER-UHCTHD") ? "KC0096" : "KC0095";

    this.setState({ 
      brand_id: brandid,
      brand_name: e.target.text,
      legendText: legendText
    });
    this.fetchData(brandid, type);
    if(e.target.text === "CREM"){
      this.getExcludeProductDetails(brandid);
    }
  }

  getExcludeProductDetails = async (brandid) => {
    const response = await http.get(UnitInfo.excludeProduct,
      {
        headers: {
          'Content-Type': 'application/json',
          'cid': CUSTOMER_ID,
          'brandid': brandid,
        },
        data: {}
      })
    let excludeProduct = response.data && response.data[0]
    let checklength = excludeProduct && excludeProduct.excludedProductList && excludeProduct.excludedProductList.length ? excludeProduct.excludedProductList.length :0;

    if (checklength > 0) {
      this.setState({
        showBlueDot: true,
      })
    }
  }

  formatPeakHourResult = async (peakHourData) => {
    try {
      // Products
      let products = []
      peakHourData.map(oneData => {
        let oneDataProducts = oneData.data.map(item => item.product)
        products = [...products, ...oneDataProducts]
      })
      products = _.uniq(products)
      const productObject = products.reduce((acc,curr)=> (acc[curr]=0,acc),{});

      // Prepare Data for 24 Hours
      let result = []
      for(let i = 1; i < 25; i++) {
        let oneObject = { hours: i, totalcount: 0, ...productObject }
        const hourData = peakHourData.find(data => data.hour+1 == i)
        if(hourData){
          hourData.data.map(oneData => {
            oneObject[oneData.product] = oneData.count
            oneObject.totalcount += oneData.count 
          })
        }
        if(!Number.isInteger(oneObject.totalcount)){
          oneObject.totalcount = oneObject.totalcount.toFixed(2);
        }
        result.push(oneObject)
      }
      return result
    } catch(error) {
      console.log("Catch in Format Peak Hour Result: ", error);
      throw error;
    }
  }

  decompressData(base64) {
    try {
        const buffer = Buffer.from(base64, "base64");
        const bufferArr = ungzip(buffer);
        const json = new TextDecoder("utf8").decode(bufferArr);
        return JSON.parse(json);
    } catch (err) {
        console.error(err);
        return null;
    }
  }

  getUnits = async (params, brand_id = '') => {
    try {
      const url = UnitInfo.list
      let headers = {
        'Content-Type': 'application/json',
        'cgid': params.cgid,
        'cid': params.cid,
        'brandid': params.brandid || brand_id
      }
      const response = await http.get(url, { headers, data: {}, params: { compress: true } });
      let units = response?.data?.units?.length ? response.data.units : [];
      if (response.data.compressed) {
          units = this.decompressData(units) || [];
      }
      return units;
    } catch(error) {
      console.log("Catch in Get Units: ", error)
      throw error
    }
  }

  getBatchProductionData = async (productionDBData, batchWeightDBData) => {
    const dbData = JSON.parse(JSON.stringify(productionDBData))
    const newResult = dbData.map(prodData => {
      let batchValue = batchWeightDBData.find(data => prodData.modelName == data.modelName)?.batchWeight || 1
      prodData.count = prodData.count * batchValue
      return prodData
    })
    const productionGroupData = _.groupBy(newResult, 'date')

    const result = []
    for(let i = 0; i < Object.keys(productionGroupData).length; i++) {
      const key = Object.keys(productionGroupData)[i]
      const batchWeight = await productionGroupData[key].reduce((acc, value) => {
        return acc + value.count
      }, 0)
      const resultOne = productionGroupData[key][0]
      resultOne.count = Number(batchWeight?.toFixed(2) || 0)
      result.push(resultOne)
    }
    return result
  }

  getReportData = async (text, startDate, date, brand_id) => {
    this.setState({ loading: true })
    const header = this.getHeaders(text, startDate, date, brand_id);

    // Data Standardization Changes
    if(STANDARDIZED_BRAND.includes(brand_id) && peakHourConfiguration[brand_id]) {

      // Response
      let finalResult = {}
      let _peakHour = 0

      // Get Units
      const result = await this.getUnits(header, brand_id)
      let units = result.map(data => data.UNITID)
      let models = result.map(data => data.MODEL_NAME)
      models = _.uniq(models)
      if(units.length == 0) {
        this.setState({
          peakHour: _peakHour,
          loading: false
        }, () => {
          this.renderChartData(finalResult, this.state.legendText);
        });
        return true;
      }

      // Get Configuration
      const peakHourConf = peakHourConfiguration[brand_id].configuration
      const id = peakHourConfiguration[brand_id].id
      const url = peakHourConfiguration[brand_id].url
      const toolTip = peakHourConfiguration[brand_id].toolTip || "KC0095";

      this.setState({ 
        legendText: toolTip
      });

      // Prepare Date
      let startDate, endDate
      if(text == "Date Range") {
        startDate = moment(header.startdate).format(apiDateFormat);
        endDate = moment(header.enddate).format(apiDateFormat);
        if(endDate == todaysDateFormatted) endDate = moment(header.enddate).add(1, 'day').format(apiDateFormat);
      } else {
        const dateRange = getDateRange(text, header.reqdate)
        startDate = dateRange.fromDate;
        endDate = dateRange.toDate;
      }

      const peakHourData = {
        data: {
          units: units,
          models: models,
          dateFilterStartValueFormatted: startDate,
          dateFilterEndValueFormatted: endDate
        }
      }
      // Get Compiled Configuration
      const compiledConfiguration = await compileConfiguration(peakHourConf, peakHourData);
      const encrptedConfig = await encriptData(JSON.stringify(compiledConfiguration))

      // Call Common Widget API
      const peakHourResponse = await invokePostMethod(url, { id: id, configuration: encrptedConfig});

      finalResult.peakHourChartInformation = []
      if (peakHourResponse?.data?.data?.data?.peakHour && peakHourResponse.data.data.data.peakHour.length) {
        if (peakHourResponse?.data?.data?.data?.batchWeight) {
          const batchWeight = peakHourResponse.data.data.data.batchWeight;
          let peakHour = peakHourResponse?.data?.data?.data?.peakHour || [];
          peakHour = peakHour.map(dat => {
            dat.data = dat?.data.map(obj => {
              let unitModel = result.find(unit => unit.PRODUCT_ID === obj.product);
              let batchValue = batchWeight.find(data => data.modelName === unitModel?.MODEL_NAME)?.batchWeight || 0;
              obj.count = (obj.count * batchValue);
              obj.count = Number(obj.count?.toFixed(2) || 0)
              return obj;
            })
            return dat
          });
          peakHourResponse.data.data.data.peakHour = peakHour;
        }
        finalResult.peakHourChartInformation = await this.formatPeakHourResult(peakHourResponse.data.data.data.peakHour)
      }
      _peakHour = this.findPeakHour(finalResult);

      // Set State and Render Chart
      this.setState({
        peakHour: _peakHour,
        loading: false
      }, () => {
        this.renderChartData(finalResult, this.state.legendText);
      });
      return true;
    }
    
    let widgetId = null;
    let url;
    if (brand_id === 1) {
      widgetId = 79;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 6) {
      widgetId = 80;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 3) {
      widgetId = 78;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 2) {
      widgetId = 81;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 11) {
      widgetId = 104;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 9) {
      widgetId = 114;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else {
      url = `${ReportManagement.root}`
    }

    this.getData(url, header, brand_id).then(response => {
      let _peakHour = this.findPeakHour(response);
      this.setState({
        peakHour: _peakHour,
        loading: false
      }, () => {
        this.renderChartData(response, this.state.legendText);
      });
    }).catch(err => {
      console.log(err);
    });
  }

  fetchData(brand_id, text) {
    this.setState({ filterType: text })
    if (text === "Date Range") {
      todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
      weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
    }
    else {
      todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
      weekDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
    }
    if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date")) {
      this.getReportData(text, weekDateFormatted, todaysDateFormatted, brand_id);
    }
  }

  onDropDownHandler(e) {
    const filterType = e.nodeText;
    this.setState({
      filterType,
      datapickflag: filterType === 'Date Range',
    });
    this.fetchData(this.state.brand_id, filterType);
  }

  getHeaders(type = 'Month', startDateFormatted = '', endDateFormatted = '', brand_id = '') {
    let filterType;
    switch (type) {
      case 'Week':
        filterType = 'custom';
        break;
      case 'Year':
        filterType = 'yearly';
        break;
      case 'Day':
        filterType = 'daily';
        break;
      case 'Quarter':
        filterType = 'quarterly';
        break;
      case 'Date Range':
        filterType = 'custom';
        break;
      default:
        filterType = 'monthly';
    }

    let header;

    let cgids = "";

    if (this.props.orgid && this.props.orgid.length > 0) {
      cgids = this.props.orgid;
    }
    if (this.props.cgid && this.props.cgid.length > 0) {
      cgids = this.props.cgid;
    }

    let groupby = brand_id === 6 ? "recipe_name" : brand_id === 3 ? "product_name"
      : (brand_id === 2 || brand_id === 11) ? "PRODUCT_NAME" : brand_id === 1 ? "recipe_name" : "product_type";

    if (filterType === 'custom') {
      startDateFormatted = format(startDateFormatted, 'YYYY-MM-DD');
      endDateFormatted = format(endDateFormatted, 'YYYY-MM-DD');

      if (brand_id === 4) {
        header = {
          'cgid': cgids,
          'cid': CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
          'brandid': brand_id,
          'model': this.props.model,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'startdate': startDateFormatted,
          'enddate': endDateFormatted,
          'filterType': filterType,
          'groupby': groupby
        };
      }
      else {
        header = {
          'cgid': cgids,
          'cid': CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
          'productmodel': this.props.model,
          'Content-Type': 'application/json',
          'startdate': startDateFormatted,
          'enddate': endDateFormatted,
          'filterType': filterType,
          'showtrend': 'false'
        };
      }
    }
    else {
      startDateFormatted = format(todaysDate, 'YYYY-MM-DD');

      if (brand_id === 4) {
        header = {
          'cgid': cgids,
          'cid': CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
          'brandid': brand_id,
          'model': this.props.model,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'reqdate': startDateFormatted,
          'filterType': filterType,
          'groupby': groupby
        };
      }
      else {
        header = {
          'cgid': cgids,
          'cid': CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
          'productmodel': this.props.model,
          'Content-Type': 'application/json',
          'reqdate': startDateFormatted,
          'filterType': filterType,
          'showtrend': 'false'
        };
      }
    }
    if (this.props.tags && this.props.tags.length > 0) {
      header['tags'] = this.props.tags.join(',');
    }
    return header;
  }


  getData(url, header, brand_id) {
    return new Promise((resolve, reject) => {
      http
        .get(url,
          {
            headers: header,
            data: {}
          })
        .then(response => {
          if (brand_id === 4) {
            resolve({
              peakHourChartInformation: response.data.peakHoursDrinksChart ? response.data.peakHoursDrinksChart : []
            })
          }
          else {
            resolve({
              peakHourChartInformation: response.data.peakHoursChart ? response.data.peakHoursChart : []
            })
          }
        })
        .catch(error => {
          reject({
            peakHourChartInformation: []
          })
        });
    });
  }

  findPeakHour = (data) => {
    let maxTotalCount = 0;
    let peakHour = 0;

    let chartData = data.peakHourChartInformation && data.peakHourChartInformation.length > 0 ? data.peakHourChartInformation : [];
    let separator = localStorage.getItem("thousandSeparator");
    //Find maximum count
    for (let index = 0; index < chartData.length; index++) {
      if (maxTotalCount < chartData[index].totalcount) {
        maxTotalCount = chartData[index].totalcount;
      }
      // Tooltip to show data with . as thousand separator
      let tooltipTotalCount = chartData[index].totalcount;
      chartData[index].tooltipNum = separator === "." ? tooltipTotalCount.toString().replace(',', separator) : tooltipTotalCount;
    }

    if (maxTotalCount) {
      for (let index = 0; index < chartData.length; index++) {
        if (chartData[index].totalcount === maxTotalCount) {
          chartData[index].fillOpacity = 1;
          chartData[index].color = "#00a4f2";
          chartData[index].stroke = "#00a4f2";
          peakHour = chartData[index].hours;
        } else {
          chartData[index].fillOpacity = 1;
          chartData[index].color = "#006b94";
          chartData[index].stroke = "#006b94";
        }
      }
    }

    return parseInt(peakHour);
  }

  renderChartData = (data, legendText) => {

    if (data.peakHourChartInformation && data.peakHourChartInformation.length > 0) {
      let chart = am4core.create("peakHoursDBChartdivAllLocations", am4charts.XYChart);
      chart.data = data.peakHourChartInformation && data.peakHourChartInformation.length > 0 ? data.peakHourChartInformation : [];
      this.chart = chart;
      chart.zoomOutButton.disabled = true;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "hours";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 0;
      categoryAxis.renderer.labels.template.disabled = true;
      categoryAxis.renderer.grid.template.disabled = true;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.renderer.grid.template.disabled = true;
      valueAxis.min = 0;

      //Congire Ticks
      if (chart.data.length > 0) {
        categoryAxis.renderer.ticks.template.disabled = false;
        categoryAxis.renderer.ticks.template.length = 5;
        categoryAxis.renderer.ticks.template.strokeOpacity = 1;
        categoryAxis.renderer.ticks.template.stroke = am4core.color("#d9d9d9");
        categoryAxis.renderer.ticks.template.strokeWidth = 1;
      }

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "totalcount";
      series.dataFields.categoryX = "hours";
      series.dataFields.tootltipY = "tooltipNum";
      series.name = reactIntl.formatMessage({id: legendText});
      series.columns.template.width = am4core.percent(93);
      series.columns.template.tooltipText = " " + reactIntl.formatMessage({id: legendText}) + " : [bold]{tootltipY}[/]";
      series.tooltip.label.adapter.add("textOutput", function (text, target) {
        if (target.dataItem.dataContext && target.dataItem.dataContext.tooltipNum) {
          return " " + reactIntl.formatMessage({ id: legendText }) + `: [bold]${target.dataItem.dataContext.tooltipNum}[/]`;
        } else {
          return reactIntl.formatMessage({ id: legendText }) + `: `;
        }
      });

      series.columns.template.propertyFields.fillOpacity = "fillOpacity";
      series.columns.template.propertyFields.fill = "color";

      // Series Tooltip Test and bg color
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color("#FFFFFF");
      series.tooltip.label.fill = am4core.color("#000000");

      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 0;
      columnTemplate.strokeOpacity = 0;
    }
    else {
      if (this.chart) {
        this.chart.dispose();
      }
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    let { peakHour, loading, brandsAvailable } = this.state;

    let newTimeFormat =  <div className="valH1Div"> {peakHour > 12 ? peakHour - 12 + ":00" : peakHour + ":00"} <span className="valH1Span"> {peakHour === 24 ? <FormattedMessage id="KC1883"/> : peakHour >= 12 ? <FormattedMessage id="KC1884"/> : <FormattedMessage id="KC1883"/>}</span></div>
    if (localStorage.getItem("timeFormat") === "24"){
      newTimeFormat =  <div className="valH1Div"> {peakHour === 24 ? "00:00" : peakHour + ":00"}</div>
    }

    return (
      <div className="colmDi brandDropdown">
        <h4><FormattedMessage id='KC0311'/>
        &nbsp;{this.state.showBlueDot ? <span class="blueDot" title={reactIntl.formatMessage({ id: 'KC1037' })}></span> : ""}
          <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.filterType} />
          { brandsAvailable && <UserBrandList onBrandSelection={(e) => this.onBrandSelection(e, this.state.filterType)} brandLabelRequired={true}/> }
        </h4>
        <div className="colmDiVal">
          { brandsAvailable && loading  ? <li className="spinner"></li> 
          : <> {newTimeFormat}</> }
        </div>
        <div id="peakHoursDBChartdivAllLocations"></div>
      </div>
    );
  }
}

export default PeakHoursChart;
